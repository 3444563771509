import { ref, watch, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import useAxios from '@/compositions/useAxios';

export default function useGetReview() {
	const { fetchData } = useAxios();
	const route = useRoute();

	const reviews = ref([]);

	function getReview() {
		fetchData(
			'/getReview',
			(data) => {
				reviews.value = data.review;
			},
			{
				pno: route.params.product_pk ? route.params.product_pk : '',
			},
		);
	}

	onBeforeMount(() => {
		getReview();
	});

	return {
		reviews,
		getReview,
	};
}
