<template>
	<div class="modal_container">
		<div class="btn_close_wrapper">
			<button @click="$emit('close-inquiry')">
				<span></span>
			</button>
		</div>
		<div class="modal_wrapper">
			<div class="div_inner">
				<h4>문의하기</h4>
				<div class="content_wrapper">
					<div class="div_scroll">
						<div class="content_section">
							<RowProductItem
								:product="{
									...productDetail,
									save_name: productDetail.product_req_image.save_name,
									path: productDetail.product_req_image.path,
									product_name: productDetail.product_name,
									brand_name: productDetail.brand_name,
									product_saleprice: productDetail.product_saleprice,
									discount_price: productDetail?.discount_price ? productDetail.discount_price : ((productDetail.pay_price - productDetail.order_price) / 100).toFixed(0),
									discount_unit: productDetail.discount_unit,
								}"
								@click.capture.stop=""
							/>
						</div>
						<div class="content_section">
							<table class="table">
								<tbody>
									<tr class="table_row">
										<th class="cell_title">문의유형</th>
										<td class="cell_content">
											<div class="select_box">
												<select v-model="division">
													<option value="">문의 유형을 선택하세요</option>
													<option value="사이즈">사이즈</option>
													<option value="배송">배송</option>
													<option value="재입고">재입고</option>
													<option value="상품상세문의">상품상세문의</option>
												</select>
												<span class="select_box_icon">
													<img src="/img/icon_drop_arrow.b010ffd0.svg" alt="" />
												</span>
											</div>
										</td>
									</tr>
									<tr class="table_row">
										<th class="cell_title">제목</th>
										<td class="cell_content">
											<input
												v-model="title"
												type="text"
												name="inquiry_title"
												id=""
												maxlength="15"
												placeholder="15자 이내로 입력해주세요"
											/>
										</td>
									</tr>
									<tr class="table_row">
										<th class="cell_title">내용</th>
										<td class="cell_content">
											<textarea
												v-model="content"
												placeholder="문의 내용을 입력해주세요"
												name="inquiry_content"
												id="inquiry-textarea"
												cols="30"
												rows="10"
											></textarea>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<input type="checkbox" id="exposure_yn" v-model="exposure_yn" />
							<label for="exposure_yn">비공개</label>
						</div>
					</div>
				</div>
				<div class="btn_submit_wrapper">
					<button @click="onClickSubmit">확인</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import RowProductItem from '@/components/molcules/Product/RowProductItem.vue';
	import useAxios from '@/compositions/useAxios';
	import { useRoute, useRouter } from 'vue-router';
	import { useStore } from 'vuex';

	export default {
		components: {
			RowProductItem,
		},
		props: {
			productDetail: {
				type: Object,
			},
			inquiry: {
				type: Object,
			},
		},
		setup(props, { emit }) {
			const router = useRouter();
			const route = useRoute();
			const store = useStore();
			const { postData } = useAxios();

			const ino = ref('');
			const division = ref('');
			const title = ref('');
			const content = ref('');
			const exposure_yn = ref(false);

			const canSubmit = computed(() => {
				if (!division.value) return false;
				if (!title.value) return false;
				if (!content.value) return false;
				return true;
			});

			const closeModal = () => emit('close-inquiry');

			const submit = () => {
				const params = {
					pno: route.params.product_pk ?? props.inquiry.product_pk,
					division: division.value,
					title: title.value,
					content: content.value,
					exposure_yn: !exposure_yn.value,
					ino: ino.value,
				};

				postData('/saveInquiry', params, () => {
					router.go(0);
					store.commit('alert', '문의가 등록되었습니다.');
					setTimeout(() => closeModal(), 1000);
				});
			};
			const onClickSubmit = () => {
				if (!canSubmit.value) alert('입력 양식을 지켜주세요.');
				else submit();
			};

			onMounted(() => {
				if (props.inquiry) {
					const {
						inquiry_pk,
						content: inquiryContent,
						division: inquiryDivision,
						exposure_yn: inquiryExpYn,
						title: inquiryTitle,
					} = props.inquiry;

					ino.value = inquiry_pk;
					exposure_yn.value = inquiryExpYn === 'true' ? false : true;
					title.value = inquiryTitle;
					content.value = inquiryContent;
					division.value = inquiryDivision ?? '';
				}
			});

			return {
				division,
				title,
				content,
				exposure_yn,
				onClickSubmit,
			};
		},
	};
</script>

<style scoped>
	.content_wrapper {
		max-height: 500px;
		min-width: 28rem;
		width: 100%;
		padding: 1.5rem;
		overflow-y: scroll;
	}

	@media screen and (max-width: 768px) {
		.content_wrapper {
			max-height: 100%;
		}
	}

	.content_wrapper .content_section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.content_wrapper .content_section >>> img {
		max-width: 120px;
	}
	.content_wrapper::-webkit-scrollbar {
		width: 6px;
	}
	.content_wrapper::-webkit-scrollbar-thumb {
		background-color: var(--color-border-light);
		border-radius: var(--size-radius-full);
	}
	@media screen and (max-width: 768px) {
		.content_wrapper {
			max-height: 100%;
		}
	}
	.table {
		width: 100%;
	}
	.table_row {
		padding: 1rem 0;
		display: flex;
	}
	.cell_title {
		flex-basis: 8rem;
		vertical-align: top;
		text-align: left;
		padding: 1rem 0;
		font-size: 14px;
		font-weight: var(--font-w-regular);
	}
	.cell_content {
		flex-basis: 100%;
		text-align: left;
	}
	.table_row .cell_content label {
		margin-left: 1rem;
	}

	.table_row .cell_content #inquiry-textarea {
		padding: 1rem;
	}
	@media screen and(max-width:465px) {
		.cell_content {
		}
	}
</style>
