<template>
	<div class="modal_container modal_size_guide">
		<div class="btn_close_wrapper">
			<button @click="this.$store.commit('modal/closeSizeModal')">
				<span></span>
			</button>
		</div>
		<div class="modal_wrapper">
			<div class="div_inner">
				<h4>사이즈 가이드</h4>
				<div class="content_wrapper">
					<div class="div_scroll">
						<img
							v-if="sizeGuide"
							:src="sizeGuide.path + sizeGuide.save_name"
							:alt="sizeGuide.original_name"
						/>
						<p v-if="!sizeGuide" style="text-align:center;">사이즈 가이드 정보가 없습니다.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			sizeGuide: {
				type: Object,
			},
		},
	};
</script>

<style></style>
