/* eslint-disable no-mixed-spaces-and-tabs */
<template>
	<div class="nav_item_wrap">
		<button type="button" @click="routePath ? $router.push(routePath) : () => {}">
			<slot name="icon"></slot>
			<span
				:style="
					routeName !== 'Home'
						? $route.path.includes(routePath)
							? 'color:var(--color-primary)'
							: '#222'
						: $route.path === '/'
						? 'color:var(--color-primary)'
						: '#222'
				"
				>{{ content }}</span
			>
		</button>
	</div>
</template>

<script>
	export default {
		props: {
			routePath: String,
			routeName: String,
			content: String,
		},
	};
</script>

<style></style>
