<template>
	<div class="search_modal" @mousewheel.stop="onMouseWheel">
		<div class="search_container">
			<div class="btn_close_wrapper close_wrap">
				<button @click.stop="closeModal">
					<span></span>
				</button>
			</div>
			<div class="search_top">
				<!-- <button class="ro_rectan"></button> -->
				<div class="search">
					<SearchInput
						:focus="true"
						:placeholer="'검색하기'"
						:value="searchInputValue"
						:eventName="'search-input'"
						@search-input="bindSearchInput"
						@keyup.enter="() => searchProduct(searchInputValue)"
					/>
					<button type="button" class="icon_search" @click.stop="() => searchProduct(searchInputValue)"></button>
				</div>
			</div>
			<div class="wrap">
				<div class="recent_keyword_container">
					<h6><strong>최근 검색어</strong></h6>
					<div>
						<div class="recent_keyword" v-for="(keyword, idx) in recentKeywords" :key="idx">
							<a href="javascript:void(0)" @click="() => searchProduct(keyword)">{{ keyword }}</a>
							<button type="button" class="btn_del" @click="removeRecentKeyword(idx)">x</button>
						</div>
						<div v-if="recentKeywords.length === 0">최근 검색어가 없습니다</div>
					</div>
				</div>
				<div class="rcmd_keyword_container">
					<h6><strong>추천 검색어</strong></h6>
					<div v-if="!recommendKeywordsLoading">
						<button
							type="button"
							class="search_rcmd"
							v-for="keyword in recommendKeywords"
							:key="keyword.recommend_search_pk"
							@click="() => searchProduct(keyword.content)"
						>
							{{ keyword.content }}
						</button>
					</div>
					<div v-if="recommendKeywords.length === 0">추천 검색어가 없습니다</div>
				</div>
				<div class="pop_keyword_container">
					<h6>
						<strong>인기 검색어</strong><span class="update_time">{{ updatedDate }}</span>
					</h6>
					<ol v-if="!popularKeywordLoading">
						<li
							role="button"
							class="pop_keyword"
							v-for="(keyword, idx) in popularKeywords"
							:key="idx"
							@click="() => searchProduct(keyword.content)"
						>
							<span class="ranking">
								{{ idx &lt; 9 ? `0${String(idx + 1)}` : String(idx + 1)
								}}</span
							>
							<span style="cursor: pointer">{{ keyword.content }}</span>
						</li>
						<li v-if="popularKeywords.length === 0">인기 검색어가 없습니다</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchInput from '@/components/molcules/Input/TextInput.vue';
	import { ref } from '@vue/reactivity';
	import { computed, onBeforeMount, onMounted, watch } from '@vue/runtime-core';
	import { useRoute, useRouter } from 'vue-router';
	import { useQuery } from 'vue-query';
	import { fetchPopularKeywords, fetchRecommendKeywords } from '@/api/index';
	import { useStore } from 'vuex';

	const MAX_RECENT_SEARCH_KEYWORDS = 10;
	export default {
		components: {
			SearchInput,
		},
		props: {
			isSearchOpen: {
				type: Boolean,
				required: true,
			},
		},
		setup(props, { emit }) {
			const { searchInputValue, bindSearchInput } = useInput();
			const { recentKeywords, hotKeywords, saveRecentKeyword, removeRecentKeyword } = useRecentKeywords();
			const { updatedDate, popularKeywords, popularKeywordLoading } = usePopularKeywords();
			const { recommendKeywords, recommendKeywordsLoading } = useRecommendKeywords();

			const router = useRouter();
			const store = useStore();

			function onMouseWheel(e) {
				e.stopPropagation();
			}
			function closeModal() {
				emit('close-search');
			}

			function searchProduct(keyword) {
				if (keyword.trim().length >= 1) {
					saveRecentKeyword(keyword);
					closeModal();
					router.push(`/search?q=${keyword}`);
				} else {
					store.commit('alert', '검색어를 입력해주세요');
				}
			}

			return {
				recentKeywords,
				hotKeywords,
				updatedDate,
				onMouseWheel,
				removeRecentKeyword,
				closeModal,
				searchProduct,
				searchInputValue,
				bindSearchInput,
				popularKeywordLoading,
				popularKeywords,
				recommendKeywords,
				recommendKeywordsLoading,
			};
		},
	};
	const useInput = () => {
		const searchInputValue = ref('');
		const bindSearchInput = (value) => {
			searchInputValue.value = value;
		};
		return {
			searchInputValue,
			bindSearchInput,
		};
	};
	const useRecentKeywords = () => {
		const recentKeywords = ref([]);

		function getRecentKeyword() {
			const keywordString = localStorage.getItem('recent_key');
			if (keywordString) {
				const keywords = keywordString.split(',');
				recentKeywords.value = keywords;
			}
		}
		function saveRecentKeyword(keyword) {
			const currentKeywords = recentKeywords.value.length;
			if (currentKeywords > MAX_RECENT_SEARCH_KEYWORDS) {
				recentKeywords.value.splice(MAX_RECENT_SEARCH_KEYWORDS - 1 - currentKeywords);
			}
			recentKeywords.value = [keyword, ...recentKeywords.value.filter((v) => v !== keyword)];
			localStorage.setItem('recent_key', recentKeywords.value);
		}
		function removeRecentKeyword(index) {
			recentKeywords.value = recentKeywords.value.filter((v, idx) => idx !== index);
			localStorage.setItem('recent_key', recentKeywords.value);
		}

		onBeforeMount(() => {
			getRecentKeyword();
		});

		return {
			recentKeywords,
			saveRecentKeyword,
			removeRecentKeyword,
		};
	};
	const usePopularKeywords = () => {
		const updateTime = ref(new Date().toTimeString().slice(0, 8));
		const updatedDate = computed(() => {
			const timeString = updateTime.value;
			const dayString = Number(timeString.substring(0, 2)) < 12 ? '오전' : '오후';

			return `${dayString} ${timeString} 업데이트`;
		});
		const { isLoading: popularKeywordLoading, data } = useQuery('popularKeywords', fetchPopularKeywords, {
			staleTime: 300 * 1000,
			cacheTime: 300 * 1000,
			onSuccess: () => (updateTime.value = new Date().toTimeString().slice(0, 8)),
		});
		const popularKeywords = computed(() => data.value?.popularity ?? []);
		return {
			updatedDate,
			popularKeywordLoading,
			popularKeywords,
		};
	};
	const useRecommendKeywords = () => {
		const { isLoading: recommendKeywordsLoading, data } = useQuery('recommendKeywords', fetchRecommendKeywords, {
			staleTime: 300 * 1000,
			cacheTime: 300 * 1000,
		});
		const recommendKeywords = computed(() => data.value?.recommend ?? []);
		return {
			recommendKeywordsLoading,
			recommendKeywords,
		};
	};
</script>
<style scoped src="@/assets/css/components/header_basic.css"></style>
<style scoped src="@/assets/css/components/header_custom_01.css"></style>
<style></style>
