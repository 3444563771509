import router from '../../router/index';
import useAxios from '@/compositions/useAxios';
const { fetchData } = useAxios();

import { modalOpenScrollFix, modalCloseScrollRestore } from '@/utils/modalScrollControl';

export default {
	namespaced: true,
	state: {
		isModalOpen: false,
		myAddressModal: false,

		orderNo: '',
		orderDetailNo: '',
		orderData: {},

		orderServiceModal: false,
		orderServicePayload: {},
		orderServiceType: '',

		optionData: {},
		optionModal: false,

		inviteModal: false,
		pointModal: false,
		couponModal: false,
		sizeModal: false,

		reviewModal: false,
		reviewOrderDetail: {},

		imageModal: false,
		reviewContent: '',
		images: [],

		inquiryModal: false,

		cashModal: false,

		payModal: false,

		decisionModal: false,

		addressChangeModal: false,
		addressDetail: '',
	},
	mutations: {
		closeModal(state) {
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openModal(state) {
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		openMyAddressModal(state) {
			// state.isModalOpen = true;
			state.myAddressModal = true;
			modalOpenScrollFix();
		},
		closeMyAddressModal(state) {
			// state.isModalOpen = false;
			state.myAddressModal = false;
			modalCloseScrollRestore();
		},

		openOptionModal(state, value) {
			state.optionModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeOptionModal(state, value) {
			state.optionModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openInviteModal(state) {
			state.inviteModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeInviteModal(state) {
			state.inviteModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openPointModal(state) {
			state.pointModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closePointModal(state) {
			state.pointModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openCouponModal(state) {
			state.couponModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeCouponModal(state) {
			state.couponModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openSizeModal(state) {
			state.sizeModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeSizeModal(state) {
			state.sizeModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openDecisionModal(state, orderDetail) {
			state.decisionOrderDetail = orderDetail;
			state.decisionModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeDecisionModal(state, refresh) {
			state.decisionOrderDetail = {};
			state.decisionModal = false;
			state.isModalOpen = false;
			if (refresh) {
				router.go(0);
			}
		},
		openReviewModal(state, orderDetail) {
			state.reviewOrderDetail = orderDetail;
			state.reviewModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeReviewModal(state, refresh) {
			state.reviewOrderDetail = {};
			state.reviewModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
			if (refresh) {
				router.go(0);
			}
		},
		openPayModal(state) {
			state.payModal = true;
			modalOpenScrollFix();
		},
		closePayModal(state) {
			state.payModal = false;
			modalCloseScrollRestore();
		},
		openOrderServiceModal(state, { type, orderData }) {
			switch (type) {
				case '교환':
					state.orderServiceType = '교환';
					state.orderServicePayload = orderData.filter((v) => v.order_state === '배송완료');
					break;
				case '취소':
					state.orderServiceType = '취소';
					state.orderServicePayload = orderData.filter(
						(item) => item.order_state === '결제완료' || item.order_state === '결제대기',
					);
					break;
				case '반품':
					state.orderServiceType = '반품';
					state.orderServicePayload = orderData.filter(
						(item) => item.order_state === '결제완료' || item.order_state === '배송완료',
					);
			}
			state.orderServiceModal = true;
			modalOpenScrollFix();
		},
		closeOrderServiceModal(state) {
			state.orderServicePayload = {};
			state.orderServiceType = '';
			state.orderServiceModal = false;
			modalCloseScrollRestore();
		},
		openImageModal(state, { reviewContent }) {
			state.imageModal = true;
			state.isModalOpen = true;
			state.reviewContent = reviewContent;
			modalOpenScrollFix();
		},
		closeImageModal(state) {
			state.isModalOpen = false;
			state.imageModal = false;
			modalCloseScrollRestore();
		},
		openInquiryModal(state) {
			state.inquiryModal = true;
			modalOpenScrollFix();
		},
		closeInquiryModal(state) {
			state.inquiryModal = false;
			modalCloseScrollRestore();
		},
		openCashModal(state) {
			state.cashModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeCashModal(state) {
			state.cashModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
		openAddressChangeModal(state, addressDetail) {
			state.addressDetail = addressDetail;
			state.addressChangeModal = true;
			state.isModalOpen = true;
			modalOpenScrollFix();
		},
		closeAddressChangeModal(state) {
			state.addressChangeModal = false;
			state.isModalOpen = false;
			modalCloseScrollRestore();
		},
	},
	actions: {
		OPEN_SERVICE_MODAL(context, { orderNo, type }) {
			fetchData(
				'/getMyOrderDetail',
				(data) => {
					context.commit('openOrderServiceModal', { type, orderData: data.detail });
				},
				{
					order_no: orderNo,
				},
				false,
			);
		},
	},
	getters: {
		isReviewModalOpen(state) {
			return state.reviewModal;
		},
		images(state) {
			return state.reviewContent.review_image.map((image) => image.path + image.save_name);
		},
		reviewContent(state) {
			return state.reviewContent;
		},
	},
};
