import router from '../../router';
import store from '../../store';
import useAxios from '@/compositions/useAxios';

const { fetchData, postData } = useAxios();

export default {
	namespaced: true,
	state: {
		user: {
			email: '',
			name: '',
			contact: '',
			grade: '', // 회원 등급
			code: '', //추천인 코드
			site_yn: '',
		},
		saveLogin: false,
		isLogin: false,
		point: 0,
		cash: 0,
		coupons: [],
		invite: [],
	},
	mutations: {
		login(state, payload) {
			const { user, saveLogin, redirect } = payload;
			if (user.access_token) {
				state.user = user;
				state.saveLogin = saveLogin;

				localStorage.setItem('saveLogin', saveLogin);

				store.commit('access_token/saveToken', {
					access_token: user.access_token,
					refresh_token: user.refresh_token,
					remember_token: user.remember_token,
					redirect: redirect,
				});
			} else {
				store.commit('alert', '로그인에 실패했습니다.');
				location.href = '/';
			}
		},
		logout(state) {
			state.user = {};
			localStorage.removeItem('recentProducts');
			store.commit('access_token/removeToken');
			// location.href = '/';
		},
		setUserInfo(state, payload) {
			state.user = payload;
		},
		setInvite(state, payload) {
			state.invite = payload;
		},
		setPoint(state, point) {
			state.point = point;
		},
		setCash(state, cash) {
			state.cash = cash;
		},
		setCoupons(state, coupons) {
			state.coupons = coupons;
		},
	},
	actions: {
		async LOGIN(context, payload) {
			const { contact, password, saveLogin, redirect } = payload;

			const { ip } = await (async () => {
				return await (await fetch('//jsonip.com')).json();
			})();
			await new Promise((resolve, reject) => {
				postData(
					'/signin',
					{
						contact,
						password,
						login_ip: ip,
					},
					(data) => {
						context.commit('login', { user: data.user, saveLogin: saveLogin, redirect });
						resolve();
					},
				);
			});
		},
		async SOCIAL_LOGIN(context, token) {
			const saveLogin = JSON.parse(localStorage.getItem('c_ls'));

			const { ip } = await (async () => {
				return await (await fetch('//jsonip.com')).json();
			})();

			postData(
				'/tSignin',
				{
					token: token,
					login_ip: ip,
				},
				(data) => {
					context.commit('login', {
						user: data.user,
						saveLogin: saveLogin,
						redirect: '/',
					});
					// fetchData('/getMyCoupon', ({ coupon }) => {
					// 	// 가입축하쿠폰을 발급받았는지 확인함, 없으면 로그인하면서 자동으로 발급하도록 함
					// 	if (coupon.findIndex((item) => item.coupon_division_pk === 11) === -1) {
					// 		postData('/saveMyCoupon', { coupon_division_pk: 11 }, () => {
					// 			store.commit('alert', '가입축하 쿠폰 3,000원이 발급되었습니다');
					// 		});
					// 	}
					// 	router.push({ name: 'Home' });
					// });
				},
			);
		},
		LOGOUT(context) {
			const access_token = store.state.access_token.access_token;
			postData(
				'/logout',
				{
					access_token: access_token,
				},
				() => {
					context.commit('logout');
				},
			);
		},
		SAVE_USERINFO(context, params) {
			postData('/saveUser', params, (data) => {
				if (params.password) {
					//비밀번호 변경시
					store.commit('alert', '비밀번호가 변경되었습니다. 다시 로그인 해주세요');
					context.commit('logout');
					router.push('/login');
				} else {
					// 회원정보 변경시
					store.commit('alert', '회원 정보가 변경되었습니다.');
					const toId = setTimeout(() => {
						router.go(0);
						clearTimeout(toId);
					}, 1500);
				}
			});
		},

		GET_USERINFO(context) {
			fetchData('/getUserInfo', (data) => context.commit('setUserInfo', data.user));
		},
		GET_INVITE(context) {
			fetchData('/getInvite', (data) => context.commit('setInvite', data.invite));
		},
		GET_POINT(context) {
			fetchData('/getMyPoint', (data) => context.commit('setPoint', data.point));
		},
		GET_CASH(context) {
			fetchData('/getMyCash', (data) => context.commit('setCash', data.cash));
		},
		GET_COUPONS(context) {
			fetchData('/getMyCoupon', (data) => context.commit('setCoupons', data.coupon));
		},
	},
	getters: {
		isLogin(state) {
			return state.isLogin;
		},
		point(state) {
			return state.point ? state.point : 0;
		},
		cash(state) {
			return state.cash ? state.cash : 0;
		},
		coupons(state) {
			return state.coupons ? state.coupons : [];
		},
		profileThumb(state) {
			return state.user.path + state.user.save_name;
		},
		user(state) {
			return state.user;
		},
		memberDivision(state) {
			return state.user.member_division;
		},
	},
};
