<template>
	<!-- <SwiperList v-if="useSlideYn" :productList="products" :pagination="true" /> -->
	<section class="best_review">
		<div class="item_list_container row_scroll">
			<ul class="item_list">
				<li v-if="reviews.length === 0" style="text-align: center; width: 100%; padding: 5rem 0">
					<p>베스트 리뷰가 없습니다.</p>
				</li>
				<li
					class="top_line"
					v-for="item in reviews.slice(0, 6)"
					:key="String(item)"
					@click="onClickProductReview(item.product_pk)"
				>
					<a href="javascript:void(0)">
						<div class="img_container">
							<div class="overlay"></div>
							<div class="img_wrapper">
								<div class="inner_wrapper">
									<img
										:src="item.review_path + item.review_save_name || item.product_path + item.product_save_name"
										alt="베스트리뷰이미지"
									/>
								</div>
							</div>
							<!-- <img :src="item.best_image" alt="베스트리뷰이미지" /> -->
						</div>
						<div class="review_contents">
							<div>
								<p class="item_img_for_review">
									<img :src="item.product_path + item.product_save_name" alt="베스트리뷰 상품이미지" />
								</p>
								<div class="item_info">
									<p class="brand_name">
										<strong>{{ item.brand_name }}</strong>
									</p>
									<p class="product_name">{{ item.product_name }}</p>
								</div>
							</div>
							<p>{{ item.content }}</p>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="btn_wrapper">
			<router-link to="/review">
				<button type="button" class="btn_common btn_mid">전체 리뷰 보기</button>
			</router-link>
		</div>
	</section>
</template>

<script>
	import SwiperList from '@/components/molcules/Scroll/SwiperList.vue';
	import { useRouter } from 'vue-router';

	export default {
		components: { SwiperList },
		props: {
			useSlideYn: Boolean,
			reviews: Array,
		},
		setup(props) {
			const router = useRouter();
			const onClickProductReview = (pk) => {
				router.push(`/product/detail/${pk}`);
			};
			return {
				onClickProductReview,
			};
		},
	};
</script>

<style></style>
