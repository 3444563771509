<!-- 이 컴포넌트는 productDetail의 쿠폰 받기 모달창, 마이페이지의 쿠폰 더보기 모달, orderAndPay의 쿠폰 모달에서 공통으로 사용됨 -->
<template>
	<div v-if="$store.state.modal.couponModal" class="modal_container modal_coupon">
		<div class="btn_close_wrapper">
			<button @click="this.$store.commit('modal/closeCouponModal')">
				<span></span>
			</button>
		</div>
		<div class="modal_wrapper coupon_container">
			<div class="usable_division">
				<span @click="showUsable = true" :class="{ on: showUsable }">사용 가능 쿠폰</span>
				<span @click="showUsable = false" :class="{ on: !showUsable }">사용 불가 쿠폰</span>
			</div>
			<!-- 사용 가능 쿠폰 -->
			<div class="div_inner" v-if="showUsable">
				<h4 v-if="menu === 'product'">쿠폰 받기</h4>
				<h4 v-else>보유한 쿠폰</h4>
				<div class="coupon_wrapper content_wrapper">
					<div class="div_scroll">
						<div class="coupon_available_wrapper">
							<h5 class="coupon_available" v-if="menu === 'product'">
								다운 가능한 쿠폰 <span>{{ usableCoupons.length - sameCoupons.length }}</span> 장
							</h5>
							<h5 class="coupon_available" v-else>
								사용 가능한 쿠폰 <span>{{ usableCoupons.length }}</span> 장
							</h5>
							<p class="coupon_description">
								<strong>쿠폰 받기는 번호 인증이 필요한 서비스</strong>로 다운시도 시 인증 화면으로 전환됩니다.
								<strong>인증은 최초 1회만 진행</strong>됩니다.
							</p>
						</div>
						<div class="coupon_list">
							<!-- 쿠폰 아이템 START -->
							<ul>
								<li class="coupon_content" v-for="(item, i) in usableCoupons" :key="i">
									<div class="coupon_content_left">
										<span>
											<h6 v-if="menu === 'product' || menu === 'order'">
												{{ item.price + item.unit }}
											</h6>
											<h6 v-else-if="item.discountrate">{{ item.discountrate }}%</h6>
											<h6 v-else>{{ item.discount_price }}원</h6>
											할인
										</span>
										<div>
											<p>
												<strong>{{ item.content }}</strong>
											</p>
											<p v-if="item.end_date === INFINITE_DATE">기한 없음</p>
											<p v-else-if="item.start_date && item.end_date">
												{{ dateFormatDate(item.start_date) }} ~ {{ dateFormatDate(item.end_date) }}
											</p>
										</div>
									</div>
									<div class="coupon_content_right" v-if="new Date(item.end_date) < new Date()">
										<p>유효기간 경과</p>
									</div>
									<div
										class="coupon_content_right"
										@click="downloadCoupon(item)"
										v-if="menu === 'product' && !sameCoupons.includes(item.coupon_division_pk)"
									>
										<p>쿠폰 다운받기</p>
									</div>
									<div
										class="coupon_content_right disabled"
										v-else-if="menu === 'product'"
										@click="() => $store.commit('alert', '이미 보유하고 있는 쿠폰입니다')"
									>
										<p v-if="checkUsedCoupon(item)">쿠폰 사용완료</p>
										<p v-else>쿠폰 보유 중</p>
									</div>

									<div class="coupon_content_right" @click="applyCoupon(item)" v-else-if="menu === 'order'">
										<p>쿠폰 적용하기</p>
									</div>
								</li>
							</ul>
							<!-- 쿠폰 아이템 END -->
						</div>
						<div class="btn_coupon_wrapper" v-if="menu === 'product'">
							<button @click="downloadCouponAll">모든 쿠폰받기</button>
						</div>
					</div>
				</div>
				<div class="btn_submit_wrapper">
					<button @click="this.$store.commit('modal/closeCouponModal')">확인</button>
				</div>
			</div>
			<!-- 사용 불가 쿠폰(마이페이지에서만 표시) -->
			<div v-else>
				<div class="div_inner">
					<h4>사용 불가 쿠폰</h4>
					<div class="coupon_wrapper content_wrapper">
						<div class="div_scroll">
							<div class="coupon_available_wrapper">
								<p class="coupon_description">유효기간이 지나거나, 이미 사용한 쿠폰입니다</p>
							</div>
							<div class="coupon_list">
								<ul>
									<li class="coupon_content not_usable" v-for="(item, i) in notUsableCoupons" :key="i">
										<div class="coupon_content_left">
											<span>
												<h6 v-if="menu === 'product' || menu === 'order'">
													{{ item.price + item.unit }}
												</h6>
												<h6 v-else-if="item.discountrate">{{ item.discountrate }}%</h6>
												<h6 v-else>{{ item.discount_price }}원</h6>
												할인
											</span>
											<div>
												<p>
													<strong>{{ item.content }}</strong>
												</p>
												<p v-if="item.start_date && item.end_date">
													{{ dateFormatDate(item.start_date) }} ~ {{ dateFormatDate(item.end_date) }}
												</p>
											</div>
										</div>
										<!--<div class="coupon_content_right" v-if="new Date(item.end_date) < new Date()">
											<p>유효기간 경과</p>
										</div>-->
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="btn_submit_wrapper">
						<button @click="this.$store.commit('modal/closeCouponModal')">확인</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from 'vue';
	import { useStore } from 'vuex';
	import useAxios from '@/compositions/useAxios';
	import { dateFormatDate } from '@/utils/dateFormatDate';

	export default {
		props: {
			productCoupon: Array,
			menu: {
				type: String,
				validator: (value) => {
					const allowed = ['mypage', 'order', 'product']; // 마이페이지 || 상품 주문 || 제품 상세
					return allowed.includes(value);
				},
			},
		},
		mixins: [dateFormatDate],
		emits: ['sendDiscount'],
		setup(props, { emit }) {
			const store = useStore();
			const showUsable = ref(true);
			const INFINITE_DATE = '2999-12-31 00:00:00';
			const { fetchData, postData } = useAxios();
			const sameCoupons = ref([]); // 중복된 쿠폰 저장
			let myCoupon = ref([]); // 내가 발급받은 쿠폰(사용 전, 후 포함)
			let usableCoupons = ref([]);
			let notUsableCoupons = ref([]);

			onMounted(() => {
				fetchData('/getMyCoupon', (data) => {
					//쿠폰 검증 로직 : 유효기간이 남아있으면서 사용하지 않은 쿠폰 필터 -> 그 중에서 현재 보유중인 쿠폰인지 확인
					myCoupon.value = data.coupon;
					setUsableCoupon();
					checkSameCoupon();
				});
			});

			const setUsableCoupon = () => {
				usableCoupons.value = props.productCoupon.filter((item) => {
					// 유효기간이 남아있으면서 사용하지 않은 쿠폰.
					return new Date(item.end_date) > new Date() && !checkUsedCoupon(item);
				});
				notUsableCoupons.value = props.productCoupon.filter((item) => {
					return new Date(item.end_date) < new Date() || checkUsedCoupon(item);
				});
			};

			const checkSameCoupon = () => {
				// 장바구니에서 여러 상품을 한번에 주문할 경우, 같은 쿠폰이 중복되서 표시되는 걸 막기 위해서 필터링함
				myCoupon.value.forEach((my) => {
					// 내가 가진 쿠폰
					usableCoupons.value.forEach((prod) => {
						// 상품에서 주어진 쿠폰
						if (prod.coupon_division_pk === my.coupon_division_pk) {
							//쿠폰 중복 발견시
							sameCoupons.value.push(prod.coupon_division_pk);
						}
					});
				});
			};

			const checkUsedCoupon = (item) => {
				//이미 사용한 적이 있는 쿠폰인지 검증
				const idx = myCoupon.value.findIndex((myItem) => {
					return myItem.state === '사용' && myItem.coupon_division_pk === item.coupon_division_pk;
				});
				if (idx === -1) {
					//사용한 적 없음(보유 중)
					return false;
				} else {
					//이미 사용함
					return true;
				}
			};

			const downloadCoupon = (param, isAll = 0) => {
				postData(
					'/saveMyCoupon',
					{
						coupon_division_pk: param.coupon_division_pk,
					},
					() => {
						store.dispatch('user/GET_COUPONS');
						sameCoupons.value.push(param.coupon_division_pk);
						if (!isAll) {
							store.commit('alert', '쿠폰을 다운받았습니다');
						}
					},
				);
			};
			const downloadCouponAll = () => {
				let downloaded = 0;
				usableCoupons.value.forEach((item) => {
					if (!sameCoupons.value.includes(item.coupon_division_pk)) {
						downloadCoupon(item, 1);
						downloaded++;
					}
				});
				store.commit('alert', `보유한 쿠폰을 제외한 총 ${downloaded}장의 쿠폰을 다운받았습니다.`);
			};

			const applyCoupon = (param) => {
				emit('sendDiscount', param);

				store.commit('modal/closeCouponModal');
			};

			return {
				downloadCoupon,
				downloadCouponAll,
				checkSameCoupon,
				myCoupon,
				sameCoupons,
				applyCoupon,
				usableCoupons,
				showUsable,
				notUsableCoupons,
				INFINITE_DATE,
				checkUsedCoupon,
			};
		},
	};
</script>

<style scoped>
	.coupon_container .usable_division {
		display: flex;
		justify-content: flex-start;
	}
	.coupon_container .usable_division span {
		border: 1.4px solid black;
		padding: 0.3rem 0.8rem;
		font-weight: 450;
		cursor: pointer;
		margin-left: 1rem;
	}
	.coupon_container .usable_division span.on {
		color: white;
		background: black;
	}
</style>
