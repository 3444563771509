/
<template>
	<input type="text" :value="input" @input="bindInput" :maxlength="maxlength" v-bind="$attrs" />
</template>

<script>
	import { ref, onUpdated, onMounted } from 'vue';

	export default {
		name: 'NumberInput',
		props: {
			value: {
				type: String,
				required: true,
			},
			maxlength: {
				type: Number,
				default: 11,
			},
			default: {
				type: String,
				default: '',
			},
		},
		setup(props, { emit }) {
			const input = ref('');
			onMounted(() => {
				input.value = props.default;
			});
			onUpdated(() => {
				input.value = props.value;
			});

			const bindInput = (event) => {
				input.value = event.target.value;

				if (input.value === '') {
					input.value = '';
				} else {
					const newValue = event.target.value.replace(/[^0-9]/g, '');
					input.value = newValue;
					// newValue.length > 1 && newValue[0] === '0'
					// 	? (input.value = newValue.substring(1, newValue.length))
					// 	: (input.value = newValue);
				}
				emit('bind-number', input.value);
			};

			return {
				input,
				bindInput,
			};
		},
	};
</script>

<style></style>
