<template>
	<svg
		width="2.4rem"
		height="2.4rem"
		version="1.1"
		id="레이어_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 23 23"
		style="enable-background: new 0 0 23 23"
		xml:space="preserve"
	>
		<g id="레이어_2_00000050625380531810037480000003520462727773783707_">
			<g id="레이어_1-2">
				<path
					class="st0"
					:class="primaryColor"
					d="M21.8,10.1H0.9V6.4h20.8V10.1z M21.2,6.9H1.5v2.7h19.8V6.9z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M21.6,10.4H1.1c-0.2,0-0.4-0.2-0.4-0.4l0,0V6.6c0-0.2,0.1-0.4,0.4-0.4h20.5c0.2,0,0.4,0.2,0.4,0.4V10
			C22.1,10.3,21.8,10.4,21.6,10.4z M1.6,9.9h19.5c0.2,0,0.4-0.2,0.4-0.4V7.1c0-0.2-0.2-0.4-0.4-0.4H1.6C1.4,6.6,1.2,6.8,1.2,7.1l0,0
			v2.4C1.2,9.7,1.4,9.9,1.6,9.9z M21.1,9.9H1.7c-0.3,0-0.5-0.2-0.5-0.4V9.5V7.1c0-0.3,0.2-0.4,0.4-0.4l0,0h19.5
			c0.2,0,0.4,0.2,0.4,0.4v2.4C21.5,9.7,21.3,9.9,21.1,9.9L21.1,9.9z M2.2,9.4h18.4C20.8,9.4,21,9.2,21,9V7.6c0-0.2-0.2-0.4-0.4-0.4
			H2.2C2,7.2,1.8,7.4,1.8,7.6l0,0V9C1.8,9.2,2,9.4,2.2,9.4L2.2,9.4z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M20.2,22.5H2.6c-0.3,0-0.7-0.3-0.7-0.6c0,0,0,0,0-0.1V9.6h18.9v12.2C20.8,22.2,20.6,22.5,20.2,22.5z
M2.4,10.2V22h17.9V10.2H2.4z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M20.5,22.7H2.3c-0.3,0-0.6-0.3-0.7-0.6V10c0-0.4,0.3-0.7,0.7-0.7h18.1c0.3,0,0.6,0.3,0.6,0.7v12.1
			C21.1,22.5,20.8,22.7,20.5,22.7z M2.8,22.3H20c0.3,0,0.7-0.3,0.7-0.7V9.9H2.2v11.7C2.2,21.9,2.4,22.2,2.8,22.3z M20,22.3H2.8
			c-0.3,0-0.6-0.3-0.6-0.6l0,0V9.9h18.3v11.6C20.6,21.9,20.3,22.2,20,22.3L20,22.3z M2.7,21.7H20V10.4H2.7V21.7z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M11.4,6.9L11.4,6.9C10.9,6.8,7,6,6,5.1C5.1,3.9,5.3,2.2,6.6,1.3c0.9-0.7,2.3-0.7,3.2,0l0,0
			c0.9,0.9,1.8,4.9,1.8,5.3c0,0.1,0,0.2-0.1,0.3C11.5,6.9,11.4,6.9,11.4,6.9z M7.9,1C7.4,1,6.8,1.2,6.4,1.6c-0.8,0.8-0.8,2.2,0,3
			C7.1,5.3,9.7,6,11.1,6.3c-0.3-1.4-0.9-4-1.6-4.7C9,1.3,8.5,1,7.9,1L7.9,1z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M11.4,7.2h-0.1C10.5,7,6.8,6.3,5.8,5.3c-1.1-1.1-1.1-3,0-4.2s3-1.1,4.2,0c1,1,1.8,4.7,1.9,5.5
			c0,0.2,0,0.3-0.1,0.5C11.6,7.1,11.5,7.2,11.4,7.2z M7.9,0.7c-1.4,0-2.4,1.1-2.4,2.4c0,0.7,0.3,1.3,0.7,1.7C7,5.6,9.8,6.4,11.4,6.6
			l0,0c-0.3-1.6-1-4.5-1.8-5.2C9.1,1,8.5,0.7,7.9,0.7z M11.4,6.6L11,6.6C10,6.4,7,5.7,6.2,4.9C5.3,3.9,5.3,2.4,6.2,1.5l0,0
			c0.9-0.9,2.4-0.9,3.4,0c0.7,0.8,1.5,3.9,1.7,4.8L11.4,6.6z M7.9,1.3C6.8,1.3,6,2.1,6,3.2c0,0.5,0.2,1,0.5,1.4C7,5,8.9,5.6,10.7,6
			c-0.4-1.8-1-3.7-1.5-4.1C8.9,1.5,8.4,1.3,7.9,1.3z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M11.4,6.9c-0.1,0-0.1,0-0.2-0.1s-0.1-0.1-0.1-0.3c0.1-0.4,0.9-4.3,1.8-5.3l0,0c1.1-0.9,2.8-0.7,3.9,0.4
			c0.9,1,0.9,2.4,0,3.4C15.8,6,11.9,6.8,11.4,6.9C11.4,6.9,11.4,6.9,11.4,6.9z M13.3,1.6c-0.7,0.7-1.4,3.3-1.6,4.7
			c1.4-0.3,4-0.9,4.7-1.6c0.8-0.9,0.7-2.2-0.1-3C15.4,0.9,14.1,0.9,13.3,1.6z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M11.4,7.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5c0.1-0.7,0.9-4.5,2-5.5c1.1-1.1,3-1.1,4.1,0
			c1.1,1.1,1.1,3,0,4.1C15.9,6.2,12.2,7,11.4,7.2L11.4,7.2z M14.9,0.7c-0.6,0-1.3,0.2-1.7,0.7c-0.7,0.8-1.5,3.7-1.8,5.2l0,0l0,0
			c1.6-0.3,4.5-1,5.2-1.8c0.9-0.9,0.9-2.5,0-3.4C16.1,1,15.5,0.7,14.9,0.7z M11.4,6.6l0.1-0.4c0.2-0.9,0.9-4,1.7-4.8l0,0
			c0.9-0.9,2.4-0.9,3.4,0c0.9,0.9,0.9,2.4,0,3.4c-0.7,0.8-3.8,1.5-4.7,1.7L11.4,6.6z M13.5,1.8C13,2.3,12.4,4.2,12,6
			c1.8-0.4,3.7-1,4.1-1.5c0.7-0.7,0.7-2,0-2.7l0,0C15.4,1.1,14.2,1.1,13.5,1.8L13.5,1.8z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M11.4,22.2c-0.1,0-0.3-0.1-0.3-0.3V6.6c0-0.1,0.1-0.3,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2v15.3
			C11.6,22.1,11.5,22.2,11.4,22.2z"
				/>
				<path
					class="st0"
					:class="primaryColor"
					d="M11.4,22.5c-0.3,0-0.5-0.2-0.5-0.5V6.6c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.4,0.2,0.5,0.5v15.3
			C11.9,22.3,11.6,22.5,11.4,22.5z M11.4,6.6v15.3l0,0V6.6z"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import { computed } from 'vue';
export default {
	props: {
		primary: Boolean,
	},
	setup(props) {
		const primaryColor = computed(() => {
			return props.primary ? 'primary_color' : '';
		});
		return {
			primaryColor,
		};
	},
};
</script>

<style scoped>
.st0 {
	fill: #222222;
	stroke: #222222;
	stroke-width: 0.2;
	stroke-miterlimit: 10;
}
.primary_color {
	stroke: var(--color-primary);
	fill: var(--color-primary);
}
</style>