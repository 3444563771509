<template>
	<section class="common_section">
		<SectionTitle
			:title="sectionTitle"
			:subContents="subContents"
			:division="sectionDivision"
			v-bind="{ display_pk }"
		/>
		<SectionDivider />
		<NormalProduct
			:useSlideYn="useSlideYn"
			:products="products"
			v-bind="{ display_pk }"
			v-if="sectionDivision === '일반'"
		/>
		<RankingProduct :useSlideYn="useSlideYn" :products="products" v-else />
	</section>
</template>

<script>
	import SectionTitle from '@/components/molcules/Text/SecondHeader.vue';
	import SectionDivider from '@/components/molcules/Divider/SectionDivider.vue';
	import NormalProduct from '@/components/organisms/home/item/NormalProduct.vue';
	import RankingProduct from './item/RankingProduct.vue';

	export default {
		components: {
			SectionTitle,
			SectionDivider,
			NormalProduct,
			RankingProduct,
		},
		props: {
			sectionTitle: {
				type: String,
				required: true,
			},
			sectionDivision: {
				type: String,
				default: '일반', // 일반 || 핫딜 || 리뷰 || 매거진 || 랭킹
			},
			useSlideYn: {
				type: Boolean,
				default: false, // true || false
			},
			products: {
				type: Array,
				required: true,
			},
			subContents: {
				type: String,
			},
			display_pk: {
				type: String,
			},
		},
	};
</script>

<style></style>
