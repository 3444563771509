import router from '../../router';
import store from '../../store';

export default {
    namespaced: true,
    state: {
        access_token: '',
        access_token_end_date: '',
        refresh_token: '',
        refresh_token_end_date: '',
        remember_token: '',
    },
    mutations: {
        saveToken(state, payload) {
            // console.log('saveToken', payload);
            state.access_token = payload.access_token;
            state.access_token_end_date = payload.access_token_end_date;
            state.refresh_token = payload.refresh_token;
            state.refresh_token_end_date = payload.refresh_token_end_date;
            state.remember_token = payload.remember_token;
            if (!store.state.user.isLogin) {
                store.state.user.isLogin = true;
                const nextLink = payload.redirect || '/';
                router.push(nextLink);
            } else {
                // console.log('already login');
            }
            // // location.href = '/';
        },
        removeToken(state) {
            // console.log('removeToken');
            state.access_token = '';
            state.access_token_end_date = '';
            state.refresh_token = '';
            state.refresh_token_end_date = '';
            state.remember_token = '';
            store.state.user.isLogin = false;
            router.push('/');
        },
        setAccessToken(state, access_token) {
            state.access_token = access_token;
        },
    },
};