<template>
	<!-- 컨텐츠 START -->
	<div class="sub_content_container magazine">
		<!--<h3 class="page_title" v-if="!isLoading">{{ info.content }}</h3>-->
		<section class="common_banner" v-if="!isLoading">
			<!--<img :src="magazineBannerImg" alt="매거진 배너이미지" v-if="magazineBannerImg" />-->
		</section>
		<section class="common_ing_wrap">
			<div class="common_ing border_top magazine_wrap">
				<ul v-if="!isLoading">
					<li v-for="article in articles.filter((v) => v.use_yn === 'true')" :key="article.magazine_pk">
						<ArticleComponent :article="article" :type="info.content" />
					</li>
				</ul>
				<p v-if="!isLoading && articles.length === 0" style="text-align: center">매거진 목록이 없습니다.</p>
			</div>
		</section>
	</div>
	<!--컨텐츠 END-->
</template>

<script>
	import { ref, onBeforeMount, computed } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { useQuery } from 'vue-query';

	import { fetchMenu } from '@/api';

	import ArticleComponent from '@/components/molcules/Article/ArticleComponent.vue';

	export default {
		components: {
			ArticleComponent,
		},
		setup() {
			const route = useRoute();

			const initTab = ref(0);
			onBeforeMount(() => {
				if (route.params.category === 'brand') {
					initTab.value = 1;
				} else if (route.params.category === 'people') {
					initTab.value = 2;
				} else if (route.params.category === 'fashion') {
					initTab.value = 3;
				} else {
					initTab.value = 0;
				}
			});

			const { isLoading, data } = useQuery('magazines', () => fetchMenu('매거진'));
			const articles = computed(() => data.value?.data ?? []);
			const info = computed(() => data.value?.menu ?? { content: '' });
			const magazineBannerImg = computed(() => info.value?.path + info.value?.save_name ?? '');
			return { articles, info, isLoading, magazineBannerImg };
		},
	};
	const useTab = (initTab) => {
		const router = useRouter();
		const selectedTab = ref(initTab);
		const onClickTab = (idx) => {
			selectedTab.value = idx;
			const params = { category: '' };
			if (idx === 0) {
				params.category = '';
			} else if (idx === 1) {
				params.category = 'brand';
			} else if (idx === 2) {
				params.category = 'people';
			} else if (idx === 3) {
				params.category = 'fashion';
			}
			router.push({ name: 'Magazine', params: params });
		};
		return {
			selectedTab,
			onClickTab,
		};
	};
</script>

<style>
	.common_ing_wrap .magazine_wrap ul {
		justify-content: flex-start;
	}
</style>
