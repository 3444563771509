<template>
	<!-- 카테고리 -->
	<div class="bottom_nav">
		<div class="nav_item_wrap">
			<button type="button" @click="$route.path !== '/mcategory' ? $router.push('/mcategory') : $router.go(-1)">
				<!-- <img src="@/assets/images/common/hamburger.svg" alt="카테고리" /> -->
				<HambergerIcon :primary="$route.path === '/mcategory' ? true : false" />
				<span :style="$route.name === 'MobileCategory' ? 'color: var(--color-primary)' : '#333333'">{{
					$route.path !== '/mcategory' ? '카테고리' : '이전'
				}}</span>
			</button>
		</div>
		<!-- 브랜드 -->
		<div class="nav_item_wrap">
			<button type="button" @click="openBrandSearch">
				<!-- <img src="@/assets/images/common/bn_like.svg" alt="관심상품" /> -->
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6"
					fill="none"
					width="2.4rem"
					height="2.4rem"
					viewBox="0 0 23 23"
					:stroke="isOpenBrandSearch ? 'var(--color-primary)' : 'black'"
					stroke-width="1.5"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M8 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
					/>
				</svg>
				<span :style="isOpenBrandSearch ? 'color: var(--color-primary)' : 'color:#333333'">브랜드</span>
			</button>
			<div ref="brandSearchRef" class="sub_menu" v-if="isOpenBrandSearch">
				<HeaderBrand />
			</div>
		</div>
		<!-- 홈 -->
		<NavButton routeName="Home" routePath="/" content="홈">
			<template v-slot:icon>
				<HomeIcon />
			</template>
		</NavButton>
		<!-- 메뉴 -->
		<div class="nav_item_wrap">
			<div ref="submenuRef" class="bottom_nav sub_menu" v-show="isOpenMenu" style="">
				<NavButton
					v-if="renderMenu.find((v) => v.content === '이벤트')"
					routeName="Event"
					routePath="/event"
					content="이벤트"
				>
					<template v-slot:icon>
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							fill="none"
							width="2.5rem"
							height="2.5rem"
							:stroke="$route.path.includes('/event') ? 'var(--color-primary)' : '#333333'"
							stroke-width="2"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
						>
							<g id="Layer_2_00000181772207466885268700000016804012559262523553_">
								<g id="Layer_1-2">
									<rect x="3" y="25" class="st0" width="44" height="24" />
									<rect x="20" y="25" class="st0" width="10" height="24" />
									<rect x="19" y="15" class="st0" width="12" height="10" />
									<rect x="1" y="15" class="st0" width="48" height="10" />
									<path class="st0" d="M25,8c0-3.9-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7h7V8z" />
									<path class="st0" d="M25,8c0-3.9,3.1-7,7-7s7,3.1,7,7s-3.1,7-7,7h-7V8z" />
								</g>
							</g>
						</svg>
					</template>
				</NavButton>
				<NavButton v-if="renderMenu.find((v) => v.content === 'FAQ')" routeName="FAQ" routePath="/faq" content="FAQ">
					<template v-slot:icon>
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							fill="none"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							:stroke="$route.path.includes('/faq') ? 'var(--color-primary)' : '#333333'"
							stroke-width="2"
							width="2.5rem"
							height="2.5rem"
							xml:space="preserve"
						>
							<g id="Layer_2_00000143588660777664980230000003969373298734430119_">
								<g id="Layer_1-2">
									<path
										class="st0"
										d="M11.9,39.8c0,2.7-1,5.3-2.9,7.2c3.6,0,6.9-1.9,8.6-5c2.4,0.7,4.9,1,7.4,1c13.2,0,24-9,24-20S38.2,3,25,3
			S1,12,1,23C1,30,5.3,36.2,11.9,39.8z"
									/>
									<circle class="st1" cx="25" cy="33" r="1.2" />
									<path class="st2" d="M19,19c0-3.3,2.7-6,6-6s6,2.7,6,6s-2.7,6-6,6v4" />
								</g>
							</g>
						</svg>
					</template>
				</NavButton>
				<NavButton
					v-if="renderMenu.find((v) => v.content === '공지사항')"
					routeName="Notice"
					routePath="/notice"
					content="공지사항"
				>
					<template v-slot:icon>
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							fill="none"
							stroke-width="2"
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
							:stroke="$route.path.includes('/notice') ? 'var(--color-primary)' : '#333333'"
							width="2.5rem"
							height="2.5rem"
						>
							<g id="Layer_2_00000084499670561533225300000011116228231402691748_">
								<g id="Layer_1-2">
									<line class="st0" x1="9" y1="46.5" x2="9" y2="11.5" />
									<path class="st1" d="M29,12.5H3c-1.1,0-2,0.9-2,2v16c0,1.1,0.9,2,2,2h26l14,8v-36L29,12.5z" />
									<path class="st0" d="M43,16.5c3.3,0,6,2.7,6,6s-2.7,6-6,6" />
								</g>
							</g>
						</svg>
					</template>
				</NavButton>
				<NavButton
					v-if="renderMenu.find((v) => v.content === '매거진')"
					routeName="Magazine"
					routePath="/magazine"
					content="매거진"
				>
					<template v-slot:icon>
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							fill="none"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
							width="2.5rem"
							height="2.5rem"
							:stroke="$route.path.includes('/magazine') ? 'var(--color-primary)' : '#333333'"
							stroke-width="2"
						>
							<g id="Layer_2_00000087392895174924296140000007056254469355462302_">
								<g id="Layer_1-2">
									<rect x="7" y="1" class="st0" width="36" height="48" />
									<rect x="13" y="8" class="st1" width="10" height="10" />
									<line class="st1" x1="28" y1="18" x2="38" y2="18" />
									<line class="st1" x1="28" y1="10" x2="38" y2="10" />
									<line class="st1" x1="12" y1="26" x2="38" y2="26" />
									<line class="st1" x1="12" y1="34" x2="38" y2="34" />
									<line class="st1" x1="12" y1="42" x2="23" y2="42" />
								</g>
							</g>
						</svg>
					</template>
				</NavButton>
				<NavButton
					v-if="renderMenu.find((v) => v.content === '기획전')"
					routeName="ThemeProducts"
					routePath="/themeproducts"
					content="기획전"
				>
					<template v-slot:icon>
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							width="2.4rem"
							height="2.4rem"
							fill="none"
							:stroke="$route.path.includes('/themeproducts') ? 'var(--color-primary)' : '#333333'"
							stroke-width="2"
							stroke-miterlimit="10"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
						>
							<g id="Layer_2_00000017477689720142568650000002884073045024926891_">
								<g id="Layer_1-2">
									<rect x="2" y="2" class="st0" width="20" height="20" />
									<rect x="28" y="2" class="st0" width="20" height="20" />
									<rect x="2" y="28" class="st0" width="20" height="20" />
									<rect x="28" y="28" class="st0" width="20" height="20" />
								</g>
							</g>
						</svg>
					</template>
				</NavButton>
				<NavButton
					v-if="renderMenu.find((v) => v.content === '리뷰')"
					routeName="Review"
					routePath="/review"
					content="리뷰"
				>
					<template v-slot:icon>
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							fill="none"
							:stroke="$route.path.includes('/review') ? 'var(--color-primary)' : '#333333'"
							stroke-width="2"
							stroke-linejoin="round"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
							width="2.4rem"
							height="2.4rem"
						>
							<g id="Layer_2_00000150800131468899053830000016712246864281698975_">
								<g id="Layer_1-2">
									<path class="st0" d="M1,46l9-9h35c2.2,0,4-1.8,4-4V8c0-2.2-1.8-4-4-4H5C2.8,4,1,5.8,1,8V46z" />
									<path
										class="st0"
										d="M32.8,13.1L30,10.3c-0.4-0.4-1-0.4-1.4,0L13.7,25.1L13,30.1l5-0.7l14.8-14.9C33.2,14.1,33.2,13.5,32.8,13.1z"
									/>
									<line class="st0" x1="23" y1="30" x2="37" y2="30" />
								</g>
							</g>
						</svg>
					</template>
				</NavButton>
			</div>
			<button type="button" @click="openMenu">
				<!-- <img src="@/assets/images/common/bn_like.svg" alt="관심상품" /> -->
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
					:stroke="isOpenMenu ? 'var(--color-primary)' : '#333333'"
					stroke-width="2"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
					/>
				</svg>
				<span :style="isOpenMenu ? 'color: var(--color-primary)' : '#333333'">메뉴</span>
			</button>
		</div>
		<!-- <NavButton
			:routeName="$store.state.access_token.access_token ? 'Mypage' : 'Login'"
			:routePath="$store.state.access_token.access_token ? '/mypage' : '/login'"
			:content="$store.state.access_token.access_token ? '마이페이지' : '로그인'"
		>
			<template v-slot:icon>
				<UserIcon :primary="$route.fullPath.includes('mypage') || $route.name === 'Login' ? true : false" />
			</template>
		</NavButton> -->
		<!-- 위시리스트 -->
		<NavButton routeName="Wishlist" routePath="/wishlist" content="위시리스트">
			<template v-slot:icon> <WishIcon :primary="$route.name === 'Wishlist' ? true : false" /> </template>
		</NavButton>
	</div>
</template>

<script>
	import { computed, ref, watch } from 'vue';
	import { useRoute } from 'vue-router';

	import NavButton from '@/components/molcules/Button/BottomNavButton.vue';
	import HeaderBrand from '@/components/common/HeaderDropDownBrand.vue';

	import HambergerIcon from '@/components/atoms/icons/HambergerIcon';
	import EventIcon from '@/components/atoms/icons/EventIcon';
	import HomeIcon from '@/components/atoms/icons/HomeIcon';
	import WishIcon from '@/components/atoms/icons/WishIcon';
	import UserIcon from '@/components/atoms/icons/UserIcon.vue';

	import { onClickOutside } from '@vueuse/core';
	import { useStore } from 'vuex';

	export default {
		components: {
			NavButton,
			HeaderBrand,
			HambergerIcon,
			EventIcon,
			HomeIcon,
			WishIcon,
			UserIcon,
		},
		setup() {
			const route = useRoute();

			const submenuRef = ref(null);
			const brandSearchRef = ref(null);
			const isOpenMenu = ref(false);
			const isOpenBrandSearch = ref(false);

			//onClickOutside(submenuRef, () => (isOpenMenu.value = false));
			//onClickOutside(brandSearchRef, () => (isOpenBrandSearch.value = false));

			function openMenu() {
				isOpenMenu.value = !isOpenMenu.value;
			}
			function openBrandSearch() {
				isOpenBrandSearch.value = !isOpenBrandSearch.value;
			}

			watch(
				route,
				() => {
					isOpenMenu.value = false;
					isOpenBrandSearch.value = false;
				},
				{
					deep: true,
				},
			);
			const store = useStore();
			const menu = computed(() => store.getters['template/menu']);

			const renderMenu = computed(() => (menu.value ? menu.value.filter((v) => v.use_yn === 'true') : []));

			return { isOpenMenu, openMenu, submenuRef, renderMenu, brandSearchRef, openBrandSearch, isOpenBrandSearch };
		},
	};
</script>

<style scoped src="@/assets/css/components/bottomnav.css"></style>
<style scoped>
	.sub_menu {
		position: absolute;
		bottom: 7rem;
		left: 0;
		border-bottom: 1px solid var(--color-border-light);
		border-top: 1px solid var(--color-border-light);
		box-shadow: none;
		z-index: 901;
		justify-content: flex-start;
	}
</style>
