<template>
	<div class="review_info">
		<div style="display: flex; flex-direction: column; gap: 1rem">
			<p style="font-weight: var(--font-w-mid);" @click="$router.push(`/product/detail/${review.product_pk}`)">{{ review.product_name }}</p>
			<div class="img_wrapper" @click="openImageModal">
				<img
					style="height: 100%; width: auto"
					:src="
						review.review_image[0]?.path + review.review_image[0]?.save_name ||
						review.review_image?.path + review.review_image?.save_name
					"
					v-if="review.review_image?.length > 0"
				/>
			</div>
			<p class="title" v-if="review.review.length < 200">{{ review.review }}</p>
			<p v-show="review.review.length >= 200 && !isDetail">
				<!--클릭시 글전체 보여짐-->
				{{ review.review.slice(0, 50) }}...
			</p>
			<p v-show="review.review.length >= 200 && isDetail">{{ review.review }}</p>

			<p v-if="review.review.length >= 200" id="show_detail" @click="onClickText">
				{{ !isDetail ? '더보기' : '닫기' }}
			</p>
		</div>
		<div class="review_bottom">
			<p class="name">{{ review.member_name }}</p>
			<p class="time">
				{{ review.enrollment_date.slice(0, 10) }}
				<span class="time">{{
					review.modifying_date
						? `${review.modifying_date.slice(11, 19)} (수정됨)`
						: review.enrollment_date.slice(11, 19)
				}}</span>
			</p>
			<div style="display: flex">
				<p class="btn_recommend" style="cursor: pointer" @click="() => (isLogin ? onClickLike() : () => {})">
					<span><LikeBtn :on="isLiked" /></span>
					<span>{{ likes }}</span>
				</p>
				<p class="btn_declare" style="cursor: pointer" @click="() => (isLogin ? handleReportModal() : () => {})">
					<span><ReportIcon /></span>
					<span>{{ reports }}</span>
				</p>
				<a
					v-if="review.member_name == $store.state.user.user.name"
					href="javascript:void(0)"
					style="margin-left: 1.5rem; cursor: pointer; color: var(--color-font-sub)"
					@click="() => onClickEdit(review)"
					>수정</a
				>
			</div>
			<!-- TODO: 현재는 member_name으로 판별하는데 이름을 바꾸면 이전에 내가 쓴 리뷰는 수정하지 못한다. -->
		</div>
	</div>
	<div class="star_wrap">
		<span class="icon_star" :class="i <= review.rating ? 'on' : ''" v-for="i in 5" :key="i"></span>
	</div>
	<transition name="fade">
		<div v-if="reportModal" class="modal_bg">
			<div class="modal_container">
				<div class="btn_close_wrapper">
					<button @click="handleReportModal"><span></span></button>
				</div>
				<div class="modal_wrapper">
					<div class="div_inner">
						<h4>리뷰 신고</h4>
						<div class="content_wrapper">
							<div class="div_scroll">
								<h5>신고 사유</h5>
								<textarea
									v-model="reportMessage"
									placeholder="신고 사유를 10자 이상 입력해주세요."
									id=""
									cols="40"
									rows="10"
								></textarea>
							</div>
						</div>
						<div class="btn_submit_wrapper" style="margin-top: 0">
							<button @click="onClickReport">신고하기</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
	<Modal
		v-if="
			$store.state.modal.isModalOpen &&
			(isEditModalOpen ||
				($store.state.modal.imageModal && review.review_pk === $store.state.modal.reviewContent.review_pk))
		"
	>
		<ReviewModalContent v-if="isEditModalOpen" />
		<ImageModal v-if="$store.state.modal.imageModal" />
	</Modal>
</template>

<script>
	import { onMounted, ref, computed, watch } from 'vue';

	import LikeBtn from '@/components/atoms/icons/LikeIcon';
	import ReportIcon from '@/components/atoms/icons/ReportIcon';
	import Modal from '@/components/molcules/Popup/TeleportModal.vue';
	import ReviewModalContent from '@/components/organisms/popup/ReviewModalContent.vue';
	import ImageModal from '@/components/organisms/popup/ImageModal.vue';

	import useAxios from '@/compositions/useAxios';
	import { useStore } from 'vuex';

	export default {
		components: {
			LikeBtn,
			ReportIcon,
			Modal,
			ReviewModalContent,
			ImageModal,
		},
		props: {
			review: {
				type: Object,
				required: true,
			},
		},
		setup(props) {
			const store = useStore();
			const isLogin = computed(() => store.getters['user/isLogin']);
			const { postData } = useAxios();

			const isDetail = ref(false);
			const onClickText = () => {
				isDetail.value = !isDetail.value;
			};

			const reportModal = ref(false);
			const reportMessage = ref('');
			const handleReportModal = () => {
				reportModal.value = !reportModal.value;
			};

			const isReported = ref(false);
			const reports = ref(0);

			const isLiked = ref(false);
			const likes = ref(0);

			const saveReview = (review_pk, type, report_content = '') => {
				if (review_pk) {
					postData(
						'/saveReview',
						{
							type: type,
							rno: review_pk,
							report_content: report_content,
						},
						() => {
							if (type == 'R') {
								store.commit('alert', '신고가 정상 접수되었습니다.');
							}
						},
						false,
					);
				}
			};

			const onClickLike = () => {
				isLiked.value ? likes.value-- : likes.value++;
				isLiked.value = !isLiked.value;
				saveReview(props.review?.review_pk, 'L');
			};

			const checkReportMessage = () => {
				return reportMessage.value.length >= 10 ? true : false;
			};

			const onClickReport = () => {
				if (checkReportMessage()) {
					store.commit('confirm', {
						content: '정말로 이 리뷰를 신고하시겠습니까?',
						callback: () => {
							isReported.value ? reports.value-- : reports.value++;
							isReported.value = !isReported.value;
							saveReview(props.review?.review_pk, 'R', reportMessage.value);
							reportModal.value = false;
							reportMessage.value = '';
						},
					});
				} else {
					alert('신고 사유를 10자 이상 입력해주세요.');
				}
			};

			onMounted(() => {

				console.log(props.review);

				likes.value = props.review.like;
				reports.value = props.review.report;
				isLiked.value = props.review.like_yn === 'true' ? true : false;
				isReported.value = props.review?.report_yn === 'true' ? true : false;
			});

			const openImageModal = () => {
				if (props.review.review_image[0]?.path + props.review.review_image[0]?.save_name) {
					store.commit('modal/openImageModal', { reviewContent: props.review });
				}
			};
			return {
				isLogin,
				isDetail,
				onClickText,
				isLiked,
				likes,
				onClickLike,
				isReported,
				reports,
				onClickReport,
				reportModal,
				reportMessage,
				handleReportModal,
				openImageModal,
				...useEdit(),
			};
		},
	};
	const useEdit = () => {
		const { fetchData } = useAxios();
		const isEditModalOpen = ref(false);

		const getProductDetail = (product_pk, product_order_pk) => {
			return new Promise((resolve) =>
				fetchData(
					'/getReviewProduct',
					(data) => {
						resolve(data.product);
					},
					{
						pno: product_pk,
						pono: product_order_pk,
					},
				),
			);
		};
		const onClickEdit = async (review) => {
			const productDetail = await getProductDetail(review.product_pk, review.product_order_pk);
			isEditModalOpen.value = true;
			store.commit('modal/openReviewModal', {
				save_name: productDetail.save_name,
				path: productDetail.path,
				original_name: productDetail.original_name,
				product_name: productDetail.product_name,
				brand: productDetail.brand_name,
				order_price: productDetail.pay_price,
				review,
			});
		};

		const store = useStore();
		const isTeleportModalOpen = computed(() => store.getters['modal/isReviewModalOpen']);
		watch(isTeleportModalOpen, (value) => {
			if (!value) {
				isEditModalOpen.value = false;
			}
		});

		return {
			onClickEdit,
			isTeleportModalOpen,
			isEditModalOpen,
		};
	};
</script>

<style scoped>
	#show_detail {
		text-align: end;
		cursor: pointer;
		font-size: var(--font-size-xx-small);
		color: var(--color-border-light);
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all 100ms ease-in-out;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.modal_bg {
		position: fixed;
		z-index: 900;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: var(--color-bg-modal);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.modal_bg .modal_content_wrapper {
		width: 400px;
		height: 600px;
		display: flex;
		flex-direction: column;
		transition: width 50ms linear, height 50ms linear;
	}

	.column_align_center {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		flex: 1;
		padding: 2.3rem;
	}

	.modal_content {
		background: #fff;
		height: 100%;
	}
	h5 {
		font-weight: 600;
		font-size: 1.8rem;
		text-align: center;
		margin: 1rem 0;
	}
	textarea {
		background: #fff;
		padding: 1rem;
		border: 1px solid var(--color-border-mid);
		/* border-radius: 1rem; */
	}
	textarea:focus {
		outline-color: var(--color-primary-effect);
		outline-width: 1px;
	}
	@media screen and (max-width: 768px) {
		.modal_bg .modal_content_wrapper {
			height: calc(100vh - 4.6rem);
			width: 100vw;
		}
		.modal_bg .modal_content_wrapper .btn_close_wrapper {
			padding: 1rem 2rem;
		}
	}
</style>
