import router from '../../router';
import store from '../../store';
import axios from 'axios';
import useAxios from '@/compositions/useAxios';

const { fetchData, postData } = useAxios();

export default {
	namespaced: true,
	state: {
		tempProduct: [],
		cartProduct: [],
		buyProducts: [],
	},
	mutations: {
		addTempProduct(state, payload) {
			state.tempProduct.push(payload);

			store.commit('confirm', {
				content: '장바구니에 상품이 추가되었습니다. 장바구니로 이동하시겠습니까?',
				callback: () => {
					router.push('/shoppingBag');
				},
			});
		},
		addCartProduct(state, payload) {
			state.cartProduct.push(payload);

			store.commit('confirm', {
				content: '장바구니에 상품이 추가되었습니다. 장바구니로 이동하시겠습니까?',
				callback: () => {
					router.push('/shoppingBag');
				},
			});
		},
		setCartProducts(state, payload) {
			state.cartProduct = payload;
			//console.log(payload);
			//console.log(state.cartProduct);
		},
		setBuyProduct(state, payload) {
			const { params, division } = payload;
			state.buyProducts = [];
			state.buyProducts = params;
			router.push('/orderAndPay');
		},
	},
	actions: {
		async GET_PRODUCT(context, product_pk) {
			await axios.get(`/getProduct?productId=${product_pk}`).then((res) => {
				context.commit('setProduct', res.data);
			});
		},
		async SAVE_ORDER(context, { product_pk, options, amount = 1 }) {
			postData(
				'/saveOrder',
				{
					ono: product_pk,
				},
				() => {
					alert('상품 구매가 완료되었습니다');
				},
			);
		},
		async ADD_CART(context, payload) {
			const { params, division } = payload;
			//console.log('params', params);
			if (division === 'G') {
				// 입력형
				postData(
					'/saveCart',
					{
						pno: params.product_pk,
						pono: params.options.join(),
						paono: params.addition_options.join(),
						//order_amount: params.order_amount,
						order_amount: 1,
						order_option_input: params.order_option_input,
					},
					() => {
						setTimeout(() => {
							store.commit('confirm', {
								content: '장바구니에 상품이 추가되었습니다. 장바구니로 이동하시겠습니까?',
								callback: () => {
									router.push('/shoppingBag');
								},
							});
						}, 500);
					},
				);
			} else {
				//입력형 제외 나머지
				if (!params.length === 0) {
					store.commit('alert', '옵션을 선택해주세요');
				} else {
					postData(
						'/saveCart',
						{
							pno: params.product_pk,
							pono: params.options.join(),
							paono: params.addition_options.join(),
							order_amount: params.order_amount,
						},
						() => {
							setTimeout(() => {
								store.commit('confirm', {
									content: '장바구니에 상품이 추가되었습니다. 장바구니로 이동하시겠습니까?',
									callback: () => {
										router.push('/shoppingBag');
									},
								});
							}, 500);
						},
					);
				}
			}
		},
		async UPDATE_CART(context) {
			context.state.tempProduct.forEach((item, idx) => {
				fetchData(
					'/getOption',
					(data) => {
						context.state.tempProduct[idx].product_option = data.option;
					},
					{
						pno: item.product_pk,
					},
				);
			});
		},
		async CONCAT_CART(context) {
			// 로그인 했을 때 tempProduct가 있다면 해당 상품들을 장바구니에 추가한 후에 tempProduct는 지운다.
			// 1. tempProduct 확인
			if (context.state.tempProduct.length > 0) {
				// 2. tempProduct 내의 상품들을 순회하면서 /saveCart
				context.state.tempProduct.forEach(async (product) => {
					let options = [];
					for (const key in product.options) {
						options.push(product.options[key]);
					}
					const params = {
						pno: product.product_pk,
						pono: options.join(),
						order_amount: product.order_amount,
						coupon_pk: product.coupon_pk,
					};

					await postData('/saveCart', params);
				});
				// 3. remove tempProduct
				context.state.tempProduct = [];
			}
		},
		async GET_CART_PRODUCTS(context) {
			await new Promise((resolve, reject) => {
				fetchData('/getCart', (data) => {
					context.commit('setCartProducts', data.cart);
					resolve();
				});
			});
		},
	},
	getters: {
		async getTempProducts(state) {
			await state.tempProduct.forEach((item, idx) => {
				let options = [];
				for (const key in item.options) {
					options.push(item.options[key]);
				}

				fetchData(
					'/getOption',
					(data) => {
						state.tempProduct[idx].product_option = data.option;
						state.tempProduct[idx].order_option = data.option.filter((v) => options.includes(v.product_option_pk));
					},
					{
						pno: item.product_pk,
					},
				);
			});
			return state.tempProduct;
		},
	},
};
