<template>
	<svg
		version="1.1"
		id="레이어_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 23 23"
		style="enable-background: new 0 0 23 23"
		xml:space="preserve"
	>
		<g id="레이어_2_00000051355185640621279140000008621214394292057229_">
			<g id="icon">
				<path
					class="st0"
					:class="[isOn, isPrimary]"
					d="M20.5,4.4c-1.7-1.9-4.6-2-6.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-2.3,2.4L9.2,4.4c-1.7-1.9-4.6-2-6.4-0.3
			C2.7,4.2,2.6,4.3,2.5,4.4c-1.9,2.1-1.9,5.2,0,7.2L4.8,14l0,0l6.8,7.2l6.8-7.2l0,0l2.3-2.4C22.4,9.6,22.4,6.5,20.5,4.4z"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
	import { computed } from 'vue';
	export default {
		props: {
			isLiked: Boolean,
			primary: Boolean,
		},
		setup(props) {
			const isOn = computed(() => {
				return props.isLiked ? 'on' : '';
			});

			const isPrimary = computed(() => {
				return props.primary ? 'primary_color' : '';
			});
			return {
				isOn,
				isPrimary,
			};
		},
	};
</script>

<style scoped>
	svg {
		width: 2.2rem;
	}
	.st0 {
		fill: none;
		stroke: #222222;
		stroke-width: 2;
		stroke-linejoin: round;
	}
	.on {
		fill: tomato;
		stroke: tomato;
	}
	.primary_color {
		stroke: var(--color-primary);
	}
</style>
