<template>
	<div class="screen_filter" v-if="this.$store.state.alertContent"></div>
	<transition name="popup">
		<div class="alert" v-if="this.$store.state.alertContent">
			<div class="alert_content">
				<p>{{ this.$store.state.alertContent }}</p>
				<button type="button" class="btn_close" id="alert_close_btn" @click="closeAlert">확인</button>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'Alert',
		updated() {
			document.getElementById('alert_close_btn').focus();
		},
		methods: {
			closeAlert() {
				this.$store.commit('closeAlert');
			},
		},
	};
</script>

<style scoped>
	.screen_filter {
		z-index: 1000;
	}
	.alert {
		position: fixed;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 40rem;
		max-width: 90vw;
		background-color: #fff;
		border-radius: 0.8rem;
		z-index: 1000;
	}
	.alert .alert_content {
		height: calc(100% - 10rem);
		background-color: #fff;
		border-radius: 1rem;
		padding: 2rem;
		text-align: center;
		font-size: 2rem;
		word-break: keep-all;
		z-index: 1000;
	}
	.alert .alert_content p {
		font-weight: 500;
	}
	.alert .alert_content .btn_close {
		display: inline-block;
		border: none;
		font-size: 1.6rem;
		color: #fff;
		background-color: var(--color-primary);
		padding: 0.9rem 2.6rem;
		border-radius: 0.8rem;
		margin-top: 3rem;
		cursor: pointer;
	}
	.alert .alert_content .btn_close:hover {
		background-color: var(--color-primary-effect);
	}

	/* transition popup */
	.popup-enter-from,
	.popup-leave-to {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.9);
	}
	.popup-enter-active,
	.popup-leave-active {
		transition: all 0.1s ease-in-out;
	}
</style>
