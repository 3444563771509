<template>
	<svg
		width="2.4rem"
		height="2.4rem"
		version="1.1"
		id="레이어_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 23 23"
		style="enable-background: new 0 0 23 23"
		xml:space="preserve"
	>
		<g id="레이어_1-2">
			<rect x="8.6" y="13" class="st0" width="6.5" height="9" />
			<path
				class="st1"
				d="M20.3,22h-17c-0.5,0-0.8-0.4-0.8-0.8l0,0v-14c0-0.3,0.1-0.6,0.4-0.7l8.5-5.2C11.6,1,12,1,12.3,1.2l8.5,5.2
		c0.3,0.2,0.4,0.4,0.4,0.7v14C21.2,21.6,20.8,22,20.3,22z"
			/>
		</g>
	</svg>
</template>

<script>
import { computed } from 'vue';
export default {
	props: {
		primary: Boolean,
	},
	setup(props) {
		const primaryColor = computed(() => {
			return props.primary ? 'primary_color' : '';
		});
		return {
			primaryColor,
		};
	},
};
</script>

<style scoped>
.st0 {
	fill: var(--color-primary);
}
.st1 {
	fill: none;
	stroke: #222222;
	stroke-width: 1.5;
	stroke-miterlimit: 10;
}
</style>