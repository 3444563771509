<template>
	<teleport to="#desktop-modal">
		<transition name="fade" appear>
			<div class="modal">
				<!-- <div class="modal-window"> -->
				<div class="modal-content">
					<slot></slot>
				</div>
				<!-- </div> -->
			</div>
		</transition>
	</teleport>
</template>

<script>
	import { useStore } from 'vuex';

	export default {
		setup() {
			const store = useStore();

			function closeModal() {
				store.commit('modal/closeModal');
			}

			return {
				closeModal,
			};
		},
	};
</script>

<style scoped>
	.modal {
		position: fixed;
		inset: 0px;
		z-index: 900;
		background-color: var(--color-bg-modal);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: 0.1s ease-in-out;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transition: 0.1s ease-in-out;
	}

	.modal-enter-active,
	.modal-leave-active {
		transition: opacity 0.4s;
	}
	.modal-enter-active .modal-window,
	.modal-leave-active .modal-window {
		transition: opacity 0.4s, transform 0.4s;
	}

	.modal-leave-active {
		transition: opacity 0.6s ease 0.4s;
	}

	.modal-enter,
	.modal-leave-to {
		opacity: 0;
	}
	.modal-enter .modal-window,
	.modal-leave-to .modal-window {
		opacity: 0;
		transform: translateY(-20px);
	}
</style>
