import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import useAxios from '../compositions/useAxios';

// 로그인 여부 확인
function checkLogin(to, from, next, alertContent = '로그인이 필요합니다') {
	if (store.state.access_token.access_token) {
		next();
	} else {
		store.commit('alert', alertContent);
		if (from.fullPath !== '/login') {
			next({ name: 'Login', params: { redirect: to.fullPath } });
		} else {
			router.currentRoute.value.params.redirect = to.fullPath;
		}
	}
}

// 이미 로그인했을 시 다시 들어가는거 방지
function loginGuard(next, alertContent = '이미 로그인/회원가입 하셨습니다') {
	if (!store.state.access_token.access_token) {
		next();
	} else {
		store.commit('alert', alertContent);
		next('/');
	}
}

// 유저 정보 갱신
const { fetchData } = useAxios();
async function getUserInfo() {
	await fetchData('/getUserInfo', (data) => {
		store.commit('user/setUserInfo', data.user);
	});
}

const pageGuard = (next, to, from) => {
	if (to.query.page && Number.isNaN(Number(to.query.page))) {
		next('/404');
	} else {
		next();
	}
};

const routes = [
	// 홈
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: '홈',
		},
	},

	// 로그인
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Login/Login.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		props: true,
		meta: {
			title: '로그인',
		},
	},
	// 로그인 - 회원가입
	{
		path: '/join',
		name: 'Join',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Login/Join.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '회원가입',
		},
	},
	// 로그인 - 본인인증
	{
		path: '/verify',
		name: 'Verify',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Login/Vertify.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '본인인증',
		},
	},
	// 로그인 - 비밀번호 찾기
	{
		path: '/findPassword',
		name: 'FindPassword',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Login/FindPassword.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '비밀번호 찾기',
		},
	},
	{
		path: '/changePassword',
		name: 'ChangePassword',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Login/ChangePassword.vue'),
		beforeEnter: (to, from, next) => {
			if (from.name === 'FindPassword') {
				// 반드시 인증을 거치고 와야만 비밀번호 변경 가능하도록 함
				next();
			} else {
				store.commit('alert', '올바르지 않은 접근입니다');
				next({ name: 'Home' });
			}
		},
		meta: {
			title: '비밀번호 변경',
		},
	},

	// 고객센터
	{
		path: '/customer',
		name: 'Customer',
		component: () => import(/* webpackChunkName: "Customer" */ '../views/Categories/Customer/Customer.vue'),
		meta: {
			title: '고객센터',
		},
	},

	// 공지사항
	{
		path: '/notice',
		name: 'Notice',
		component: () => import(/* webpackChunkName: "Notice" */ '../views/Categories/Notice/Notice.vue'),
		meta: {
			title: '공지사항',
		},
	},

	// 공지사항 - 상세페이지
	{
		path: '/notice_detail/:noticePk',
		name: 'NoticeDetail',
		component: () => import(/* webpackChunkName: "Notice" */ '../views/Categories/Notice/NoticeDetail.vue'),
		props: true,
		meta: {
			title: '공지사항',
		},
	},

	// FAQ
	{
		path: '/faq',
		name: 'FAQ',
		component: () => import(/* webpackChunkName: "FAQ" */ '../views/FAQ.vue'),
		meta: {
			title: 'FAQ',
		},
	},

	// 마이페이지
	{
		path: '/mypage',
		name: '',
		component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/Mypage.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
		},
		children: [
			{
				path: '',
				name: 'View',
				component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/Tabs/MypageView.vue'),
				meta: {
					title: '마이페이지',
				},
				children: [
					{
						path: 'order_history',
						name: 'OrderHistory',
						component: () =>
							import(/* webpackChunkName: "Mypage" */ '../components/organisms/mypage/OrderHistoryTab.vue'),
						props: true,
						meta: {
							title: '주문내역',
						},
					},
					{
						path: 'myreview',
						name: 'MyReview',
						component: () => import(/* webpackChunkName: "Mypage" */ '../components/organisms/mypage/ReviewTab.vue'),
						props: true,
						meta: {
							title: '내 리뷰',
						},
					},
					{
						path: 'info',
						name: 'MyInfo',
						component: () =>
							import(/* webpackChunkName: "Mypage" */ '../components/organisms/mypage/ModifyUserInfoTab.vue'),
						props: true,
						meta: {
							title: '내 정보',
						},
					},
					{
						path: 'inquiry',
						name: 'MyInquiry',
						component: () => import(/* webpackChunkName: "Mypage" */ '../components/organisms/mypage/MyInquiryTab.vue'),
						props: true,
						meta: {
							title: '내 질문',
						},
					},
					//회원 초대
					{
						path: 'invite',
						name: 'MyInvite',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/Invitation.vue'),
						meta: {
							title: '회원 초대',
						},
					},
					{
						path: 'myPasswordChange',
						name: 'MyPasswordChange',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/MyPasswordChange.vue'),
						meta: {
							title: '비밀번호 변경',
						},
					},
					//초대 목록
					{
						path: 'inviteList',
						name: 'MyInviteList',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/InvitationList.vue'),
						meta: {
							title: '초대 목록',
						},
					},
					//추천 목록
					{
						path: 'recommendList',
						name: 'MyRecommendList',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/RecommendList.vue'),
						meta: {
							title: '추천 목록',
						},
					},
					{
						path: 'point_usage',
						name: 'PointUsage',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/PointUsage.vue'),
						meta: {
							title: '포인트 사용',
						},
					},
					{
						path: 'offlinePoint',
						name: 'OfflinePointList',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/OfflinePointList.vue'),
						meta: {
							title: '오프라인 포인트 내역',
						},
					},
					{
						path: 'OfflinePay',
						name: 'OfflinePayList',
						component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/OfflinePayList.vue'),
						meta: {
							title: '오프라인 구매 내역',
						},
					},
				],
			},
			{
				path: 'order/:order_no',
				name: 'MypageOrder',
				component: () => import(/* webpackChunkName: "Mypage" */ '../views/Mypage/Order/OrderDetail.vue'),
				meta: {
					title: '주문 조회',
				},
			},
			{
				path: 'order_refund/:order_no',
				name: 'MypageOrderRefund',
				component: () => import(/* webpackCHunkName: "Mypage" */ '../views/Mypage/Order/OrderRefund.vue'),
				meta: {
					title: '주문 환불',
				},
			},
			{
				path: 'order_cancel/:order_no',
				name: 'MypageOrderCancel',
				component: () => import(/* webpackCHunkName: "Mypage" */ '../views/Mypage/Order/OrderCancel.vue'),
				meta: {
					title: '주문 취소',
				},
			},
		],
	},

	// 기획전
	{
		path: '/themeproducts',
		name: 'ThemeProducts',
		component: () =>
			import(
				/* webpackChunkName: "ThemeProduct" */
				'../views/Categories/ThemeProduct/ThemeProducts.vue'
			),
		meta: {
			title: '기획전',
		},
	},
	// 기획전 상세페이지
	{
		path: '/themeproducts/:articleId',
		name: 'ThemeProductsDetail',
		component: () =>
			import(
				/* webpackChunkName: "ThemeProduct" */
				'../views/Categories/ThemeProduct/ThemeProductsDetail.vue'
			),
		props: true,
		meta: {
			title: '기획전',
		},
	},

	// 매거진
	{
		path: '/magazine',
		name: 'Magazine',
		component: () => import(/* webpackChunkName: "Magazine" */ '../views/Categories/Magazine/Magazine.vue'),
		meta: {
			title: '매거진',
		},
	},

	// 매거진 상세페이지
	{
		path: '/magazine/:articleId',
		name: 'MagazineDetail',
		component: () =>
			import(
				/* webpackChunkName: "Magazine" */
				'../views/Categories/Magazine/MagazineDetail.vue'
			),
		props: true,
		meta: {
			title: '매거진',
		},
	},

	// 리뷰
	{
		path: '/review',
		name: 'Review',
		component: () => import(/* webpackChunkName: "Review" */ '../views/Categories/Review/Review.vue'),
		props: true,
		meta: {
			title: '리뷰',
		},
	},

	// 이벤트
	{
		path: '/event',
		name: 'Event',
		component: () => import(/* webpackChunkName: "Event" */ '../views/Categories/Event/Event.vue'),
		meta: {
			title: '이벤트',
		},
	},
	{
		path: '/event_award',
		name: 'EventAward',
		component: () => import(/* webpackChunkName: "Event" */ '../views/Categories/Event/EventAward.vue'),
		meta: {
			title: '이벤트',
		},
	},
	{
		path: '/event/eventKakao',
		name: 'EventKakao',
		component: () => import(/* webpackChunkName: "Event" */ '../views/Categories/Event/EventKakao.vue'),
		props: true,
		meta: {
			title: '이벤트카카오',
		},
	},
	{
		path: '/event/:articleId',
		name: 'EventDetail',
		component: () => import(/* webpackChunkName: "Event" */ '../views/Categories/Event/EventDetail.vue'),
		props: true,
		meta: {
			title: '이벤트',
		},
	},
	{
		path: '/event/eventApply',
		name: 'EventApply',
		component: () => import(/* webpackChunkName: "Event" */ '../views/Categories/Event/EventApply.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
		},
		props: true,
		meta: {
			title: '이벤트신청서',
		},
	},

	// 주문&결제
	{
		path: '/orderAndPay',
		name: 'OrderAndPay',
		component: () => import(/* webpackChunkName: "OrderAndPay" */ '../views/OrderAndPay.vue'),
		meta: {
			title: '주문결제',
		},
	},
	// 주문완료
	{
		path: '/orderComplete',
		name: 'OrderComplete',
		component: () => import(/* webpackChunkName: "OrderAndPay" */ '../views/OrderComplete.vue'),
		meta: {
			title: '주문완료',
		},
	},
	// 상품
	{
		path: '/category/:category/:subcategory?/:lastcategory?',
		name: 'Category',
		component: () => import(/* webpackChunkName: "Product" */ '../views/Categories/Product/Product.vue'),
		beforeEnter: (to, from, next) => {
			pageGuard(next, to, from);
		},
		props: true,
		meta: {
			title: '카테고리',
		},
	},
	// {
	//     path: '/category/:*',
	//     redirect: '/404',
	// },
	// {
	//     path: '/category/:category/:*',
	//     redirect: '/404',
	// },
	// 상품 검색
	{
		path: '/search/:keyword?',
		name: 'Search',
		component: () => import(/* webpackChunkName: "Product" */ '../views/Categories/Product/ProductSearch.vue'),
		props: true,
		meta: {
			title: '검색',
		},
	},

	// 상품 상세
	{
		path: '/product/detail/:product_pk?',
		name: 'ProductDetail',
		component: () =>
			import(
				/* webpackChunkName: "Product" */
				'../views/Categories/Product/ProductDetail.vue'
			),
		props: true,
		meta: {
			title: '',
		},
	},

	// 상품 검색
	// {
	//     path: '/search/product',
	//     name: 'ProductSearch',
	//     component: () =>
	//         import ('../views/Categories/Product/Product.vue'),
	//     props: true,
	// },
	// 장바구니
	{
		path: '/shoppingBag',
		name: 'ShoppingBag',
		component: () => import(/* webpackChunkName: "ShoppingBag" */ '../views/Mypage/ShoppingBag.vue'),
		meta: {
			title: '장바구니',
		},
		// beforeEnter: (to, from, next) => {
		//     checkLogin(next);
		// },
	},

	// 이용약관
	{
		path: '/policy/:policy?',
		name: 'Policy',
		component: () => import(/* webpackChunkName: "Policy" */ '../views/Policy/Policy.vue'),
		meta: {
			title: '이용약관',
		},
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
		meta: {
			title: '이용약관',
		},
	},
	{
		path: '/mcategory',
		name: 'MobileCategory',
		component: () => import(/* webpackChunkName: "MobileCategory" */ '../views/Categories/MobileCategory.vue'),
		meta: {
			title: '카테고리',
		},
	},
	// 개인정보 취급방침
	{
		path: '/privacypolicy',
		name: 'PrivacyPolicy',
		component: () => import(/* webpackChunkName: "Terms" */ '../views/PrivacyPolicy.vue'),
		meta: {
			title: '개인정보 취급방침',
		},
	},
	//위시리스트
	{
		path: '/wishlist',
		name: 'Wishlist',
		component: () => import(/* webpackChunkName: "Wishlist" */ '../views/Mypage/Wishlist.vue'),
		// beforeEnter: (to, from, next) => {
		//     checkLogin(to, from, next);
		// },
		meta: {
			title: '위시리스트',
		},
	},
	{
		path: '/save_point/qr_code',
		name: 'SavePoint',
		component: () => import(/* webpackChunkName: "Wishlist" */ '../views/SavePoint.vue'),
		// beforeEnter: (to, from, next) => {
		//     checkLogin(to, from, next);
		// },
		meta: {
			title: '포인트적립',
		},
	},
	{
		path: '/save_income_point/qr_code',
		name: 'SaveIncomePoint',
		component: () => import(/* webpackChunkName: "Wishlist" */ '../views/SaveIncomePoint.vue'),
		// beforeEnter: (to, from, next) => {
		//     checkLogin(to, from, next);
		// },
		meta: {
			title: '포인트적립',
		},
	},

	// 개발중
	{
		path: '/temp',
		name: 'Temp',
		component: () => import(/* webpackChunkName: "Temp" */ '../views/Temp.vue'),
	},
	// 404
	{
		path: '/404',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue'),
		meta: {
			title: '404 NotFound',
		},
	},
	// 404로 리다이렉트
	{
		path: '/:pathMatch(.*)*',
		redirect: '/404',
	},
	// 에러메시지 페이지
	{
		path: '/error/:errorMsg',
		name: 'Error',
		component: () => import(/* webpackChunkName: "NotFound" */ '../views/Error.vue'),
	},
	{
		path: '/more/:display_pk?',
		name: 'MoreProduct',
		component: () => import(/* webpackCHunkName: "Mypage" */ '../views/MoreProduct.vue'),
		meta: {
			title: '더보기',
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	// 맨 위로
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			if (to.name !== 'OrderHistory' && to.name !== 'MyReview' && to.name !== 'MyInfo' && to.name !== 'MyInquiry') {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}
	},
});

// 페이지 이동하면 로딩 스피너 초기화
router.beforeEach(async (to, from, next) => {
	// if (to.name !== 'Policy') store.dispatch('template/GET_TEMPLATE');

	store.commit('finishLoading');
	if (store.state.user.isLogin && !store.state.access_token.access_token) {
		store.state.user.isLogin = false;
	} else if (store.state.access_token.access_token && !store.state.user.isLogin) {
		store.state.user.isLogin = true;
		if (to.path !== '/mypage') {
			await getUserInfo();
		}
	}
	next();
});

router.afterEach((to, from) => {
	//상품상세 페이지 한해서 title에 제품의 이름을 적어주기로 함.
	if (to.name !== 'ProductDetail') {
		document.title = `소나기몰 | ${to.meta.title ?? ''}`;
	}
});

export default router;
