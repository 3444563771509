<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.25 31.25">
		<g id="레이어_2" data-name="레이어 2">
			<g id="icon">
				<path
					class="cls-1"
					:class="isOn"
					d="M31.49,30.63H1.76A1.14,1.14,0,0,1,.69,29.12l.73-2.22a4.54,4.54,0,0,1,4.3-3.13H27.53a4.54,4.54,0,0,1,4.3,3.13l.74,2.22A1.15,1.15,0,0,1,31.49,30.63Z"
				/>
				<path
					class="cls-1"
					:class="isOn"
					d="M28,23.77V12.06A11.37,11.37,0,0,0,16.63.63h0A11.38,11.38,0,0,0,5.29,12.06V23.77S27.81,23.92,28,23.77Z"
				/>
				<path class="cls-1" :class="isOn" d="M23.46,11.09a5.62,5.62,0,0,0-5.59-5.64" />
			</g>
		</g>
	</svg>
</template>

<script>
import { computed } from 'vue';
export default {
	props: {
		on: Boolean,
	},
	setup(props) {
		const isOn = computed(() => {
			return props.on ? 'on' : '';
		});
		return {
			isOn,
		};
	},
};
</script>

<style scoped>
svg {
	margin-right: 0.6rem;
	width: 1.5rem;
}
svg:active {
	opacity: 0.8;
}
.cls-1 {
	fill: none;
	stroke: #7b7b7b;
	stroke-linejoin: round;
	stroke-width: 1.25px;
}
.cls-1.on {
	stroke: var(--color-primary-effect);
}
</style>