<template>
	<div class="c_table">
		<div class="list_header">
			<span v-if="isMyInquiry" class="td30">상품 정보</span>
			<span v-if="!isMyInquiry" class="td10">번호</span>
			<span class="td20">구분</span>
			<span class="td30">제목</span>
			<span v-if="!isMyInquiry" class="td15">작성자</span>
			<span class="td20">등록일</span>
			<span class="td10">상태</span>
		</div>
		<ol class="list">
			<QAItem
				v-for="(item, idx) in data"
				@question-click="onClickQuestion"
				@edit-inquiry="onEditInquiry"
				@delete-inquiry="onClickDelete"
				:key="item"
				:index="idx + 1"
				:data="item"
				:isShown="isShownList[idx]"
				:isMyInquiry="isMyInquiry"
			/>
			<div v-if="data.length === 0" class="no-content">등록된 문의가 없습니다.</div>
		</ol>
	</div>
	<ul class="page_nav"></ul>

	<transition name="fade">
		<div
			v-if="inquiryModal"
			style="
				position: fixed;
				inset: 0px;
				z-index: 200;
				background-color: var(--color-bg-modal);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			"
		>
			<div class="modal-content">
				<InquiryModal
					v-if="inquiryModal"
					@close-inquiry="onCloseInquiryModal"
					:productDetail="inquiryProduct"
					:inquiry="editInquiry"
				/>
			</div>
		</div>
	</transition>
</template>

<script>
	import { ref, computed } from '@vue/reactivity';

	import QAItem from '@/components/molcules/Article/QAItem.vue';
	import InquiryModal from '@/components/organisms/popup/InquiryModal.vue';
	import { deleteInquiry, fetchProductDetail } from '@/api';
	import { useStore } from 'vuex';
	import { useRouter } from 'vue-router';
	import { modalOpenScrollFix, modalCloseScrollRestore } from '@/utils/modalScrollControl';

	export default {
		components: {
			QAItem,
			InquiryModal,
		},
		props: {
			data: {
				type: Array,
				required: true,
			},
			isMyInquiry: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const store = useStore();
			const router = useRouter();

			const isShownList = ref(new Array(props.data.length).fill(false));

			function onClickQuestion(index) {
				isShownList.value[index] = !isShownList.value[index];
			}

			// const inquiryModal = ref(false);
			const inquiryModal = computed(() => store.state.modal.inquiryModal);

			const editInquiry = ref({});
			const inquiryProduct = ref({});

			async function onEditInquiry(index) {
				editInquiry.value = props.data[index];
				inquiryProduct.value = await fetchProductDetail(editInquiry.value?.product_pk);
				store.commit('modal/openInquiryModal');
			}
			function onCloseInquiryModal() {
				store.commit('modal/closeInquiryModal');
			}

			async function onClickDelete(index) {
				const toDelete = props.data[index];
				if (toDelete.state === '답변대기') {
					store.commit('confirm', {
						content: '문의를 삭제하시겠습니까?',
						callback: async () => {
							await deleteInquiry(toDelete.inquiry_pk);
							router.go(0);
						},
					});
				} else {
					store.commit('alert', '답변이 완료된 문의는 삭제할 수 없습니다.');
				}
			}

			return {
				inquiryModal,
				isShownList,
				onClickQuestion,
				onCloseInquiryModal,
				onEditInquiry,
				editInquiry,
				inquiryProduct,
				onClickDelete,
			};
		},
	};
</script>

<style scoped>
	.detail_content {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.detail_content .question_content,
	.detail_content .answer {
		padding: 2rem;
	}
	.detail_content .answer {
		border-top: 1px solid var(--color-border-light);
		background: var(--color-bg-highlight);
	}
	@media screen and (max-width: 599px) {
		.c_table .list_header {
			display: none;
		}
	}
</style>
