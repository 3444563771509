<template>
	<svg
		width="2.4rem"
		height="2.4rem"
		version="1.1"
		id="레이어_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 23 23"
		style="enable-background: new 0 0 23 23"
		xml:space="preserve"
	>
		<g id="레이어_2_00000136384511722506786450000000879800548352680846_">
			<g id="레이어_1-2">
				<line class="st0" :class="primaryColor" x1="1.2" y1="4.6" x2="21.8" y2="4.6" />
				<line class="st0" :class="primaryColor" x1="1.2" y1="11.8" x2="21.8" y2="11.8" />
				<line class="st0" :class="primaryColor" x1="21.8" y1="19" x2="1.2" y2="19" />
			</g>
		</g>
	</svg>
</template>

<script>
import { computed } from 'vue';
export default {
	props: {
		primary: Boolean,
	},
	setup(props) {
		const primaryColor = computed(() => {
			return props.primary ? 'primary_color' : '';
		});
		return {
			primaryColor,
		};
	},
};
</script>

<style scoped>
.st0 {
	fill: none;
	stroke: #222222;
	stroke-width: 1.5;
	stroke-linecap: round;
	stroke-miterlimit: 10;
}
.primary_color {
	stroke: var(--color-primary);
}
</style>