export default function useLocalStorage() {
    function getLocalStorageItem(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    function setLocalStorageItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /*
  return true : 로컬 스토리지에 없음, 저장 O
  return false : 로컬 스토리지에 있음, 저장 x
*/
    function checkLocalStorage(key, item_key, value) {
        const obj = getLocalStorageItem(key);

        if (Array.isArray(obj) && obj.find((item) => String(item[item_key]) === String(value))) {
            return false;
        } else {
            return true;
        }
    }

    return {
        getLocalStorageItem,
        setLocalStorageItem,
        checkLocalStorage,
    };
}