<template>
	<!-- 홈화면 팝업 START -->
	<div class="home-popup" v-show="isPopupShow">
		<div class="btn_close">
			<img
				src="@/assets/images/common/icon_close_w.svg"
				style="width: 2rem; height: 2rem; float: right; cursor: pointer"
				@click="isPopupShow = false"
			/>
		</div>
		<div class="image_wrap" @click="$router.push({ name: 'EventAward' })">
			<img
				style="object-fit: contain; width: 100%; height: 100%; object-position: center"
				src="@/assets/images/main/popup/event_award.png"
				alt=""
			/>
		</div>
	</div>
	<!-- 홈화면 팝업 END -->
</template>

<script>
	import { ref } from 'vue';
	export default {
		setup() {
			let isPopupShow = ref(true);
			return {
				isPopupShow,
			};
		},
	};
</script>

<style scoped>
	.home-popup {
		/* width: 90vw;
		height: 90vw;
		max-width: 60rem;
		max-height: 60rem; */
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 9999;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 1rem;
	}
	.home-popup .btn_close {
		width: 90vw;
		max-width: 60rem;
	}
	.home-popup .image_wrap {
		width: 90vw;
		height: 90vw;
		max-width: 60rem;
		max-height: 60rem;
	}
	.home-popup .btn_popup_close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
		margin-top: -1rem;
		background-color: var(--color-bg-dark);
		color: #fff;
		cursor: pointer;
	}
</style>
