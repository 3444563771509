<template>
	<div class="inquiry_item">
		<li>
			<div
				v-if="isMyInquiry"
				class="td20 product"
				@click="$router.push({ name: 'ProductDetail', params: { product_pk: data.product_pk } })"
			>
				<img :src="data.path + data.save_name" v-noimage />
				<div class="name_and_brand">
					<p class="product_name">{{ data.product_name }}</p>
					<p class="brand_name">{{ data.brand_name }}</p>
				</div>
			</div>

			<span v-if="!isMyInquiry" class="td10 num">{{ index }}</span>

			<span class="td20 id division">{{ replyDivision }}</span>
			<span class="td30 title">
				<a href="javascript:void(0)" @click="$emit('question-click', index - 1)">
					<span class="text" :class="data.exposure_yn === 'true' ? '' : 'icon_lock'">{{
						data.exposure_yn === 'false'
							? data.name === $store.state.user.user.name
								? data.title
								: '비공개 문의입니다.'
							: data.title
					}}</span>
					<!--공개질문시 아이콘사라짐-->
				</a>
			</span>
			<span class="td15 id writer" v-if="!isMyInquiry">{{ data?.name ? data?.name.slice(0, 2) + '***' : '***' }}</span>
			<span class="td20 date">{{ data.enrollment_date.slice(0, 10) }}</span>
			<span class="td10 id status">
				<span class="reply_status">
					{{ data.state }}
				</span>
			</span>
		</li>

		<div
			v-if="(isShown && data.exposure_yn === 'true') || (isShown && data.exposure_yn !== 'true' && openChecked)"
			class="detail_question_content"
		>
			<div class="detail_info">
				<span class="writer">{{ data?.name ? data?.name.slice(0, 2) + '***' : '***' }}</span>
				<span class="date">{{ data.enrollment_date.slice(0, 10) }}</span>
			</div>
			<div class="question_content">
				<p>{{ data.content }}</p>
				<div style="display: flex; gap: 1rem" v-if="data.name === user.name">
					<button @click="$emit('edit-inquiry', index - 1)" class="btn_primary" style="padding: 0.5rem 0">
						수정하기
					</button>
					<button @click="$emit('delete-inquiry', index - 1)" class="btn_primary" style="padding: 0.5rem 0">
						삭제하기
					</button>
				</div>
			</div>
			<div class="answer" v-for="inquiry in data.answer" :key="inquiry.inquiry_pk">
				<div class="answer_content">
					<p style="white-space: break-spaces">
						{{ data.state === '답변대기' ? '아직 답변이 없습니다' : inquiry.content }}
					</p>
					<p style="color: var(--color-font-light); min-width: 8rem">
						{{ data.enrollment_date.slice(0, 10) }}
					</p>
				</div>
			</div>
		</div>
		<div class="locked_content" v-if="isShown && data.exposure_yn === 'false' && !openChecked">
			<p>비공개 문의글입니다</p>
			<!-- <div class="input_wrap">
				<label for="exposure_pw">비밀번호</label>
				<input id="exposure_pw" type="password" v-model="exposurePw" />
			</div> -->
		</div>
	</div>
</template>

<script>
	import { ref, onBeforeUnmount, onMounted, computed } from 'vue';
	import { useStore } from 'vuex';

	import ProductInfo from '@/components/molcules/Product/RowProductItem.vue';

	export default {
		components: {
			ProductInfo,
		},
		props: {
			index: {
				type: Number,
			},
			data: {
				type: Object,
				required: true,
			},
			isShown: {
				type: Boolean,
				default: false,
			},
			isMyInquiry: {
				type: Boolean,
				default: false,
			},
		},
		setup(props) {
			const store = useStore();
			const user = computed(() => store.getters['user/user']);

			const replyDivision = computed(() => {
				switch (props.data.division) {
					case 'detail':
						return '상품상세문의';
					case '상품상세문의':
						return '상품상세문의';
					case 'size':
						return '사이즈';
					case '사이즈':
						return '사이즈';
					case 'delivery':
						return '배송';
					case '배송':
						return '배송';
					case 'wearing':
						return '재입고';
					case '재입고':
						return '재입고';
				}
				return '';
			});
			const openChecked = computed(() =>
				props.isMyInquiry || (props.data.exposure_yn === 'false' && props.data.content) ? true : false,
			);

			// onMounted(() => {
			// 	props.isMyInquiry ? (openChecked.value = true) : (openChecked.value = false);
			// });

			return {
				openChecked,
				user,
				replyDivision,
			};
		},
	};
</script>
<style scoped></style>
