<template>
	<!--오늘의 핫딜 START-->
	<div class="timedeal_container">
		<h2 class="hotdeal_title">
			<strong>{{ products.content }}</strong>
			<p class="sub-title">{{ products.sub_content }}</p>
		</h2>
		<div>
			<TimedealProductSection day="오늘" @onClick-next="onClickNext" :products="products" v-if="currentDay === 0" />
		</div>
		<!--오늘의 핫딜 END-->
	</div>
</template>

<script>
	import SwiperCore, { Pagination, Autoplay } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import 'swiper/swiper-bundle.min.css';

	import SwiperList from '@/components/molcules/Scroll/SwiperList.vue';
	import TimeDealItem from '@/components/molcules/Product/RowProductItem.vue';
	import ProductItemVue from '@/components/molcules/Product/ProductItem.vue';
	import TimedealProductSection from './TimedealProductSection.vue';
	import { numberFormat } from '@/utils/numberFormat';

	import { toRefs, computed } from '@vue/reactivity';
	import { ref } from 'vue';
	import { Countdown } from 'vue3-flip-countdown';
	import OnlyTodayProduct from './OnlyTodayProduct.vue';

	SwiperCore.use([Pagination, Autoplay]);

	export default {
		components: {
			ProductItemVue,
			TimeDealItem,
			SwiperList,
			Swiper,
			SwiperSlide,
			TimedealProductSection,
			Countdown,
			OnlyTodayProduct,
		},
		props: {
			//useSlideYn: Boolean,
			products: Array,
			//layoutType: String,
			//subContents: String,
		},
		mixins: [numberFormat],
		setup(props) {
			const { products: timeDealProduct } = toRefs(props);
			const currentDay = ref(0);

			const onClickNext = () => {
				currentDay.value++;
			};

			const timeDealSwiperOption = computed(() => {
				return {
					speed: 500,
					loop: timeDealProduct.value.length > 3 ? true : false,
					autoplay:
						timeDealProduct.value.length > 3
							? {
									delay: 5000,
									disableOnInteraction: false,
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  }
							: false,
					navigation: true,
					pagination: {
						clickable: true,
					},
					breakpoints: {
						899: {
							slidesPerView: 1,
							slidesPerGroup: timeDealProduct.value.length >= 2 ? 2 : timeDealProduct.value.length,
							spaceBetween: 10,
						},
						930: {
							slidesPerView: timeDealProduct.value.length >= 2 ? 2 : timeDealProduct.value.length,
							slidesPerGroup: timeDealProduct.value.length >= 2 ? 2 : timeDealProduct.value.length,
							spaceBetween: 10,
						},
						1130: {
							slidesPerView: timeDealProduct.value.length >= 3 ? 3 : timeDealProduct.value.length,
							slidesPerGroup: timeDealProduct.value.length >= 3 ? 3 : timeDealProduct.value.length,
							spaceBetween: 10,
						},
					},
				};
			});
			return { timeDealSwiperOption, currentDay, onClickNext };
		},
	};
</script>

<style scoped>
	.non_slide_item {
		width: calc(33% - 1rem);
	}
	.grid_non_slide_item {
		width: calc(20% - 1rem);
	}

	@media (max-width: 931px) {
		.non_slide_item {
			width: calc(50% - 1rem);
		}
		.grid_non_slide_item {
			width: calc(25% - 1rem);
		}
	}
	@media (max-width: 769px) {
		.grid_non_slide_item {
			width: calc(33% - 1rem);
		}
	}
	@media (max-width: 600px) {
		.non_slide_item {
			min-width: 100%;
			padding: 1rem 0rem;
		}
		.grid_non_slide_item {
			width: calc(50% - 1rem);
		}
	}
	@media (max-width: 426px) {
		.grid_non_slide_item {
			width: calc(100% - 4rem);
			padding: 2rem;
		}
	}
</style>
