<template>
	<div class="desktop_share_popup center-space-between">
		<div class="center-space-between" style="margin-left: 1rem">
			<LinkIcon @click="shareLink" />
			<div
				id="kakao-link-btn"
				ref="kakaoLinkRef"
				class="icon_wrapper"
				style="background: yellow"
				@click="() => snsShare('kakao')"
			>
				<img src="@/assets/images/common/icon_login_social_kakao.svg" alt="카카오톡으로 공유하기" />
			</div>

			<!--<div class="icon_wrapper" style="background: #1da1f2">
				<ShareNetwork
					style="align-items: center; justify-content: center; display: flex"
					network="twitter"
					:url="share.url"
					:title="share.title"
					:description="share.description"
					:hashtags="share.hashtags"
				>
					<img src="@/assets/images/common/icon_social_twitter.svg" alt="이페이지를 공유하기" />
				</ShareNetwork>
			</div>
			<div @click="() => snsShare('facebook')" class="icon_wrapper" style="background: #1877f2">
				<img src="@/assets/images/common/icon_social_face.svg" alt="이페이지를 공유하기" />
			</div>-->
		</div>
		<!--<div class="btn_close_wrapper border-left-wrapper">
			<button class="btn_close" style="position: relative" @click="$emit('close-share-popup')">
				<span></span>
			</button>
		</div>-->
	</div>
</template>

<script>
	import { ref, computed, onBeforeUnmount, onMounted } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	import { useClipboard } from '@vueuse/core';

	import LinkIcon from '@/components/atoms/icons/LinkIcon.vue';
	import { numberFormat } from '@/utils/numberFormat';

	//const FACEBOOK_SHARE_URL = 'http://www.facebook.com/sharer/sharer.php?u=';
	//const TWITTER_SHARE_URL = 'http://www.twitter.com/share?url=';
	//const TWITTER_PIC_OPTIONS = '&pic.twitter.com/';
	//const POPUP_OPTIONS = '';

	export default {
		head() {
			return {
				script: [{ src: '//developers.kakao.com/sdk/js/kakao.min.js' }],
			};
		},
		components: {
			LinkIcon,
		},
		props: {
			imageSrc: String,
			description: String,
			menu: String,
			inviteUrl: String,
			price: Number,
		},
		minins: [numberFormat],
		setup(props) {
			const store = useStore();
			const route = useRoute();
			const { copy, text } = useClipboard();
			const productPrice = numberFormat.methods.numberFormat(props.price);

			const share = computed(() => {
				return {
					url: `${process.env.VUE_APP_MALL_URL}${route.fullPath}`,
					title: `${process.env.VUE_APP_MALL_TITLE}\n`,
					description: props.description,
					// hashtags: '마이헤이데이,MYHEYDAY,명품,명품쇼핑몰',
				};
			});

			const kakaoLinkRef = ref(null);
			onMounted(() => {
				window.Kakao.init(process.env.VUE_APP_KAKAO_API_KEY);
			});
			onBeforeUnmount(() => {
				window.Kakao.cleanup();
			});
			const url = computed(() => {
				if (props.menu === 'invite') {
					return props.inviteUrl;
				}
				//return `${process.env.VUE_APP_MALL_URL}${route.fullPath}`;
				//return window.location.href + ;
				return window.location.href + (store.state.user.isLogin ? `?code=${store.state.user.user.code}` : '');
			});

			function shareLink() {
				copy(url.value);
				store.commit('alert', 'URL이 클립보드에 복사되었습니다');
			}

			//const shareFacebook = () => window.open(FACEBOOK_SHARE_URL + url.value, 'popup', POPUP_OPTIONS);
			//const shareTwitter = () =>
			//	window.open(
			//		TWITTER_SHARE_URL + encodeURIComponent(url.value) + TWITTER_PIC_OPTIONS + encodeURIComponent(props.imageSrc),
			//		'popup',
			//		POPUP_OPTIONS,
			//	);
			function snsShare(sns) {
				switch (sns) {
					case 'kakao':
						shareKakao();
						break;
					//case 'facebook':
					//	shareFacebook();
					//	break;
					//case 'twitter':
					//	shareTwitter();
					//	break;
				}
			}
			function shareKakao() {
				// 카카오링크 버튼 생성
				if (!window.Kakao.isInitialized()) {
					window.Kakao.init('5302d6d764cb058aa6957ded6ef91d30');
				}

				// window.Kakao.Link.sendScrap({
				// 	requestUrl: 'https://sonagimall.com/', // 페이지 url
				// 	templateId: 12345, // 메시지템플릿 번호
				// });

				if (props.menu === 'invite') {
					// 회원 초대 공유
					window.Kakao.Link.sendDefault({
						objectType: 'feed',
						content: {
							title: '회원 초대', // 보여질 제목
							description: '버튼을 누르면 회원가입 페이지로 이동합니다',
							imageUrl: 'https://admin.sonagimall.com/sonagimall/share.jpg',
							link: {
								mobileWebUrl: url.value,
								webUrl: url.value,
							},
						},
						buttons: [
							{
								title: `${process.env.VUE_APP_MALL_NAME}로 이동`,
								link: {
									mobileWebUrl: url.value,
									webUrl: url.value,
								},
							},
						],
					});
				} else {
					// 상품 정보 공유
					window.Kakao.Link.sendDefault({
						objectType: 'feed',
						content: {
							title: props.description, // 보여질 제목
							description: `${productPrice}원`, // 보여질 설명
							imageUrl: `https:${props.imageSrc}`,
							link: {
								mobileWebUrl: url.value,
								webUrl: url.value,
							},
						},
						buttons: [
							{
								title: `${process.env.VUE_APP_MALL_NAME}로 이동`,
								link: {
									mobileWebUrl: url.value,
									webUrl: url.value,
								},
							},
						],
					});
				}
			}
			return { share, kakaoLinkRef, url, shareLink, snsShare, productPrice };
		},
	};
</script>

<style scoped>
	/*.center-space-between {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
	}*/
	/*.border-left-wrapper {
		border-left: 1px solid var(--color-border-light);
		height: 100%;
		justify-content: center;
		align-items: center;
		padding: 0 1rem;
		margin-left: 0.5rem;
	}*/
	.desktop_share_popup {
		margin: unset;
		width: fit-content;
		height: auto;
		padding: 0.7rem;
		background: white;
		right: 0;
		box-shadow: 1px 2px 4px rgba(146, 146, 146, 0.5);
	}
	.desktop_share_popup .center-space-between {
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: 1rem;
	}

	/*button.btn_close span::before,
	button.btn_close span::after {
		content: '';
		display: block;
		width: 0.2rem;
		height: 2rem;
		background-color: var(--color-border-mid);
		position: absolute;
		top: 10px;
	}*/

	/*button.btn_close span::before {
		transform: rotate(-45deg);
	}

	button.btn_close span::after {
		transform: rotate(45deg);
	}*/
	@media (max-width: 768px) {
		.btn_close_wrapper button span {
			top: -0.5rem;
		}
	}
	@media screen and (max-width: 398px) {
		.desktop_share_popup {
			top: 4rem;
			/* left: 6rem; */
			right: 0;
		}
	}
</style>
