<template>
	<div class="img_container">
		<div class="overlay"></div>
		<div class="img_wrapper">
			<div class="inner_wrapper">
				<img
					v-noimage
					:src="img_url"
					alt="이미지가 없습니다."
					@load="onImgLoad($event)"
					@error="onErrorImage($event)"
				/>
			</div>
		</div>

		<!-- <div class="overlay"></div>
		<img
			:src="img_url"
			alt="사진이 없습니다."
			@load="onImgLoad($event)"
			@error="onErrorImage($event)"
		/> -->
	</div>
</template>

<script>
	export default {
		props: {
			item_path: {
				type: String,
				required: false,
			},
		},

		computed: {
			img_url() {
				return this.item_path;
			},
		},
		methods: {
			onImgLoad(event) {
				//console.log("onImgLoad", event.currentTarget);
			},
			onErrorImage(event) {
				//console.log("onErrorImage", event.currentTarget);
			},
		},
	};
</script>

<style scoped>
	/*@keyframes spining {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}*/

	/* .img_container {
		height: 24rem;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
	}
	.img_container .overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.04);
	}
	.img_container img {
		width: 100%;
		max-height: 24rem;
		object-fit: contain;
	} */

	/* .img_container#loading img {
  width: 140px;
  height: auto;
}
.img_container #loading {
  opacity: 0.6;
} */

	/* .img_container#loading::after {
  content: "";
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: calc(50% - 3rem);
  left: calc(50% - 2rem);
  border-radius: 50%;
  border: 0.7rem solid var(--base-color1);
  border-top-color: var(--point-color);
  animation: spining 0.7s ease-in-out infinite;
  z-index: 100;
} */
</style>
