import { createApp } from 'vue';
import VueSocialSharing from 'vue-social-sharing';
import { VueQueryPlugin, QueryClient, VUE_QUERY_CLIENT } from 'vue-query';

import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import { lazy, noimage, infiniteScroll } from './directives';

axios.defaults.baseURL = process.env.VUE_APP_PRODUCTION_URI;
axios.defaults.headers.common['mall-code'] = process.env.VUE_APP_MALL_CODE;

let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};
const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

axios.interceptors.response.use(async function(response) {
    if (response.data.code == '99111') {
        // 파라미터 잘못 됨.
        router.push('/404');
    }

    if (response.data.code == '99105') {
        store.commit('user/logout');
    } else if (response.data.code == '99104') {
        const originalRequest = response.config;

        if (!isTokenRefreshing) {
            // isTokenRefreshing이 false인 경우에만 token refresh 요청
            isTokenRefreshing = true;
            const refresh_token = store.state.access_token.refresh_token;
            // token refresh 요청

            axios
                .get('/requestToken', { params: { refresh_token } })
                .then((res) => {
                    const { data } = res;
                    // 새로운 토큰 저장
                    store.state.access_token.access_token = data.access_token;
                })
                .then(() => {
                    // 새로운 토큰으로 지연되었던 요청 진행
                    onTokenRefreshed(store.state.access_token.access_token);
                    isTokenRefreshing = false;
                })
                .then(() => {
                    refreshSubscribers = [];
                });
        }

        // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve) => {
            // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
            addRefreshSubscriber((access_token) => {
                // console.log('요청 실행', access_token, originalRequest);
                originalRequest.headers.authorization = access_token;

                // 99104로 요청 실패했던 요청 새로운 accessToken으로 재요청
                resolve(axios(originalRequest));
            });
        });

        return retryOriginalRequest;
    }
    store.commit('finishLoading');
    return response;
}, (error) => {
    if (error.response.status == '500') {
        store.commit('alert', '에러가 발생하였습니다. 고객센터에 문의해주세요.');
        setTimeout(() => {

            router.push('/')
        }, 2000);
    }
    return Promise.reject(error);
});


// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App);
app.config.globalProperties.axios = axios;

app.directive('lazy', lazy);
app.directive('noimage', noimage);
app.directive('infinite-scroll', infiniteScroll);

const queryClient = new QueryClient();
queryClient.mount();

const vueQueryPluginOptions = {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
};
app.use(store).use(router).use(VueSocialSharing).use(VueQueryPlugin, vueQueryPluginOptions).mount('#app');