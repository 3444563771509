export default {
    namespaced: true,
    state: {
        color: [],
        brand: [],
    },
    mutations: {
        setFilters(state, { color, brand }) {
            state.color = color;
            state.brand = brand;
        },
    },
    actions: {},
    getters:{
        colorCSSVar(state){
            const cssVar={};
            state.color.forEach(v=>{
                cssVar[`--color-label-${v.color_pk}`] = v.color_code;
            });
            return cssVar;
        }
    }
};