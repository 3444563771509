import router from '../../router';
import useAxios from '@/compositions/useAxios';
const { fetchData } = useAxios();

export default {
	namespaced: true,
	state: {
		splashLoaded: false,

		templateData: {
			basic_logo: {},
			black_logo: {},
			footer: {},
			policy: [{}, {}],
			theme: {
				design: '',
				color: '',
				use_yn: '',
			},
			white_logo: {},
			account: {},
		},
		timedeal: {
			endDate: '',
			remainDay: '',
			remainHour: '',
			remainMinute: '',
			remainSecond: '',
		},
		isTimeDealEnd: false,

		mallName: process.env.VUE_APP_MALL_NAME,
	},
	mutations: {
		setTemplate(state, payload) {
			state.templateData = payload;
			// localStorage.setItem('');
		},
		setTheme(state, theme) {
			state.templateData.theme = theme;
		},
		setTimeDealEndDate(state, endDate) {
			state.timedeal.endDate = endDate;
		},
		setTimeDealRemainTime(state, { remainDay, remainHour, remainMinute, remainSecond }) {
			state.timedeal.remainDay = remainDay;
			state.timedeal.remainHour = remainHour;
			state.timedeal.remainMinute = remainMinute;
			state.timedeal.remainSecond = remainSecond;
		},
		setIsTimedealEnd(state) {
			state.isTimeDealEnd = true;
		},
		setSplashLoaded(state, value) {
			state.splashLoaded = value;
		},
	},
	actions: {
		GET_TEMPLATE(context) {
			// 주문하기 들어가기 전, 테마가 null 일 때 template 다시 불러오기
			fetchData('/getTemplate', (data) => {
				context.commit('setTemplate', data);
			});
		},
	},
	getters: {
		logo(state) {
			return state.templateData.basic_logo
				? localStorage.getItem('basicLogo')
				: state.templateData.black_logo
				? localStorage.getItem('basicLogo')
				: state.templateData.white_logo
				? localStorage.getItem('basicLogo')
				: require('../../assets/images/common/ci_color.svg');
		},
		basicLogo(state) {
			return state.templateData.basic_logo.path + state.templateData.basic_logo.save_name;
		},
		blackLogo(state) {
			return state.templateData.black_logo.path + state.templateData.black_logo.save_name;
		},
		footer(state) {
			return state.templateData.footer;
		},
		terms(state) {
			return state.templateData.policy[1];
		},
		policy(state) {
			return state.templateData.policy;
		},
		privacyPolicy(state) {
			return state.templateData.policy[0];
		},
		theme(state) {
			return state.templateData.theme;
		},
		whiteLogo(state) {
			return state.templateData.white_logo.path + state.templateData.white_logo.save_name;
		},
		account(state) {
			return state.templateData.account;
		},
		menu(state) {
			return state.templateData.menu;
		},
		timedealEndDate(state) {
			return state.timedeal.endDate;
		},
		mallName(state) {
			return state.mallName;
		},
		eventPk(state) {
			if (!state.templateData.menu) return null;
			return state.templateData.menu.find((v) => v.content === '이벤트').homepage_menu_pk;
		},
		magazinePk(state) {
			if (!state.templateData.menu) return null;
			return state.templateData.menu.find((v) => v.content === '매거진').homepage_menu_pk;
		},
		themePk(state) {
			if (!state.templateData.menu) return null;
			return state.templateData.menu.find((v) => v.content === '기획전').homepage_menu_pk;
		},
		splashLoaded(state) {
			return state.splashLoaded;
		},
	},
};
