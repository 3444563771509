<template>
	<div class="modal_container">
		<div class="btn_close_wrapper">
			<button @click="$store.commit('modal/closeReviewModal', isRefreshed)">
				<span></span>
			</button>
		</div>
		<div class="modal_wrapper">
			<div class="div_inner">
				<h4>리뷰쓰기</h4>
				<div class="content_wrapper">
					<div class="div_scroll">
						<div class="review_content_wrapper">
							<div class="review_section">
								<RowProductItem
									:product="{
										save_name: orderDetail.save_name,
										path: orderDetail.path,
										product_name: orderDetail.product_name,
										brand_name: orderDetail.brand,
										product_saleprice: orderDetail.order_price,
										discount_price: ((orderDetail.pay_price - orderDetail.order_price) / 100).toFixed(0),
										discount_unit: '%',
									}"
									menu="review"
									@click.capture.stop=""
								/>
							</div>
							<div class="review_section">
								<h4>상품은 만족하셨나요?</h4>
								<div class="star_wrap" @mouseleave="removeStar">
									<span
										class="icon_star"
										v-for="i in 5"
										:key="i"
										style="cursor: pointer"
										:class="starStyle[i - 1] || starFixed[i - 1]"
										@mouseover="overStar(i - 1)"
										@click="setStar(i - 1)"
									></span>
								</div>
								<span>{{ startMessage }}</span>
							</div>
							<div class="review_section">
								<h4>어떤 점이 좋았나요?</h4>
								<textarea
									v-model="reviewComments"
									placeholder="최소 10자 이상 입력해주세요"
									cols="30"
									rows="10"
									style="padding: 1rem"
								></textarea>
							</div>
							<div class="review_section">
								<div
									style="
										width: 100%;
										height: 20rem;
										display: flex;
										flex-direction: column;
										align-items: center;
										justify-content: center;
									"
								>
									<div class="review_image_container">
										<div
											class="review_image_wrapper"
											v-for="(thumbnail, idx) in reviewThumbs"
											:key="thumbnail.key"
											@click="removeImage(idx)"
										>
											<img :src="thumbnail.src" alt="" class="review_image" />
										</div>
									</div>
									<label
										for="review-image"
										style="
											cursor: pointer;
											border: 1px solid var(--color-primary);
											padding: 0.5rem 1rem;
											border-radius: 5px;
										"
									>
										{{ reviewThumbs !== '' ? '사진 변경' : '사진 추가' }}
									</label>
									<input
										type="file"
										id="review-image"
										style="display: none"
										@change="
											(event) => {
												getImages(event);
												event.target.value = '';
											}
										"
										multiple
									/>
								</div>
								<span>상품과 무관한 사진을 첨부한 리뷰는 통보없이 삭제 및 적립 혜택이 회수 될 수 있습니다.</span>
							</div>
							<div>
								<input type="checkbox" id="exposure_yn" v-model="exposure_yn" />
								<label for="exposure_yn">리뷰 공개 여부</label>
							</div>
						</div>
					</div>
				</div>
				<div class="btn_submit_wrapper">
					<button @click="saveReview">확인</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, onMounted, onBeforeUnmount, ref, watch } from '@vue/runtime-core';
	import useSelectImage from '@/compositions/useSelectImage';
	import { useStore } from 'vuex';
	import { useRouter } from 'vue-router';
	import useAxios from '@/compositions/useAxios';
	import { deleteReviewImage } from '@/api/index';

	import RowProductItem from '@/components/molcules/Product/RowProductItem.vue';
	import { isNumber } from '@vueuse/core';

	export default {
		components: {
			RowProductItem,
		},
		setup() {
			const store = useStore();
			const router = useRouter();
			const orderDetail = computed(() => store.state.modal.reviewOrderDetail);

			onBeforeUnmount(() => {
				store.commit('modal/closeReviewModal');
			});

			const { star, starStyle, starFixed, overStar, removeStar, setStar } = useStar();

			const reviewImages = ref([]);
			const reviewThumbs = ref([]);

			const { getImage, getImages } = useSelectImage({
				images: reviewImages,
				thumbnails: reviewThumbs,
			});

			const isRefreshed = ref(false); // 사진을 지우면 새로고침 필요
			async function removeImage(idx) {
				const removed = reviewThumbs.value.splice(idx, 1)[0];
				reviewImages.value = reviewImages.value.filter((image) => image.key !== removed.key);
				// isNumber(removed.key) === true 면 DB에 저장된 사진임.
				if (isNumber(removed.key)) {
					const resCode = await deleteReviewImage(removed.key, orderDetail.value.review.product_order_pk);
					if (resCode === 99201) {
						store.commit('alert', '이미지가 삭제되었습니다.');
						isRefreshed.value = true;
					}
				}
			}

			const startMessage = computed(() => {
				switch (star.value) {
					case 0:
						return '별로에요';
					case 1:
						return '약간 별로에요';
					case 2:
						return '보통이에요';
					case 3:
						return '좋아요';
					case 4:
						return '매우 좋아요';
					default:
						return '별점을 선택해주세요';
				}
			});
			const reviewComments = ref('');
			const exposure_yn = ref(true);

			function checkStar() {
				return star.value >= 0 ? true : false;
			}
			function checkTextAreaLength() {
				return reviewComments.value.length >= 10 ? true : false;
			}

			const { postData } = useAxios();
			const isEditMode = ref(false);
			function saveReview() {
				if (!checkStar()) {
					alert('별점을 입력해주세요.');
				} else if (!checkTextAreaLength()) {
					alert('후기는 10자 이상 입력해주세요.');
				} else {
					const params = {
						rno: orderDetail.value.review ? orderDetail.value.review?.review_pk : '', // 수정할 때 필요
						type: 'S',
						pno: orderDetail.value?.product_order_pk || '',
						content: reviewComments.value,
						rating: star.value + 1,
						exposure_yn: exposure_yn.value,
					};
					const files = reviewImages.value.map((item) => item.src);
					const formData = new FormData();
					Object.keys(params).forEach((key) => formData.append(key, params[key]));

					for (let i = 0; i < files.length; i++) {
						formData.append(`file[${[i]}]`, files[i]);
					}
					postData('/saveReview', formData, () => {
						store.commit('modal/closeReviewModal');
						router.go(0);
					});
				}
			}
			onMounted(() => {
				if (orderDetail?.value.review) {
					// 리뷰 수정 모드

					overStar(orderDetail.value.review.rating - 1);
					setStar(orderDetail.value.review.rating - 1);
					reviewComments.value = orderDetail.value.review.review;

					reviewThumbs.value = orderDetail.value.review.review_image.map((image, i) => {
						return { src: image.path + image.save_name, key: image.review_image_pk };
					});
				}
			});
			return {
				star,
				starStyle,
				starFixed,
				overStar,
				removeStar,
				setStar,
				reviewComments,
				exposure_yn,
				reviewImages,
				reviewThumbs,
				getImage,
				getImages,
				saveReview,
				orderDetail,
				startMessage,
				removeImage,
				isRefreshed,
			};
		},
	};
	const useStar = () => {
		const starFixed = ref([]);
		const star = ref(-1);
		const starStyle = ref([]);
		function overStar(i) {
			for (let idx = i + 1; idx < 5; idx++) {
				starStyle.value[idx] = '';
			}
			for (let idx = i; idx >= 0; idx--) {
				starStyle.value[idx] = 'on';
			}
		}
		function removeStar() {
			for (let i = 0; i < star.value + 1; i++) {
				starStyle.value[i] = 'on';
			}
			for (let i = star.value + 1; i < 5; i++) {
				starStyle.value[i] = '';
			}
		}

		function setStar(i) {
			starStyle.value = [];
			for (let idx = 0; idx <= i; idx++) {
				starStyle.value[idx] = 'on';
			}
			star.value = i;
			starFixed.value = starStyle.value;
		}
		return { star, starStyle, starFixed, overStar, removeStar, setStar };
	};
	const useReviewPhoto = () => {
		const reviewThumb = ref('');
		const reviewImage = ref({});

		return {
			reviewImage,
			reviewThumb,
		};
	};
</script>

<style scoped>
	.review_content_wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 4rem 0;
	}
	.review_content_wrapper .review_section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding: 1rem 0;
		gap: 1rem 0;
	}
	.review_content_wrapper .review_section >>> img {
		max-width: 120px;
	}
	.review_content_wrapper::-webkit-scrollbar {
		width: 6px;
	}
	.review_content_wrapper::-webkit-scrollbar-thumb {
		background-color: var(--color-border-light);
		border-radius: var(--size-radius-full);
	}
	@media screen and (max-width: 768px) {
		.review_content_wrapper {
		}
	}
	.review_image_container {
		width: 95%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		border: 1px solid var(--color-border-mid);
		margin-bottom: 1rem;
		gap: 0.5rem;
	}
	.review_image_wrapper {
		position: relative;
		cursor: pointer;
		width: 10rem;
		height: 10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid rgb(0, 0, 0, 0);
	}
	.review_image_wrapper:hover {
		border: 1px solid var(--color-border-light);
		filter: grayscale(80%);
		opacity: 0.7;
	}
	.review_image_wrapper:hover::before,
	.review_image_wrapper:hover::after {
		content: '';
		display: block;
		width: 0.2rem;
		height: 5rem;
		background-color: var(--color-border-dark);
		position: absolute;
		top: calc(50% - 2.5rem);
		right: calc(50%);
	}

	.review_image_wrapper::before {
		transform: rotate(-45deg);
	}

	.review_image_wrapper::after {
		transform: rotate(45deg);
	}
	.review_image {
		width: 10rem;
		padding: 1rem 0;
	}
	/* .review_image_wrapper:hover .review_image {
		filter: grayscale(0.8);
	} */
</style>
