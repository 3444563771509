import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import router from '../router';
import useAxios from './useAxios';

export default function useWishList() {
	const { postData } = useAxios();
	const store = useStore();
	const route = useRoute();

	function addWishItem(product_pk) {
		let wishPk;
		if (!store.state.access_token.access_token) {
			store.commit('alert', '로그인 후 이용가능합니다.');
			return new Promise((resolve) => {
				resolve(router.push({ name: 'Login', params: { redirect: route.fullPath } }));
			});
		} else {
			return new Promise((resolve) => {
				postData(
					'/saveWish',
					{
						pno: product_pk,
					},
					(data) => {
						store.commit('alert', '관심 상품에 추가되었습니다.');
						resolve(data.wish_pk);
						// alert('관심 상품에 추가되었습니다.');
					},
					false,
				);
			});
		}
	}

	function removeWishItem(product_pk, wish_pk) {
		if (store.state.user.isLogin) {
			postData(
				'/saveWish',
				{
					pno: product_pk,
					wno: wish_pk,
				},
				() => {
					// alert('관심 상품이 삭제되었습니다.');
					store.commit('alert', '관심 상품이 삭제되었습니다.');
				},
				false,
			);
		} else {
		}
	}

	return { addWishItem, removeWishItem };
}
