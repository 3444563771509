import useAxios from '@/compositions/useAxios';
import { fetchMyInquiry } from '@/api';

export default {
	namespaced: true,
	state: {
		orderData: [],
		myReviews: [],
		myInquiry: [],
	},
	mutations: {
		setOrderData(state, payload) {
			state.orderData = payload;
		},
		setMyReviews(state, payload) {
			state.myReviews = payload;
		},
		setMyInquiry(state, payload) {
			state.myInquiry = payload;
		},
	},
	actions: {
		async SET_ORDER_DATA(context) {
			const { fetchData } = useAxios();
			await new Promise((resolve, reject) => {
				fetchData('/getMyOrder', (data) => {
					data.order_all.reverse();
					data.order_cancel.reverse();
					data.order_complete.reverse();
					data.order_delivery.reverse();
					data.order_ready.reverse();
					data.order_shipping.reverse();
					data.order_wait.reverse();
					context.commit('setOrderData', data);
					resolve();
				});
			});
		},
		SET_REVIEWS(context) {
			const { fetchData } = useAxios();
			fetchData('/getMyReview', (data) => {
				context.commit('setMyReviews', data.review);
			});
		},
		async SET_MYINQUIRY(context) {
			context.commit('setMyInquiry', await fetchMyInquiry());
		},
	},
	getters: {
		orderData(state) {
			return state.orderData;
		},
		myReviews(state) {
			return state.myReviews;
		},
		myInquiry(state) {
			return state.myInquiry;
		},
	},
};
