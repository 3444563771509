<template>
	<button type="button" class="btn_common btn_whire btn_primary-fill" v-if="writable">작성하기</button>
	<div class="contents_wrap">
		<div class="list_header order_select">
			<div>
				<div class="select_box">
					<select v-model="currentFilter">
						<option value="최신 등록순" :class="currentFilter === '최신 등록순' ? 'on' : ''">최신 등록순</option>
						<option value="최고 평점순" :class="currentFilter === '최고 평점순' ? 'on' : ''">최고 평점순</option>
						<option value="추천 많은순" :class="currentFilter === '추천 많은순' ? 'on' : ''">추천 많은순</option>
					</select>
					<span class="select_box_icon">
						<img src="/img/icon_drop_arrow.b010ffd0.svg" alt="" />
					</span>
				</div>
			</div>
			<!-- <button type="button" class="btn_common only_photo_review">포토후기 모아보기</button> -->
		</div>

		<div type="button" class="review">
			<div class="review_list_container" v-if="renderReviews.length > 0">
				<div v-for="review in renderReviews.filter((item) => item.exposure_yn === 'true')" :key="review.review_pk">
					<ReviewItem :review="review" />
				</div>
			</div>
			<div class="review_list_container" v-else>
				<p class="no-content">리뷰가 없습니다.</p>
			</div>
		</div>
		<IntersectBox :isShown="renderReviews.length !== 0" :onIntersected="() => loadMore()" />
		<!-- <ul class="page_nav" v-if="reviews.length > 0">
			<li class="first"></li>
			<li class="prev"></li>
			<li class="on">1</li>
			<li>2</li>
			<li>3</li>
			<li>4</li>
			<li>5</li>
			<li class="m_hidden">6</li>
			<li class="m_hidden">7</li>
			<li class="m_hidden">8</li>
			<li class="m_hidden">9</li>
			<li class="m_hidden">10</li>
			<li class="next"></li>
			<li class="last"></li>
		</ul> -->
	</div>
</template>

<script>
	import ReviewItem from '@/components/molcules/Article/ReviewItem.vue';
	import IntersectBox from '@/components/molcules/IntersectBox.vue';
	import { ref } from '@vue/reactivity';
	import { computed } from '@vue/runtime-core';

	export default {
		components: {
			ReviewItem,
			IntersectBox,
		},
		props: {
			writable: {
				type: Boolean,
				default: true,
			},
			reviews: {
				type: Array,
			},
		},
		setup(props) {
			const currentFilter = ref('최신 등록순');

			const sortedReviews = computed(() => {
				const reviews = Array.from(props.reviews);
				if (currentFilter.value === '최신 등록순') {
					return reviews.sort((a, b) => new Date(b['enrollment_date']) - new Date(a['enrollment_date']));
				} else if (currentFilter.value === '최고 평점순') {
					return reviews.sort((a, b) => b['rating'] - a['rating']);
				} else {
					return reviews.sort((a, b) => b['like'] - a['like']);
				}
			});

			const renderReviews = computed(() => sortedReviews.value.slice(0, page.value * PER_PAGE));
			const page = ref(1);
			const PER_PAGE = 10;

			const loadMore = () => {
				if (page.value >= Math.ceil(sortedReviews.value.length / PER_PAGE)) return;

				return new Promise((resolve) =>
					setTimeout(() => {
						page.value++;
						resolve();
					}, 100),
				);
			};
			return { currentFilter, renderReviews, loadMore };
		},
	};
</script>

<style></style>
