<template>
	<div style="border-top: 0.1rem solid #eee">
		<footer class="c_inner" style="padding: 2rem">
			<div class="menu_terms" style="display: flex; gap: 20px; font-size: 14px">
				<li v-for="(policy, idx) in policies" :key="policy.policy_pk">
					<router-link :to="`/policy/${idx + 1}`" style="color: #555">
						{{ policy.policy }}
					</router-link>
				</li>
			</div>
		</footer>
	</div>
	<div v-html="footerTemplate.content"></div>
</template>

<script>
	import { computed } from 'vue';
	import { useStore } from 'vuex';
	export default {
		setup() {
			const store = useStore();
			const footerTemplate = computed(() => store.getters['template/footer']);
			const policies = computed(() => store.getters['template/policy']);
			return { footerTemplate, policies };
		},
	};
</script>

<style scoped>
	/*푸터 START*/
	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');

	/*푸터 START*/
	:deep(.footer_container) {
		padding: 0 0 10px 0;
		font-family: 'Noto Sans KR', sans-serif;
		font-weight: 400;
		border-top: 0.1rem solid #eee;
		background: #fff;
	}
	:deep(.footer_container .c_inner) {
		margin: 0 auto;
		padding: 0 20px;
		max-width: 1440px;
	}
	:deep(.footer_container .menu_terms) {
		margin: 20px 0;
		padding: 20px 0;
		display: flex;
		gap: 20px;
		/*border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;*/
	}
	:deep(.footer_container .menu_terms ul) {
		display: flex;
		gap: 20px;
		font-size: 14px;
	}
	:deep(.footer_container .menu_terms ul li) {
		color: #555;
		display: flex;
	}
	:deep(.footer_container .menu_terms ul li a) {
		color: inherit;
	}
	:deep(.footer_container .footer_bottom) {
		margin: 20px 0;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		line-height: 2;
	}
	:deep(.footer_container .company_info) {
		color: #aaa;
	}
	:deep(.footer_container .company_info p:first-child) {
		color: #555;
	}
	:deep(.footer_container .side_info) {
		display: flex;
		flex-direction: column;
		gap: 2rem 0;
	}
	:deep(.footer_container .side_info .title) {
		font-size: 14px;
	}
	:deep(.footer_container .side_info .cs ul li) {
		font-family: 'roboto', 'Noto Sans KR', sans-serif;
		letter-spacing: 0;
	}
	:deep(.footer_container .side_info .cs ul li.cs_tel) {
		margin: 0.4rem 0;
		font-size: 24px;
		font-weight: 700;
		line-height: 1;
		letter-spacing: 0;
	}
	:deep(.footer_container .side_info .cs ul li span) {
		color: #888;
	}
	:deep(.footer_container .side_info .escrow .img_wrapper) {
		width: 5rem;
	}

	@media screen and (max-width: 930px) {
		:deep(.footer_container) {
			margin-bottom: var(--size-bottom_nav-height);
		}
	}

	@media screen and (max-width: 768px) {
		:deep(.footer_container .cs) {
			flex-basis: 100%;
		}
		:deep(.footer_container .menu_terms) {
			padding: 20px 0;
		}
		:deep(.footer_container .menu_terms ul) {
			flex-direction: column;
			gap: 6px;
		}
		:deep(.footer_container .footer_bottom) {
			flex-direction: column;
			gap: 24px 0;
		}
	}
	/*푸터 END*/
</style>
