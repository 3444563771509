export const lazy = {
	mounted: (el) => {
		function loadImage(targetEl) {
			const imgEl = targetEl;
			imgEl.setAttribute('src', imgEl.getAttribute('data-src'));
			imgEl.onload = () => imgEl.removeAttribute('data-src');
		}

		function createObserver() {
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						loadImage(entry.target);
						observer.unobserve(entry.target);
					}
				});
			});

			observer.observe(el);
		}
		// 지원하지 않는 브라우저는 바로 이미지 로딩을 시켜주도록 호환성 체크
		window['IntersectionObserver'] ? createObserver() : loadImage();
	},
};

export const noimage = {
	mounted: (el) => {
		const imageSrc = el.getAttribute('src');
		if (!imageSrc || imageSrc == 0) {
			// src가 null || 0(false) || undefined
			el.setAttribute('src', require('@/assets/images/common/no_image.svg'));
		}
	},
};

export const infiniteScroll = {
	mounted: (el, binding) => {
		const handleIntersect = (entry) => {
			if (entry.isIntersecting) {
				binding.value.onScrollEnd();
			}
		};
		const observer = new IntersectionObserver((entries) => {
			handleIntersect(entries[0]);
		});
		observer.observe(el);
	},
};
