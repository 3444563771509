<template>
	<label class="label">{{ label }}</label>
	<div class="input_wrapper">
		<input
			:class="[isDanger ? 'is-danger' : '']"
			type="text"
			:value="modelValue"
			@input="(e) => $emit('update:modelValue', e.target.value)"
			v-bind="$attrs"
		/>
		<p class="help">{{ errorMessage }}</p>
	</div>
</template>

<script>
export default {
	name: 'Input',
	components: {},
	props: {
		modelValue: String,
		label: String,
		errorMessage: String,
		isDanger: Boolean,
	},
};
</script>
<style scoped>
label {
	padding-bottom: 0.6rem;
}
.is-danger {
	border: 1px solid var(--color-danger);
}
.help {
	padding: 5px 0;
	font-size: 1.3rem;
	color: var(--color-font-sub);
}
</style>
