import axios from 'axios';
import store from '../store';
import router from '../router';

import access_token from '../store/modules/access_token';

export default function useAxios() {
	function postData(url, payload, callback = () => {}, load = true) {
		if (load) store.commit('startLoading'); // 로딩 시작
		axios
			.post(url, payload, { headers: { authorization: store.state.access_token.access_token } })
			.then((res) => {
				// console.log('postData', url, res.data);
				if (url === '/requestPay') {
					callback(res.data);
				} else {
					if (res.data.success) {
						if (callback) {
							callback(res.data);
						}
					} else {
						store.commit('alert', res.data.message);
					}
				}

				store.commit('finishLoading'); // 로딩 완료
			})
			.catch((error) => {
				console.error(error);
			});
	}

	function fetchData(url, callback = () => {}, params = {}, load = true) {
		//console.log('fetchData params', params);
		if (load) store.commit('startLoading'); // 로딩 시작
		axios
			.get(url, {
				headers: { authorization: store.state.access_token.access_token },
				params: params,
			})
			.then((res) => {
				// console.log('fetchData', url, res.data);
				if (url === '/requestPay') {
					callback(res.data);
				} else {
					if (res.data.success) {
						if (callback) {
							callback(res.data);
						}
					} else {
						callback(res);
						store.commit('alert', res.data.message);
					}
				}
				store.commit('finishLoading'); // 로딩 완료
			})
			.catch((error) => {
				const status = error.message.slice(-3);
				if (status === '500') {
					router.push('/404');
				} else {
					if (status === '429') {
						store.commit('alert', `일시적인 에러입니다. 잠시 후 다시 시도해주세요.`);
						router.push('/');
					} else {
						store.commit('alert', `에러가 발생하였습니다. 잠시 후 다시 시도해주세요.`);
						router.push('/');
					}
				}
			});
	}

	return {
		postData,
		fetchData,
	};
}
