<template>
	<div v-if="useSlideYn">
		<!--<div class="more_product" >더보기 →</div>-->
		<SwiperList :productList="products" :pagination="false" />
	</div>
	<div v-else>
		<div class="product_list_base">
			<div class="product_list_container">
				<div class="grid_item" v-for="product in products" :key="product.product_pk">
					<ProductItem :product="product" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SwiperList from '@/components/molcules/Scroll/SwiperList.vue';
	import ProductItem from '@/components/molcules/Product/ProductItem.vue';
	export default {
		components: { ProductItem, SwiperList },
		props: {
			useSlideYn: Boolean,
			products: Array,
			display_pk: String,
		},
		setup(props) {},
	};
</script>

<style scoped>
	/*.more_product {
		position: relative;
		text-align: right;
		bottom: 2rem;
		cursor: pointer;
	}*/
	.product_list_base {
		padding: 0;
	}
	.product_list_container {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		box-sizing: border-box;
		gap: 4rem 2rem;
		text-align: center;
	}
	.grid_item {
		width: calc((100% - 10rem) / 6);
		height: 100%;
		position: relative;
	}
	.grid_item:not(:last-child) {
		margin-right: unset;
	}
	@media (max-width: 1151px) {
		.grid_item {
			width: calc(25% - 1.5rem);
		}
	}
	@media (max-width: 769px) {
		.grid_item {
			width: calc((100% - 4rem) / 3);
		}
	}
	@media (max-width: 500px) {
		.grid_item {
			width: calc(50% - 1rem);
		}
	}
</style>
