<template>
	<div id="inner-loading" v-show="isLoading"></div>
</template>

<script>
	export default {
		props: {
			isLoading: Boolean,
		},
	};
</script>
<style scoped>
	@keyframes spining {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	#inner-loading {
		width: 100%;
		height: 10rem;
		padding: 20px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#inner-loading::after {
		content: '';
		display: block;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 0.7rem solid var(--color-primary-effect);
		border-top-color: var(--color-primary-contrast);
		animation: spining 0.7s ease-in-out infinite;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s ease-in-out;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>
