export const dateFormatDate = {
	methods: {
		//YYYY-MM-DD 로만 뽑아냄
		dateFormatDate(date, format = '.') {
			if (!date || typeof date != 'string') return '-';

			const sliced = date.slice(0, 10); // 앞에 10자리

			return format === '-' ? sliced : sliced.replaceAll('-', format);
		},
	},
};
