import { ref, watch, onMounted } from 'vue';
import useLocalStorage from '@/compositions/useLocalStorage';

const MAX_RECENT_PRODUCTS_SIZE = 15;

export default function useRecentItems() {
	const recentItems = ref([]);
	const isRecentItemsLoaded = ref(false);

	const { setLocalStorageItem, getLocalStorageItem, checkLocalStorage } = useLocalStorage();

	function saveRecentProduct(product_pk, productDetail) {
		const recentProducts = getLocalStorageItem('recentProducts');
		if (checkLocalStorage('recentProducts', 'product_pk', product_pk)) {
			if (recentProducts && Array.isArray(recentProducts)) {
				if (productDetail) {
					// MAX_RECENT_PRODUCTS_SIZE 이상 저장되어 있으면 가장 먼저 저장 된 상품 삭제 후 추가
					if (recentProducts.length >= MAX_RECENT_PRODUCTS_SIZE) {
						recentProducts.splice(0, 1);
					}
					recentProducts.push({
						product_pk: product_pk,
						product_name: productDetail.product_name,
						brand_name: productDetail.brand_name,
						product_saleprice: productDetail.product_saleprice,
						discount_price: productDetail.discount_price,
						discount_unit: productDetail.discount_unit,
						save_name: productDetail.product_req_image.save_name,
						path: productDetail.product_req_image.path,
						reward: productDetail.reward,
						save_date: new Date(),
					});
					setLocalStorageItem('recentProducts', recentProducts);
				}
				recentItems.value = recentProducts;
			} else {
				const newRecent = [];
				newRecent.push({
					product_pk: product_pk,
					product_name: productDetail.product_name,
					brand_name: productDetail.brand_name,
					product_saleprice: productDetail.product_saleprice,
					discount_price: productDetail.discount_price,
					discount_unit: productDetail.discount_unit,

					save_name: productDetail.product_req_image.save_name,
					path: productDetail.product_req_image.path,
					reward: productDetail.reward,
					save_date: new Date(),
				});
				setLocalStorageItem('recentProducts', newRecent);
				recentItems.value = newRecent;
			}
		}
	}

	function updateRecentProduct() {
		const today = new Date();
		const current = getLocalStorageItem('recentProducts');

		function getSubDate(a, b) {
			const btMs = a.getTime() - b.getTime();
			return btMs / (1000 * 60 * 60 * 24);
		}
		if (current) {
			// 저장된 이후 3일 이상 경과 된 상품은 삭제.
			const updated = current.filter((item) => getSubDate(today, new Date(item.save_date)) <= 3);
			setLocalStorageItem('recentProducts', updated);
			return updated;
		}
		return [];
	}
	onMounted(() => {
		recentItems.value = updateRecentProduct();
	});

	watch(recentItems, (value) => {
		if (Array.isArray(value)) {
			if (value.length > 0) {
				isRecentItemsLoaded.value = true;
			}
		}
	});
	return {
		recentItems,
		isRecentItemsLoaded,
		saveRecentProduct,
	};
}
