<template>
	<!-- 컨텐츠 START -->
	<div v-if="!isLoading" class="sub_content_container common_style03 products_detail">
		<div class="category_nav">
			<div style="display: flex" v-for="(category, idx) in productDetail.category" :key="category">
				<button type="button" @click="selectCategory(category, idx)">{{ category }}</button>
				<p class="ro_rectan" v-if="idx !== productDetail.category.length - 1"></p>
			</div>
		</div>
		<section class="flexbox">
			<div class="img_wrap">
				<div v-if="productDetail.product_req_image" class="main_img">
					<img v-if="productDetail.product_image.length === 0" src="@/assets/images/common/no_image.svg" />
					<swiper v-else v-bind="productSwiperOptopns">
						<swiper-slide v-for="pImg in productDetail.product_image" :key="pImg.product_image_pk">
							<img v-noimage :src="pImg.path + pImg.save_name" :alt="productDetail.product_name" />
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<div class="pd_info_container">
				<div class="brand_item">
					<div class="text_wrapper">
						<div style="display: flex; justify-content: space-between">
							<p class="brand_name">
								<strong>{{ productDetail.brand_name }}</strong>
							</p>
							<p>{{ $route.params.product_pk }}</p>
						</div>
						<p class="item_name">
							{{ productDetail.product_name }}
						</p>
						<details style="max-width: 100%" v-if="productDetail.product_instruction">
							<summary>상품 요약</summary>
							<pre style="white-space: pre-wrap">{{ productDetail.product_instruction }}</pre>
						</details>
					</div>

					<div class="pd_info_btn icon_container">
						<div
							class="icon_wrapper"
							@click="
								async () => {
									isWish
										? removeWishItem($route.params.product_pk, productDetail.wish_pk)
										: (productDetail.wish_pk = await addWishItem($route.params.product_pk));
									isWish = !isWish;
								}
							"
						>
							<WishIcon :isLiked="isWish" />
						</div>
						<div class="icon_wrapper" @click="sharePopup = true">
							<img src="@/assets/images/common/share_icon.svg" alt="이페이지를 공유하기" />
						</div>
						<SharePopup
							ref="sharePopupRef"
							v-show="sharePopup"
							:imageSrc="productDetail.product_req_image.path + productDetail.product_req_image.save_name + ''"
							:price="productDiscountPrice"
							:description="productDetail.product_name"
							@close-share-popup="sharePopup = false"
						/>
					</div>
				</div>
				<div class="detail_info">
					<div class="price_wrap">
						<div class="og_price price" v-if="productDetail.discount_yn === 'true'">
							<div class="og_price" v-if="productDetail.discount_yn === 'true'">
								<p>
									<span class="text"> 정상가</span>
									<strong>{{ numberFormat(productDetail.product_saleprice) }}<span>원</span></strong>
								</p>
							</div>

							<div class="dc_price" v-if="!$store.state.access_token.access_token"></div>
						</div>
						<div class="dc_price price">
							<div class="price_and_point" v-if="isPriceVisible">
								<span v-show="productDetail.discount_yn === 'true'" class="discount">
									{{ productDetail.discount_price ? numberFormat(discountToPercent) : '' }}%
									<span class="text">
										{{ productDetail.discount_yn === 'true' ? '할인가' : '정상가' }}
									</span>
								</span>
								<span class="final_price">
									<strong>{{ numberFormat(productDiscountPrice) }}<span class="won">원</span></strong>
								</span>
								<span class="point" v-if="$store.state.access_token.access_token">
									적립포인트 <strong> {{ numberFormat(productDetail.reward * productAmount) }}P</strong>
								</span>
							</div>
							<div v-else>
								<span>
									<strong>가격 회원공개</strong>
								</span>
							</div>
							<button
								v-if="$store.state.user.isLogin"
								type="button"
								class="btn_common btn_small btn_coupon btn_primary"
								@click="$store.commit('modal/openCouponModal')"
							>
								쿠폰받기
							</button>
						</div>
					</div>
					<div class="price_more">
						<p>
							배송비
							<span>{{
								productDetail.delivery_price_division === 'R'
									? numberFormat(deliveryDivision) + ' 원'
									: deliveryDivision
							}}</span>
						</p>
						<p>
							{{ productDetail.delivery_method }}
							<span>{{ productDetail.delivery_period }}</span>
						</p>
						<p class="caution">{{ productDetail.product_caution }}</p>
					</div>
					<div class="guide_btn_wrap">
						<button
							type="button"
							v-if="productDetail.product_info.length"
							class="btn_common btn_small btn_size_guide"
							@click="onClickDetail"
						>
							상품 상세정보
						</button>
						<button
							type="button"
							v-if="productDetail.guide_image"
							class="btn_common btn_small btn_size_guide"
							@click="$store.commit('modal/openSizeModal')"
						>
							사이즈 가이드
						</button>
					</div>
				</div>
				<!--<button
					v-show="optionOpen"
					class="m_option_wrap_btn"
					style="
						z-index: 200;
						height: 3rem;
						/* border-radius: 50%; */
						background: rgb(250, 250, 250, 95%);
						/*color: var(--color-font-basic);*/
						/*box-shadow: 1px 1px 3px 1px rgba(183, 185, 185, 0.9);*/
					"
					@click="onClickMobileOption"
				>
					옵션
				</button>-->

				<!---------------------------- 옵션 선택 START ---------------------------->
				<div class="option_wrap" :class="isOptionOpen ? 'on ' : ''">
					<!--<button class="m_option_wrap_btn" @click="onClickMobileOption">닫기</button>-->
					<div class="content">
						<div class="btn_fold" @click="isOptionOpen = !isOptionOpen">
							<img src="~@/assets/images/common/arrow.svg" alt="fold" />
						</div>
						<div v-if="isPriceVisible" class="price_visible">
							<p v-if="productDetail.option_yn === 'true'">옵션 선택</p>
							<div v-if="productDetail.option_yn === 'false'" class="m_hidden">
								<div class="amount_input">
									<button @click="onChangeCOptionAmount(-1)"><span>-</span></button>
									<NumberInput
										:value="cOptionsAmount"
										@bind-number="(value) => (cOptionsAmount = value)"
										@blur="(e) => onBlurAmount(e, 'C')"
										:maxlength="3"
										:default="'1'"
									/>
									<button @click="onChangeCOptionAmount(1)">+</button>
								</div>
								<p>{{ numberFormat(cProductPrice * cOptionsAmount) }} 원</p>
							</div>
							<div
								v-else-if="productDetail.option_division === 'C'"
								class="m_hidden"
								v-for="(optionGroupName, idx) in Array.from(cOptionGroup)"
								:key="optionGroupName"
							>
								<!-- 조합형(C) 옵션 선택 -->
								<div class="select_box">
									<select @change="onSelectCOption($event, idx)">
										<option value="">{{ optionGroupName }} 선택</option>
										<option
											v-for="optionItem in allCOptions[idx]"
											:key="optionItem.product_option_pk"
											:value="optionItem.product_option_pk"
											:disabled="optionItem.option_stock == 0 || productDetail.sale_state == 'false'"
										>
											<p>
												{{ optionItem.option_name }}
												<span>(남은 수량 : {{ optionItem.option_stock }})</span>
												<span style="padding: 1rem" v-if="optionItem?.option_price !== 0">{{
													`    (${optionItem.option_price > 0 ? '+' : ''}${optionItem.option_price}원)`
												}}</span>
											</p>
										</option>
									</select>
									<span class="select_box_icon">
										<img src="/img/icon_drop_arrow.b010ffd0.svg" alt="" />
									</span>
								</div>
							</div>
							<!-- 단독형 옵션 선택(E) -->
							<div v-if="productDetail.option_division === 'E'" class="select_box">
								<select @change="onSelectEOption">
									<option value="">옵션</option>
									<option
										:value="option.product_option_pk"
										:key="option.product_option_pk"
										v-for="option in allOptions"
										:disabled="option.option_stock == 0 || productDetail.sale_state == 'false'"
									>
										{{
											productDetail.sale_state == 'false'
												? '주문 불가'
												: option.option_stock > 0
												? `${option.option_name} (${option.option_price > 0 ? '+' : ''} ${numberFormat(
														option.option_price,
												  )}원) / 재고: ${option.option_stock}개`
												: `${option.option_name} / 품절`
										}}
									</option>
								</select>
								<span class="select_box_icon">
									<img src="/img/icon_drop_arrow.b010ffd0.svg" alt="" />
								</span>
							</div>
							<!-- 입력형 옵션 선택(G) -->
							<div v-if="productDetail.option_division === 'G'" class="select_box">
								<Input placeholder="옵션을 입력해주세요" v-model="optionInput" @blur="onInputBlurGOption" />
							</div>
							<!-- 조합형 옵션 선택 목록(C) -->
							<div
								v-if="
									productDetail.option_division === 'C' &&
									(selectedCOptions.length === productDetail.option_count ||
										Array.from(cOptionGroup).length === selectedCOptions.length)
								"
								class="option_list"
							>
								<p>
									{{ selectedCOptions.map((v) => v.option_name).join(' / ') }}
								</p>
								<div class="option_detail_wrap">
									<div style="margin-top: 0">
										<div class="amount_input">
											<button @click="onChangeCOptionAmount(-1)">-</button>
											<NumberInput
												:value="cOptionsAmount"
												@bind-number="(value) => (cOptionsAmount = value)"
												@blur="(e) => onBlurAmount(e, 'C')"
												:maxlength="3"
												:default="'1'"
											/>
											<button @click="onChangeCOptionAmount(1)">+</button>
										</div>
										<p>{{ numberFormat(cProductPrice * cOptionsAmount) }}원</p>
									</div>
								</div>
							</div>
							<!-- 단독형 옵션 선택 목록(E) -->
							<div v-if="productDetail.option_division === 'E'" class="option_list">
								<div v-for="option in selectedEOptions" :key="option.product_option_pk" class="option_detail_wrap">
									<p>{{ option.option_name }}</p>
									<button @click="removeSelectedProduct(option.product_option_pk)" class="btn_close">
										<img src="@/assets/images/common/icon_close.svg" />
									</button>
									<div>
										<div class="amount_input">
											<button @click="onChangeEOptionAmount(option.product_option_pk, -1)">-</button>
											<NumberInput
												:value="option.product_amount"
												@bind-number="(value) => (option.product_amount = value)"
												@blur="(e) => onBlurAmount(e, 'E', option.product_option_pk)"
												:maxlength="3"
												:default="'1'"
											/>
											<button @click="onChangeEOptionAmount(option.product_option_pk, 1)">+</button>
										</div>
										<p>
											{{
												numberFormat(
													(productDetail.product_saleprice + option.option_price) * Number(option.product_amount),
												)
											}}원
										</p>
									</div>
								</div>
							</div>
							<!-- 추가 옵션 선택 -->
							<div v-if="productDetail.addition_option_yn === 'true'">
								<p style="margin-bottom: 1rem">추가 옵션 선택</p>
								<select @change="onSelectAdditionOption" style="margin-bottom: 0.5rem">
									<option value="">추가 옵션</option>
									<option
										:value="option.product_addition_option_pk"
										:key="option.product_addition_option_pk"
										v-for="option in additionOptions"
									>
										{{ `${option.addition_option_name} (${numberFormat(option.addition_option_price)}원)` }}
									</option>
								</select>
							</div>
							<div class="option_list">
								<div
									v-for="option in selectedAdditionOption"
									:key="option.product_addition_option_pk"
									class="option_detail_wrap"
								>
									<p>{{ option.addition_option_name }}</p>
									<button @click="removeSelectedAdditionProduct(option.product_addition_option_pk)" class="btn_close">
										<img src="@/assets/images/common/icon_close.svg" />
									</button>
									<div style="display: flex; justify-content: flex-end">
										<p>
											{{
												numberFormat(
													//(productDetail.product_saleprice + option.option_price) * Number(option.product_amount),
													option.addition_option_price,
												)
											}}원
										</p>
									</div>
								</div>
							</div>
							<!---------------------- 가격 표시 ---------------------->
							<div class="price_list">
								<ul>
									<li>
										<p>총 할인 금액</p>
										<span>{{ totalExpectedDiscount > 0 ? '-' : '' }} {{ numberFormat(totalExpectedDiscount) }}원</span>
									</li>
									<!--<li>
										<p>배송비</p>
										<span>{{ deliveryPrice > 0 ? '+' : '' }} {{ numberFormat(deliveryPrice) }}원</span>
									</li>-->
									<li>
										<!-- totalExpectedPrice = 옵션 합산 금액 + 추가옵션 합산 금액 -->
										<!-- 총 주문 = (모든 옵션 금액 합산) - (모든 할인 금액 합산) + (배송비) -->
										<p>총 상품 금액</p>
										<span
											style="color: var(--color-primary); font-weight: var(--font-w-bold)"
											v-if="productDetail.option_division === 'E'"
											>{{ numberFormat(Math.max(0, totalExpectedPrice - totalExpectedDiscount)) }}원</span
										>
										<span style="color: var(--color-primary); font-weight: var(--font-w-bold)" v-else
											>{{
												numberFormat(
													cProductPrice * cOptionsAmount +
														totalExpectedPrice -
														totalExpectedDiscount +
														Number(deliveryPrice),
												)
											}}원</span
										>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div class="btn_wrap">
								<button
									v-if="isPriceVisible"
									type="button"
									class="like_icon dis"
									@click="
										async () => {
											isWish
												? removeWishItem($route.params.product_pk, productDetail.wish_pk)
												: (productDetail.wish_pk = await addWishItem($route.params.product_pk));
											isWish = !isWish;
										}
									"
								>
									<WishIcon :isLiked="isWish" />
								</button>
								<!--<button
									v-if="isPriceVisible"
									type="button"
									class="btn_common btn_big btn_black-fill btn_purchase"
									@click="checkOption(() => buyProduct())"
								>
									{{ productDetail.sale_state == 'false' ? '주문 불가' : '바로구매하기' }}
								</button>-->
								<button
									v-if="isPriceVisible"
									type="button"
									class="btn_common btn_big btn_black-fill btn_basket"
									style="width: 100%"
									@click="checkOption(() => addCart())"
								>
									장바구니 담기
								</button>
								<button
									v-if="!isPriceVisible"
									type="button"
									class="btn_common btn_big btn_black-fill btn_purchase"
									style="width: 100%"
									@click="$router.push({ name: 'Login', query: { return_url: $route.fullPath } })"
								>
									로그인 후 가격 확인하기
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--################################# 상품 상세 설명 시작 #################################-->
		<section class="pd_detail_img">
			<div class="player_wrapper" v-if="youtubeURL">
				<iframe :src="youtubeURL" frameborder="0"></iframe>
			</div>
			<div class="detail_original_wrapper">
				<span>상세설명 이미지를 자유롭게 확대/축소하시려면 <strong>원본보기</strong>에서 가능합니다.</span>
				<button @click="onClickShowOriginal(productDetail.detail_image)" class="btn_common btn_mid">원본보기</button>
			</div>

			<div class="detail_img_wrapper" v-if="productDetail.detail_image !== null">
				<img :src="productDetail.detail_image.path + productDetail.detail_image.save_name" alt="제품상세컷" />
				<!-- 추가된 코드 START-->
				<div class="detail_desc_wrapper" v-if="productDetail.product_detail">
					<h2>상품 상세 설명</h2>
					<div class="detail_desc" v-html="productDetail.product_detail"></div>
				</div>
				<div style="width: 100%; text-align: center" v-else>
					<p>상품 설명 없음</p>
				</div>
				<!-- 추가된 코드 END-->
			</div>
			<div style="width: 100%; text-align: center" v-else>
				<p>상품 사진 없음</p>
			</div>

			<div class="detail_more_wrapper" v-if="Object.keys(productDetail.detail_image).length > 0">
				<!-- detail_image 객체가 빈 객체인지 체크 -->
				<button type="button" @click="onClickDetailMoreBtn">
					<p v-if="!isShowDetail"><span style="color: var(--color-primary)">상품설명</span> 자세히 보기</p>
					<p v-else>
						<span style="color: var(--color-primary)">접기</span>
					</p>
				</button>
			</div>
		</section>

		<section class="description exchange_refund">
			<h4>교환 및 반품 안내</h4>
			<div class="contents_wrap">
				<div class="flex01">
					<table>
						<tr>
							<td>교환비용</td>
							<td>{{ numberFormat(productDetail.supply_store.exchange_delivery_price) }}원</td>
						</tr>
						<tr>
							<td>반품비용</td>
							<td>{{ numberFormat(productDetail.supply_store.return_delivery_price) }}원</td>
						</tr>
						<tr>
							<td>택배사</td>
							<td>{{ productDetail.parcel_company }}</td>
						</tr>
						<tr>
							<td>교환/반품 문의</td>
							<td>{{ productDetail.supply_store.return_contact }}</td>
						</tr>
					</table>
					<table class="table_address">
						<tr>
							<td>교환/반품 주소</td>
						</tr>
						<!-- prettier-ignore -->
						<td>
							{{
								`
									${productDetail.supply_store.return_zipcode ?? ''}
									${productDetail.supply_store.return_address ?? ''}
									${productDetail.supply_store.return_address_detail ?? ''}
								`
							}}
						</td>
						<tr>
							<td>교환/반품 유의사항</td>
						</tr>
						<td>
							{{ productDetail.refund_guide }}
						</td>
					</table>
				</div>
				<div class="flex02"></div>
				<!--<div class="flex02">
					<ul>
						<li v-for="guide in productDetail.supply_store_delivery_guide" :key="guide.supply_store_pk">
							{{ guide.content }}
						</li>
					</ul>
				</div>-->
			</div>
		</section>
		<section class="review con_top_btn">
			<h4>리뷰</h4>
			<ReviewList :writable="false" :reviews="reviews" />
		</section>
		<!-- 상품 문의 -->
		<section class="products_question">
			<div class="con_top_btn">
				<h4>상품문의</h4>
				<button type="button" class="btn_common btn_mid btn_whire btn_primary btn_write" @click="onClickWrtieInquiry">
					작성하기
				</button>
			</div>
			<ProductQA :data="questions" />
		</section>
		<!-- 최근 폰 상품 -->
		<section class="customer_recent_items" v-if="isRecentItemsLoaded">
			<h4>고객님께서 최근 본 상품</h4>
			<!-- <SwiperList v-if="isRecentItemsLoaded && recentItems.length > 0" :productList="recentItems" :pagination="true" /> -->

			<!-- <SwiperList :productList="recentItems" :pagination="true" /> -->
			<Swiper v-bind="swiperOptions" :resistanceRatio="0.5" style="margin-left: 2rem">
				<SwiperSlide v-for="product in recentItems" :key="product.product_pk">
					<ProductItem :product="product" />
				</SwiperSlide>
			</Swiper>
			<div v-if="recentItems.length === 0" class="contents_wrap">
				<div class="item_list_container row_scroll">
					<ul class="item_list">
						<li>상품 목록이 없습니다.</li>
					</ul>
				</div>
			</div>
		</section>

		<Modal v-if="$store.state.modal.couponModal || $store.state.modal.sizeModal">
			<CouponModalContent
				:productCoupon="
					productDetail.coupon.filter((item) => {
						return new Date(item.end_date) > new Date();
					})
				"
				menu="product"
				v-if="$store.state.modal.couponModal"
			/>
			<SizeGuideContent v-if="$store.state.modal.sizeModal" :sizeGuide="productDetail.guide_image" />
		</Modal>
		<transition name="fade">
			<div
				v-if="detailModal || inquiryModal"
				style="
					position: fixed;
					inset: 0px;
					z-index: 900;
					background-color: var(--color-bg-modal);
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				"
			>
				<div class="modal-content">
					<ProductDetailModal
						v-if="detailModal"
						:info="productDetail.product_info"
						@close-detail="onClickCloseDetail"
					/>
					<InquiryModal v-if="inquiryModal" @close-inquiry="onCloseInquiryModal" :productDetail="productDetail" />
				</div>
			</div>
		</transition>
	</div>
	<div v-else id="loading"></div>
	<button @click="onClickTop" class="top_btn" :class="topBtnShow">↑</button>
	<!--컨텐츠 END-->
</template>

<script>
	import {
		ref,
		reactive,
		toRefs,
		toRef,
		watch,
		computed,
		onBeforeUnmount,
		onMounted,
		onBeforeMount,
		onUnmounted,
	} from 'vue';
	import { useStore } from 'vuex';
	import { useRouter, useRoute } from 'vue-router';
	import { onClickOutside } from '@vueuse/core';

	import ProductItem from '@/components/molcules/Product/ProductItem.vue';
	import ReviewList from '@/components/molcules/Article/ReviewList.vue';
	import Modal from '@/components/molcules/Popup/TeleportModal.vue';
	import CouponModalContent from '@/components/organisms/popup/CouponModalContent.vue';
	import SizeGuideContent from '@/components/organisms/popup/SizeGuideContent';
	import SharePopup from '@/components/molcules/Popup/SharePopup.vue';
	import WishIcon from '@/components/atoms/icons/WishIcon.vue';
	import ProductDetailModal from '@/components/organisms/popup/ProductDetailModal.vue';
	import ProductQA from '@/components/molcules/Section/ProductQA.vue';
	import InquiryModal from '@/components/organisms/popup/InquiryModal';
	import NumberInput from '@/components/molcules/Input/NumberInput.vue';

	import useAxios from '@/compositions/useAxios';
	import useGetReview from '@/compositions/useGetReview';
	import useRecentItems from '@/compositions/useRecentItems';
	import useWishList from '@/compositions/useWishList';
	import { numberFormat } from '@/utils/numberFormat';

	import { Swiper, SwiperSlide } from 'swiper/vue';
	import SwiperCore, { Pagination, Navigation } from 'swiper';

	import 'swiper/swiper-bundle.min.css';
	import { modalCloseScrollRestore, modalOpenScrollFix } from '@/utils/modalScrollControl';
	import Input from '@/components/atoms/Input.vue';

	SwiperCore.use([Pagination, Navigation]);

	export default {
		components: {
			ProductItem,
			ReviewList,
			Modal,
			CouponModalContent,
			SizeGuideContent,
			SharePopup,
			WishIcon,
			ProductQA,
			ProductDetailModal,
			InquiryModal,
			NumberInput,
			Input,
			Swiper,
			SwiperSlide,
		},
		mixins: [numberFormat],
		setup() {
			const { reviews } = useGetReview();

			const productSwiperOptopns = ref({
				pagination: {
					clickable: true,
				},
			});
			const swiperOptions = ref({
				effect: 'fade',
				slidesPerView: 2,
				slidesPerGroup: 2,
				spaceBetween: 15,

				// 반응형 breakpoints
				breakpoints: {
					375: {
						slidesPerView: 2.3,
						slidesPerGroup: 2,
					},
					425: {
						slidesPerView: 3.3,
						slidesPerGroup: 3,
					},
					768: {
						slidesPerView: 5.3,
						slidesPerGroup: 5,
					},
					1024: {
						slidesPerView: 6.3,
						slidesPerGroup: 6,
					},
				},
			});
			return {
				reviews,
				swiperOptions,
				productSwiperOptopns,
				...useWishList(),
				...useProduct(),
				...useSharePage(),
				...useQA(),
				...useDetailOriginal(),
				...useScrollToTop(),
			};
		},
	};
	const useProduct = () => {
		const { fetchData } = useAxios();
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		onBeforeUnmount(() => {
			store.commit('modal/closeCouponModal');
			store.commit('modal/closeSizeModal');
		});
		const state = reactive({
			productDetail: {},
			isLoading: true,
		});

		const discountToPercent = computed(() => {
			if (!state.productDetail?.discount_unit) return null;
			if (state.productDetail.discount_unit === '%') return state.productDetail.discount_price;
			const dc = Math.ceil((state.productDetail.discount_price / state.productDetail.product_saleprice) * 100);
			return dc;
		});

		const isPriceVisible = computed(() => {
			return (
				state.productDetail.product_saleprice_exposure_division === '전체공개' || store.state.access_token.access_token
			);
		});
		const product = ref({});
		const {
			allOptions,
			additionOptions,
			onSelectAdditionOption,
			selectedAdditionOption,
			selectedEOptions,
			onSelectEOption,
			onChangeEOptionAmount,
			cOptionGroup,
			allCOptions,
			onSelectCOption,
			selectedCOptions,
			removeSelectedProduct,
			removeSelectedAdditionProduct,
			totalExpectedPrice,
			checkEOption,
			checkCOption,
			cOptionsAmount,
			onChangeCOptionAmount,
			cProductPrice,
			buyProduct,
			addCart,
			onBlurAmount,
			onInputBlurGOption,
			totalExpectedDiscount,
			optionInput,
			deliveryPrice,
			productAmount,
		} = useOrderOption(product);
		const { isOptionOpen } = useMobile();
		const isWish = ref(false);

		function selectCategory(selected, idx) {
			let params = {};
			let checked = true;
			if (idx === 0) {
				params.category = selected;
				const found = store.state.category.category.level1.find((c) => c.content === selected);
				if (!found) {
					checked = false;
				}
			} else if (idx === 1) {
				params.subcategory = selected;
				params.category = state.productDetail.category[idx - 1];
				const level1 = store.state.category.category.allDepth.find((c) => c.content === params.category);
				if (!level1) {
					checked = false;
				} else {
					const level2 = level1.children.find((c) => c.content === selected);
					if (!level2) {
						checked = false;
					}
				}
			} else {
				params.lastcategory = selected;
				params.subcategory = state.productDetail.category[idx - 1];
				params.category = state.productDetail.category[idx - 2];

				const level1 = store.state.category.category.allDepth.find((c) => c.content === params.category);
				if (!level1) {
					checked = false;
				} else {
					const level2 = level1.children.find((c) => c.content === params.subcategory);
					if (!level2) {
						checked = false;
					} else {
						const level3 = level2.children.find((c) => c.content === selected);
						if (!level3) {
							checked = false;
						}
					}
				}
			}
			if (checked) {
				router.push({
					name: 'Category',
					params: params,
				});
			} else {
				router.push('/404');
			}
		}

		const deliveryDivision = computed(() => {
			if (state.productDetail.delivery_price_division === 'T') {
				return '무료';
			} else if (state.productDetail.delivery_price_division === 'R') {
				return state.productDetail.delivery_price;
			} else if (state.productDetail.delivery_price_division === 'M') {
				return '구매 금액에 따른 부과';
			} else if (state.productDetail.delivery_price_division === 'D') {
				return '구매 금액별 차등 배송료 부과';
			} else if (state.productDetail.delivery_price_division === 'W') {
				return '상품 무게별 차등 배송료 부과';
			} else if (state.productDetail.delivery_price_division === 'C') {
				return '상품 수량별 차등 배송료 부과';
			} else if (state.productDetail.delivery_price_division === 'N') {
				return '상품 수량에 비례하여 배송료 부과';
			}
			return '';
		});
		const youtubeURL = computed(() => {
			if (state.productDetail.link) {
				return `https://www.youtube.com/embed/${state.productDetail.link}`;
			} else return null;
		});
		const productDiscountPrice = computed(() => {
			if (state.productDetail.discount_yn === 'false') {
				return state.productDetail.product_saleprice;
			}

			let discounted = state.productDetail.product_saleprice;

			if (state.productDetail.discount_unit === '%') {
				discounted = Math.floor(
					(state.productDetail.product_saleprice * (100 - state.productDetail.discount_price)) / 100,
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				);
			} else if (state.productDetail.discount_unit === '원') {
				discounted -= state.productDetail.discount_price;
			}

			return discounted;
		});

		const { saveRecentProduct, isRecentItemsLoaded, recentItems } = useRecentItems();

		async function getProductData() {
			state.isLoading = true;
			fetchData(
				'/getProductDetail',
				(data) => {
					state.productDetail = data;
					product.value = data;
					isWish.value = !!data.wish_pk;
					state.isLoading = false;
					window.scrollTo({ top: 0, behavior: 'smooth' });
					saveRecentProduct(route.params.product_pk, state.productDetail);
					document.title = `소나기몰 | ${state.productDetail.product_name}`;
				},
				{ pno: route.params.product_pk },
			);
		}
		getProductData();

		watch(route, (route) => {
			if (route.params.product_pk) {
				getProductData();
			}
		});

		function checkOption(callback) {
			if (!isOptionOpen.value && window.innerWidth <= 931) {
				//모바일 환경에서 구매하기 버튼 눌렀을 때 하단 옵션창이 최소 한번은 펼쳐지도록 하기 위함
				isOptionOpen.value = true;
				return;
			}
			if (state.productDetail.sale_state === 'false') {
				store.commit('alert', '현재 주문이 불가능한 상품입니다.');
				return;
			}
			let optionChecker = '주문가능'; // option_yn === 'false'
			let msg = '옵션을 선택해주세요.';
			if (state.productDetail.option_division === 'G') {
				if (optionInput.value.length === 0) {
					optionChecker = '선택안함';
					msg = '옵션을 입력해주세요.';
				}
			} else if (state.productDetail.option_division === 'E') {
				optionChecker = checkEOption();
			} else {
				optionChecker = checkCOption();
			}
			switch (optionChecker) {
				case '선택안함':
					store.commit('alert', msg);
					break;
				case '품절':
					store.commit('alert', '상품 재고가 없습니다.');
					break;
				case '주문가능':
					callback();
					break;
			}
			return;
		}

		return {
			...toRefs(state),
			selectCategory,
			allOptions,
			additionOptions,
			onSelectAdditionOption,
			selectedAdditionOption,
			selectedEOptions,
			onSelectEOption,
			cOptionGroup,
			allCOptions,
			onSelectCOption,
			selectedCOptions,
			cOptionsAmount,
			onChangeCOptionAmount,
			cProductPrice,
			onChangeEOptionAmount,
			removeSelectedProduct,
			removeSelectedAdditionProduct,
			totalExpectedPrice,
			productDiscountPrice,
			checkOption,
			buyProduct,
			addCart,
			onBlurAmount,
			onInputBlurGOption,
			totalExpectedDiscount,
			optionInput,
			deliveryPrice,
			deliveryDivision,
			isWish,
			isRecentItemsLoaded,
			recentItems,
			discountToPercent,
			...useMobile(),
			youtubeURL,
			isPriceVisible,
			productAmount,
			isOptionOpen,
		};
	};

	const useSharePage = () => {
		const sharePopupRef = ref(null);
		const sharePopup = ref(false);

		onClickOutside(sharePopupRef, (event) => {
			sharePopup.value = false;
		});
		return { sharePopup, sharePopupRef };
	};

	const useQA = () => {
		const route = useRoute();
		const router = useRouter();
		const store = useStore();
		const { fetchData } = useAxios();

		const isLogin = computed(() => store.getters['user/isLogin']);
		const inquiryModal = ref(false);

		const questions = ref([]);

		onBeforeMount(() => {
			getQA();
		});

		const getQA = () => {
			fetchData(
				'/getInquiry',
				(data) => {
					questions.value = data.inquiry;
				},
				{
					pno: route.params.product_pk,
				},
			);
		};

		const onClickWrtieInquiry = () => {
			if (!isLogin.value) {
				router.push({
					name: 'Login',
					params: {
						redirect: route.fullPath,
					},
				});
			} else {
				modalOpenScrollFix();
				inquiryModal.value = true;
			}
		};
		const onCloseInquiryModal = () => {
			modalCloseScrollRestore();
			inquiryModal.value = false;
		};
		return {
			questions,
			inquiryModal,
			onClickWrtieInquiry,
			onCloseInquiryModal,
		};
	};
	export const useOrderOption = (product) => {
		const route = useRoute();
		let productAmount = ref(1);
		const productPk = computed(() => route.params.product_pk);

		const optionDivision = computed(() => product.value.option_division); // E: 단독, C: 조합, G: 입력형
		// E(단독형) => 다른 옵션 선택하는대로 새로 추가되어야 함.

		// C(조합형) => 옵션을 모두 선택해야 한 개의 상품 선택 하는 것.
		//           => option_count 만큼의 옵션 개수가 있음.
		const allOptions = computed(() => product.value.product_option);
		const additionOptions = computed(() => product.value.product_addition_option);

		// 입력형 옵션
		const optionInput = ref('');

		const cOptionGroup = computed(() => {
			const optionSet = new Set();
			if (optionDivision.value === 'E' || !product.value.product_option) return optionSet;
			product.value.product_option.forEach((o) => optionSet.add(o.option_group));
			return optionSet;
		});

		const allCOptions = computed(() => {
			if (!cOptionGroup.value) return [];
			let options = [];
			const optionsGroup = Array.from(cOptionGroup.value);
			for (let i = 1, j = product.value.option_count; i <= j; i++) {
				const groupName = optionsGroup[i - 1];
				options[i - 1] = allOptions.value.filter((o) => o.option_group === groupName);
			}
			return options;
		});

		const totalAdditionOptionPrice = computed(() =>
			selectedAdditionOption.value.reduce((acc, cur) => acc + cur.addition_option_price, 0),
		);
		const selectedEOptions = ref([]); // 단독형
		const totalExpectedPrice = computed(() => {
			//if (optionDivision.value === 'E') {
			//if (selectedEOptions.value.length === 0) return 0;
			return (
				totalAdditionOptionPrice.value +
				selectedEOptions.value.reduce((acc, cur) => {
					acc += (product.value.product_saleprice + cur.option_price) * Number(cur.product_amount);
					return acc;
				}, 0)
			);
		});
		const totalExpectedDiscount = computed(() => {
			if (product.value.discount_yn === 'false') {
				return 0;
			}

			//console.log(productAmount.value);
			if (product.value.discount_unit === '원') {
				return product.value.discount_price * productAmount.value;
			} else {
				return (Math.ceil(product.value.product_saleprice * product.value.discount_price) / 100) * productAmount.value;
			}
		});

		const totalOrderPrice = computed(() => totalExpectedPrice.value - totalExpectedDiscount.value);
		const deliveryPrice = computed(() => {
			//if (totalExpectedPrice.value === 0) return 0;
			let expectedDeliveryPrice = 0;
			switch (product.value.delivery_price_division) {
				case 'T':
					return 0;
				case 'R':
					return product.value.delivery_price;
				case 'M': {
					// 구간 별 차등
					const totalPrice = cProductPrice.value * productAmount.value;
					product.value.delivery_price_arr.sort((a, b) => {
						return a.standard - b.standard;
					});
					for (let i = 0, rangeLen = product.value.delivery_price_arr.length; i < rangeLen - 1; i++) {
						if (
							totalPrice >= Number(product.value.delivery_price_arr[i].standard || 0) &&
							totalPrice < Number(product.value.delivery_price_arr[i + 1].standard)
						) {
							expectedDeliveryPrice = product.value.delivery_price_arr[i].price;
							break;
						}
					}
					return Number(expectedDeliveryPrice);
				}
				default:
					return 0;
			}
		});

		const onSelectEOption = ({ target }) => {
			const { value } = target;
			if (!value) return;
			const option_pk = Number(value);
			const isExist = selectedEOptions.value.find((o) => o.product_option_pk === option_pk);
			if (isExist) {
				target.value = '';
				return;
			}
			const selectedOption = allOptions.value.find((option) => option.product_option_pk === option_pk);
			if (selectedOption.option_stock == 0) {
				store.commit('alert', '품절된 상품입니다.');
				target.value = '';
				return;
			}
			selectedEOptions.value.push({ ...selectedOption, product_amount: '1' });
			target.value = '';
		};

		const selectedAdditionOption = ref([]);

		const onSelectAdditionOption = ({ target }) => {
			if (product.value.option_division === 'E' && selectedEOptions.value.length === 0) {
				store.commit('alert', '옵션을 먼저 선택해주세요');
				target.value = '';
				return;
			} else if (product.value.option_division === 'C' && selectedCOptions.value.length === 0) {
				store.commit('alert', '옵션을 먼저 선택해주세요');
				target.value = '';
				return;
			}

			const { value } = target;
			const option_pk = Number(value);
			additionOptions.value.forEach((item) => {
				if (option_pk === item.product_addition_option_pk) {
					selectedAdditionOption.value.push(item);
				}
			});
			target.value = '';
			//console.log(selectedAdditionOption.value);
		};

		const onChangeEOptionAmount = (option_pk, amount) => {
			const option = selectedEOptions.value.find((o) => o.product_option_pk === option_pk);
			const optionStock = option.option_stock;
			if (
				Number(option.product_amount) + Number(amount) > 0 &&
				Number(option.product_amount) + Number(amount) <= optionStock
			)
				option.product_amount = Number(option.product_amount) + Number(amount) + '';
		};

		watch(selectedEOptions.value, () => {
			productAmount.value = selectedEOptions.value.reduce((total, item) => {
				return total + Number(item.product_amount);
			}, 0);
		});

		const removeESelectedProduct = (option_pk) => {
			selectedEOptions.value = selectedEOptions.value.filter((o) => o.product_option_pk !== option_pk);
		};

		const selectedCOptions = ref([]);
		const onSelectCOption = ({ target: { value } }, idx) => {
			const option_pk = Number(value);
			const selectedOption = allCOptions.value[idx].find((o) => o.product_option_pk === option_pk);
			if (selectedOption.option_stock == 0) {
				store.commit('alert', '품절된 상품입니다.');
				value = '';
				return;
			}
			selectedCOptions.value[idx] = selectedOption;
			cOptionsAmount.value = '1';
		};
		const cProductPrice = computed(() => {
			const extraCost = selectedCOptions.value.reduce((acc, cur) => {
				acc += cur.option_price;
				return acc;
			}, 0);
			return product.value.product_saleprice + extraCost;
		});
		const cOptionsAmount = ref('1');
		const onChangeCOptionAmount = (value) => {
			const optionLimit = selectedCOptions.value.map((v) => v.option_stock);
			const nextAmount = Number(cOptionsAmount.value) + Number(value);
			const isOver = optionLimit.find((v) => v < nextAmount);
			if (isOver) return;
			if (Number(cOptionsAmount.value) + Number(value) > 0)
				cOptionsAmount.value = String(Number(cOptionsAmount.value) + Number(value));
		};
		watch(cOptionsAmount, () => {
			productAmount.value = cOptionsAmount.value;
		});

		const onBlurAmount = (e, division, option_pk) => {
			if (division === 'C') {
				if (cOptionsAmount.value == '0') {
					cOptionsAmount.value = '1';
				}
			} else {
				const option = selectedEOptions.value.find((o) => o.product_option_pk === Number(option_pk));
				if (option.option_stock < Number(option.product_amount)) option.product_amount = option.option_stock + '';
				if (option.product_amount <= Number('0')) option.product_amount = '1';
			}
		};

		const removeSelectedProduct = (option_pk) => {
			if (optionDivision.value === 'E') removeESelectedProduct(option_pk);
		};
		const removeSelectedAdditionProduct = (option_pk) => {
			//selectedOptions.value = selectedEOptions.value.filter((o) => o.product_option_pk !== option_pk);
			selectedAdditionOption.value = selectedAdditionOption.value.filter(
				(o) => o.product_addition_option_pk !== option_pk,
			);
		};

		const checkEOption = () => {
			if (product.value.option_yn === 'false') return '주문가능';
			if (selectedEOptions.value.length === 0) return '선택안함'; // 선택 x => 주문 불가
			if (selectedEOptions.value.filter((o) => o.option_stock == 0).length > 0) return '품절'; // 품절 상품 존재
			// 한개 이상 선택 + 품절 상품 없음 => 주문 가능
			return '주문가능';
		};
		const checkCOption = () => {
			if (product.value.option_yn === 'false') return '주문가능';
			if (selectedCOptions.value.length !== Array.from(cOptionGroup.value).length) return '선택안함';
			if (selectedCOptions.value.filter((o) => o.option_stock == 0).length > 0) return '품절';

			return '주문가능';
		};

		const buyOptions = () => {
			const buyParams = [
				{
					...product.value,
					isDirectBuy: true,
					product_pk: route.params.product_pk,
					path: product.value.product_req_image.path,
					save_name: product.value.product_req_image.save_name,
					original_name: product.value.product_req_image.original_name,
					brand: product.value.brand_name,
					product_name: product.value.product_name,
					delivery_price: deliveryPrice.value,
					order_price: product.value.product_saleprice,
					product_price: product.value.product_saleprice,
					pay_price: null,
					discount_unit: product.value.discount_unit,
					discount_price: product.value.discount_price,
					product_taxrate: product.value.product_taxrate,
					order_addition_option: [],
					//////////////////////////공통속성////////////////////////

					order_amount: cOptionsAmount.value, // E 유형
					order_option: [], //C,E 유형
					order_option_input: null, // G유형
				},
			];

			selectedAdditionOption.value.forEach((aOption) => {
				buyParams[0].order_addition_option.push(aOption);
			});
			//console.log('buyParams', buyParams);
			return buyParams;
		};

		const store = useStore();
		const buyProduct = () => {
			if (store.state.access_token.access_token) {
				let params = buyOptions();
				// prettier-ignore
				if (optionDivision.value === 'C') {
					selectedCOptions.value.forEach((option) => {
						params[0].order_option.push(option);
					});
				}
				else if (optionDivision.value === 'G') {
					params[0].order_option_input = optionInput.value;
				}
				else if (optionDivision.value === 'E') {
					selectedEOptions.value.forEach((option) => {
						params[0].order_option.push(option);
					});
					params[0].order_amount = selectedEOptions.value.reduce((acc, cur) => {
						return acc + Number(cur.product_amount);
					}, 0);
				}
				//console.log('param',params);
				store.commit('cart/setBuyProduct', { params, division: optionDivision.value });
			} else {
				store.commit('alert', '로그인 후 이용해주세요.');
				router.push({ name: 'Login', query: { return_url: window.location.pathname } });
			}
		};

		const router = useRouter();
		const getOptionCartParam = () => {
			const tmpParam = {
				product_pk: route.params.product_pk,
				options: [],
				addition_options: selectedAdditionOption.value.map((item) => {
					return item.product_addition_option_pk;
				}),
				//////////////////////////공통속성////////////////////////

				order_amount: 1, // C,E 유형
				order_option_input: null, // G 유형
			};
			return tmpParam;
		};

		const addCart = () => {
			if (store.state.access_token.access_token) {
				let params = getOptionCartParam();

				//prettier-ignore
				if (optionDivision.value === 'C') {
					params.options = selectedCOptions.value.map((item) => {
						return item.product_option_pk;
					});
					params.order_amount = cOptionsAmount.value;
				}
				else if (optionDivision.value === 'G') {
					params.order_option_input = optionInput.value;
				}
				else if (optionDivision.value === 'E') {
					params.order_amount = selectedEOptions.value.reduce((acc, cur) => {
						return acc + Number(cur.product_amount);
					}, 0);
					selectedEOptions.value.forEach((item) => {
						for (let i = 0; i < Number(item.product_amount); i++) {
							params.options.push(item.product_option_pk);
						}
					});
				} else{
					params.order_amount = productAmount.value;
				}
				//console.log(product.value);
				//console.log('cart params', params);
				store.dispatch('cart/ADD_CART', { params, division: optionDivision.value });
			} else {
				store.commit('alert', '로그인 후 이용해주세요.');
				router.push({ name: 'Login', query: { return_url: window.location.pathname } });
			}
		};

		watch(productPk, (value) => {
			selectedCOptions.value = [];
			selectedEOptions.value = [];
		});
		return {
			allOptions,
			additionOptions,
			onSelectAdditionOption,
			selectedAdditionOption,
			checkEOption,
			selectedEOptions,
			onSelectEOption,
			selectedCOptions,
			onChangeEOptionAmount,
			cOptionGroup,
			allCOptions,
			onSelectCOption,
			removeSelectedProduct,
			removeSelectedAdditionProduct,
			totalExpectedPrice,
			cOptionsAmount,
			onChangeCOptionAmount,
			cProductPrice,
			checkCOption,
			buyProduct,
			addCart,
			onBlurAmount,
			totalExpectedDiscount,
			optionDivision,
			optionInput,
			deliveryPrice,
			productAmount,
		};
	};
	const useMobile = () => {
		const isShowDetail = ref(false);
		function onClickDetailMoreBtn() {
			const detailSection = document.querySelectorAll('.products_detail .pd_detail_img .detail_img_wrapper');
			if (isShowDetail.value) {
				//접기
				isShowDetail.value = false;
				detailSection.forEach((item) => item.style.setProperty('height', '60rem'));
				document.querySelector('.products_detail .pd_detail_img .detail_img_wrapper').scrollIntoView();
			} else {
				// 펼치기
				isShowDetail.value = true;
				detailSection.forEach((item) => item.style.setProperty('height', 'auto'));
			}
		}

		//const optionOpen = ref(true);
		const isOptionOpen = ref(false);

		//function onClickMobileOption() {
		//	optionOpen.value = !optionOpen.value;
		//}
		//watch(optionOpen, (value) => {
		//	const el = document.querySelector('.pd_info_container .m_option_wrap_btn');
		//	const optionWrap = document.querySelector('.option_wrap');
		//	if (!value) {
		//		el.style.setProperty('display', 'none');
		//		optionWrap.style.setProperty('display', 'flex');
		//	} else {
		//		el.style.setProperty('display', 'block');
		//		optionWrap.style.setProperty('display', 'none');
		//	}
		//});

		const delay = 100;
		let timer = null;
		//function showOptionWrap(e) {
		//	clearTimeout(timer);
		//	timer = setTimeout(function () {
		//		if (window.innerWidth > 929 && optionOpen.value) {
		//			optionOpen.value = false;
		//		}
		//	}, delay);
		//}

		//window.addEventListener('resize', showOptionWrap);

		const detailModal = ref(false);
		function onClickDetail() {
			modalOpenScrollFix();
			detailModal.value = true;
		}
		function onClickCloseDetail() {
			modalCloseScrollRestore();
			detailModal.value = false;
		}

		return {
			onClickDetailMoreBtn,
			isShowDetail,
			//optionOpen,
			//onClickMobileOption,
			isOptionOpen,
			detailModal,
			onClickDetail,
			onClickCloseDetail,
		};
	};
	const useDetailOriginal = () => {
		const store = useStore();
		const onClickShowOriginal = (productImages) => {
			window.open(productImages.path + productImages.save_name);
		};
		return {
			onClickShowOriginal,
		};
	};
	const useScrollToTop = () => {
		const topBtnShow = ref('');
		const topBtnHandler = () => {
			if (window.scrollY < 100) {
				topBtnShow.value = 'top_hide';
			} else {
				topBtnShow.value = '';
			}
		};
		onMounted(() => {
			window.addEventListener('scroll', topBtnHandler);
		});
		onUnmounted(() => {
			window.removeEventListener('scroll', topBtnHandler);
		});
		const onClickTop = () => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		};
		return {
			onClickTop,
			topBtnShow,
		};
	};
</script>

<style scoped>
	.pd_info_container .m_option_wrap_btn {
		display: none;
	}
	.pd_info_container .option_wrap .m_option_wrap_btn {
		position: absolute;
		top: -3.1rem;
		right: 2rem;
		z-index: 800;
	}

	@media screen and (max-width: 930px) {
		.pd_info_container .m_option_wrap_btn {
			display: block;
			position: fixed;
			width: 5rem;
			bottom: 5rem;
			right: 2.5rem;
			background: var(--color-bg-dark);
			bottom: 0;
			height: 3rem;
			color: #fff;
			font-size: 1rem;
			bottom: 8.5rem;
		}
	}
</style>
