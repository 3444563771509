<template>
	<div class="wish_item_container" style="">
		<ProductItem :type="'wish'" :product="product" />
		<div class="btn_container" style="margin: 0">
			<!-- <select class="select_option">
				<option value="">옵션을 선택하세요</option>
				<option v-for="option in productOtions" :key="option" :value="option">{{option.content}}</option>
			</select> -->
			<!-- <div v-if="productOptions.length !== 0" class="content">
				<div class="m_hidden select_box" v-for="idx in Array.from(groupSet).length" :key="idx">
					<select v-model="selectedOption[idx - 1]">
						<option value="">{{ `옵션 ${idx}` }}</option>
						<option
							v-for="optionItem in productOptions.filter((v) => v.option_group === Array.from(groupSet)[idx - 1])"
							:key="optionItem.product_option_pk"
							:value="optionItem"
						>
							<p>
								{{ optionItem.option_name }}
								<span>(남은 수량 : {{ optionItem.option_stock }})</span>
								<span v-if="optionItem?.option_price !== 0">({{ optionItem.option_price }})</span>
							</p>
						</option>
					</select>
					<span class="select_box_icon">
						<img src="/img/icon_drop_arrow.b010ffd0.svg" alt="" />
					</span>
				</div>
			</div>
			<button type="button" class="btn_common btn_black-fill basket" @click="onClickCart">장바구니 담기</button> -->
			<button type="button" class="btn_common btn_del" @click="onClickWish">삭제</button>
		</div>
	</div>
</template>

<script>
	import { useOrderOption } from '@/views/Categories/Product/ProductDetail.vue';
	import ProductItem from './ProductItem.vue';
	import useWishList from '@/compositions/useWishList';
	import { useStore } from 'vuex';
	import { ref, reactive, toRef } from '@vue/reactivity';
	import { onBeforeMount } from '@vue/runtime-core';
	import useAxios from '@/compositions/useAxios';

	export default {
		components: {
			ProductItem,
		},
		props: {
			product: {
				type: Object,
				required: true,
			},
		},
		setup(props, { emit }) {
			// const {
			// 	optionDivision,
			// 	allOptions,
			// 	cOptionGroup,
			// 	allCOptions,
			// 	selectedCOptions,
			// 	selectedEOptions,
			// 	onSelectEOption,
			// 	checkEOption,
			// 	checkCOption,
			// 	getCOptionCartParam,
			// 	getEOptionCartParam,
			// 	getNoOptionCartParam,
			// 	addCart: addProductCart,
			// } = useOrderOption(toRef(props.product));
			const { removeWishItem } = useWishList();
			const store = useStore();
			const productOptions = ref([]);
			const selectedOption = ref(['', '']);
			const groupSet = ref(new Set());
			onBeforeMount(() => {
				fetchOption(props.product.product_pk);
			});

			const { fetchData, postData } = useAxios();
			function fetchOption(product_pk) {
				fetchData(
					'/getOption',
					(data) => {
						data.option.forEach((option) => groupSet.value.add(option.option_group));
						// groupSet.value.forEach((v) => {
						// 	productOptions.value.push(v);
						// });
						productOptions.value = data.option;
					},
					{ pno: product_pk },
				);
			}
			function addCart() {
				// 	'장바구니 추가',
				if (store.state.access_token.access_token) {
					if (selectedOption.value) {
						// alert('장바구니 추가');
						store.dispatch('cart/ADD_CART', {
							product_pk: props.product.product_pk,
							options: selectedOption.value.map((v) => v.product_option_pk),
							order_amount: 1,
							coupon_pk: '',
						});
					} else {
						// alert('옵션 없음');
					}
				}
			}
			function onClickCart() {
				addCart();
			}
			function onClickWish() {
				if (props.product.wish_pk) {
					removeWishItem(props.product.product_pk, props.product.wish_pk);
					emit('remove-wishitem', props.product.wish_pk);
				}
			}
			return {
				onClickCart,
				onClickWish,
				groupSet,
				productOptions,
				selectedOption,
				// optionDivision,
				// allOptions,
				// cOptionGroup,
				// allCOptions,
				// selectedCOptions,
				// selectedEOptions,
				// onSelectEOption,
				// checkEOption,
				// checkCOption,
				// getCOptionCartParam,
				// getEOptionCartParam,
				// getNoOptionCartParam,
			};
		},
	};
</script>

<style scoped>
	.wish_item_container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		/* height: 38rem; */
		gap: 2rem;
		transition: height 100ms linear;
	}
	/* @media screen and (max-width: 1281px) {
		.wish_item_container {
			height: 34rem;
		}
	}
	@media screen and (max-width: 930px) {
		.wish_item_container {
			height: 32rem;
		}
	}
	@media screen and (max-width: 768px) {
		.wish_item_container {
			height: 36rem;
		}
	}
	@media screen and (max-width: 600px) {
		.wish_item_container {
			height: 33rem;
		}
	} */
</style>
