<template>
	<transition name="fade">
		<div id="loading" v-if="this.$store.state.loading"></div>
	</transition>
</template>

<style>
	@keyframes spining {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	#loading {
		background-color: rgba(255, 255, 255, 0.8);
		position: fixed;
		top: var(--size-header-height);
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
	@media screen and (max-width: 768px) {
		#loading {
			top: var(--size-m_header-height);
		}
	}
	#loading::after {
		content: '';
		display: block;
		position: absolute;
		top: calc(50% - 5rem);
		left: calc(50% - 2rem);
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 0.7rem solid var(--color-primary-effect);
		border-top-color: var(--color-primary-contrast);
		animation: spining 0.7s ease-in-out infinite;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s ease-in-out;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>
