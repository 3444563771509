<template>
	<div id="product_wrap" @click="productDetail" style="cursor: pointer">
		<!-- <img
        src="@/assets/images/sub/item_list/bags/tote_taphandlebag/item03.jpg"
        alt="상품 이미지"
      /> -->

		<Image :item_path="productImage ?? product.product_image" />
		<div class="product_text">
			<p class="brand_name">
				<strong>{{ product.brand_name }}</strong>
			</p>
			<p class="product_name">{{ product.product_name }}</p>
			<!--<div class="price_wrapper" v-if="!$store.state.hidePrice || $store.state.access_token.access_token">-->
			<div class="price_wrapper" v-if="isPriceVisible">
				<span v-if="discountYn === 'true'" class="discount">{{
					product.discount_price ? String(numberFormat(discountToPercent)) + '%' : ''
				}}</span>
				<p class="original_price" v-if="product.discount_yn === 'true' && this.$store.state.access_token.access_token">
					&nbsp;{{
						product.product_saleprice ? numberFormat(product.product_saleprice) : numberFormat(product.product_price)
					}}
					원 &nbsp;
				</p>
				<p class="price">{{ numberFormat(finalPrice) }}<span class="won">원</span></p>
			</div>
			<div class="price_wrapper" v-else>
				<p class="original_price" style="text-decoration: none">가격 회원공개</p>
			</div>
			<!-- <p class="original_price" v-if="product.discount_yn === 'true' && this.$store.state.access_token.access_token">
				&nbsp;{{
					product.product_saleprice ? numberFormat(product.product_saleprice) : numberFormat(product.product_price)
				}}&nbsp;
			</p> -->
			<div class="point_wrapper" v-if="product.reward != 0">
				<p class="point" v-if="!isNaN(product.reward)">{{ numberFormat(product.reward) }} P</p>
			</div>
			<div v-if="type === 'default'" style="display: flex; justify-content: center">
				<div v-for="tag in product.product_tag" :key="tag.tag_pk">
					<p
						style="
							width: 7rem;
							margin: 0.5rem 0.1rem;
							font-size: 1.3rem;
							color: var(--color-primary);
							border: 0.1rem solid var(--color-primary);
						"
					>
						{{ tag.content }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Image from '@/components/atoms/Image.vue';
	import { numberFormat } from '@/utils/numberFormat';

	export default {
		components: {
			Image,
		},
		mixins: [numberFormat],
		props: {
			product: {
				type: Object,
				required: true,
			},
			type: {
				type: String,
				default: 'default',
			},
		},
		computed: {
			isPriceVisible() {
				return (
					this.product?.product_saleprice_exposure_division === '전체공개' ||
					this.$store.state.access_token.access_token
				);
			},
			finalPrice() {
				if (!this.$store.state.access_token.access_token) return this.product.product_saleprice; // 로그인 시에만 보여줌
				if (this.product?.discount_yn === undefined || this.product?.discount_yn === 'false')
					return this.product.product_saleprice;
				if (this.product.discount_unit === '원') return this.product.product_saleprice - this.product.discount_price;
				return (this.product.product_saleprice * (100 - this.product.discount_price)) / 100;
			},
			discountYn() {
				const startDate = new Date(this.product.discount_start_date);
				const endDate = new Date(this.product.discount_end_date);
				endDate.setDate(endDate.getDate() + 1);
				const today = new Date();
				if (
					!this.$store.state.access_token.access_token ||
					this.product.discount_yn === 'false' ||
					this.product?.discount_yn === undefined
				)
					return 'false'; // 로그인 시에만 보여줌
				if (this.product?.discount_period_yn === 'false') return 'true';
				if (startDate > today || today > endDate) {
					return 'false';
				}
				return 'true';
			},
			productDiscountPrice() {
				const startDate = new Date(this.product.discount_start_date);
				const endDate = new Date(this.product.discount_end_date);
				endDate.setDate(endDate.getDate() + 1);
				const today = new Date();
				if ((startDate > today || today > endDate) && this.product.discount_period_yn === 'true') {
					return this.product.product_saleprice;
				}
				if (this.product.discount_yn === 'false') return this.product.product_saleprice;

				let discounted = this.product.product_saleprice;

				if (this.product.discount_unit === '%') {
					discounted =
						Math.ceil(
							(this.product.product_saleprice * (100 - this.product.discount_price)) / 100 / 10,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
						) * 10;
				} else if (this.product.discount_unit === '원') {
					discounted -= this.product.discount_price;
				}

				return discounted;
			},
			discountToPercent() {
				if (this.product.discount_unit === '%') return this.product.discount_price;
				const dc = Math.ceil((this.product.discount_price / this.product.product_saleprice) * 100);
				return dc;
			},
			productImage() {
				return this.product.path + this.product.save_name;
				//if (this.product.path && this.product.save_name) {
				//	return this.product.path + this.product.save_name;
				//}
				//return '';
			},
		},
		methods: {
			productDetail() {
				const params = {
					product_pk: this.product.product_pk,
				};

				this.$router.push({
					name: 'ProductDetail',
					params: params,
				});
			},
		},
	};
</script>

<style scoped>
	#product_wrap {
		text-align: center;
	}
	/* #product_wrap:hover {
		opacity: 0.7;
	} */

	#product_wrap .img_container::before {
		padding-bottom: 130%;
	}

	#product_wrap >>> img {
		transform: scale(1);
		transition: opacity 0.2s ease-out 0.1s, transform 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
	}
	#product_wrap:hover >>> img {
		transform: scale(1.05);
		transition: opacity 0.2s ease-out 0.1s, transform 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
	}
</style>
