<template>
	<svg
		version="1.1"
		id="레이어_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 23 23"
		style="enable-background: new 0 0 23 23"
		xml:space="preserve"
	>
		<circle class="st0" :class="isPrimary" cx="11.5" cy="5.6" r="4.5" />
		<path
			class="st0"
			:class="isPrimary"
			d="M3.7,23.6V15c0-1.4,1.1-2.5,2.5-2.5h10.6c1.4,0,2.5,1.1,2.5,2.5v8.5"
		/>
	</svg>
</template>

<script>
	import { computed } from 'vue';
	export default {
		props: {
			primary: Boolean,
		},
		setup(props) {
			const isPrimary = computed(() => {
				return props.primary ? 'primary_color' : '';
			});
			return {
				isPrimary,
			};
		},
	};
</script>

<style scoped>
	svg {
		width: 2.2rem;
	}
	.st0 {
		fill: none;
		stroke: #222222;
		stroke-width: 2;
		stroke-linejoin: round;
	}
	.primary_color {
		stroke: var(--color-primary);
	}
</style>
