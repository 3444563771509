import { ref } from 'vue';
import { useStore } from 'vuex';

export default function useSelectImage({ image, images, thumbnail, thumbnails }) {
	const store = useStore();
	// const image = ref({});
	// const thumbnail = ref('');

	function getImage(event) {
		// 파일이 존재하는지
		if (event.target.files && event.target.files[0]) {
			// 이미지일 때만 this.image에 넣음
			if (
				event.target.files[0].type.includes('image') &&
				!event.target.files[0].type.includes('gif')
			) {
				// 2MB보다 크면 안받도록
				if (event.target.files[0].size > 1048576 * 2.5) {
					store.commit('alert', '2.5MB 이하 이미지를 선택해 주세요');
				} else {
					image.value = event.target.files[0];
					const reader = new FileReader();
					reader.onload = (event) => {
						thumbnail.value = event.target.result;
					};
					reader.readAsDataURL(event.target.files[0]);
				}
			} else {
				store.commit('alert', '이미지를 선택해주세요. (.gif 제외)');
			}
		}
	}
	function getImages(event) {
		// 새로 첨부하는 이미지 존재 & 기존에 가지고 있는 이미지 + 새로 첨부하는 이미지의 개수가 5개 이하
		const key = Date.now().toString();
		if (
			event.target.files &&
			thumbnails.value.length + event.target.files.length > 0 &&
			thumbnails.value.length + event.target.files.length <= 5
		) {
			for (let i = 0; i < event.target.files.length; i++) {
				if (event.target.files[i].size > 1048576 * 2.5) {
					store.commit('alert', '2.5MB 이하 이미지를 선택해주세요.');
					break;
				} else {
					images.value.push({ src: event.target.files[i], key: key + `/${i}` });
					const reader = new FileReader();
					reader.onload = (event) => {
						thumbnails.value.push({ src: event.target.result, key: key + `/${i}` });
					};
					reader.readAsDataURL(event.target.files[i]);
				}
			}
		} else {
			store.commit('alert', '이미지를 선택해주세요. (.gif 제외)');
		}
	}
	return {
		getImage,
		getImages,
	};
}
