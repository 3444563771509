<template>
	<div class="modal_container modal_coupon">
		<div class="btn_close_wrapper">
			<button @click="$emit('close-detail')">
				<span></span>
			</button>
		</div>
		<div class="modal_wrapper coupon_container">
			<div class="div_inner">
				<h4>상품 상세 정보</h4>
				<div class="detail_info content_wrapper">
					<div class="div_scroll">
						<table>
							<colgroup>
								<col width="30%">
								<col width="auto">
							</colgroup>
							<tr v-for="item in info" :key="item.division">
								<td>{{ item.division }}</td>
								<td>{{ item.content }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: Array,
		},
	};
</script>

<style scoped>
	/* .content_header + .content_body {
		margin-top: 2rem;
	} */
	.detail_info {
		width: 100%;
	}
	.detail_info table {
		border-collapse: collapse;
	}
	.detail_info td {
		padding: 1rem 1rem;
		height: 2rem;
		vertical-align: middle;
		line-height: 1.4;
		border-top: 1px solid var(--color-border-light);
		border-bottom: 1px solid var(--color-border-light);
	}
	.detail_info td:nth-child(1) {
		color: var(--color-font-mid);
		background: var(--color-bg-highlight);
	}
	.detail_info td:nth-child(2) {
	}
	/* @media screen and (max-width: 768px) {
		.content_header + .content_body {
			margin-top: unset;
		}
	} */
</style>
