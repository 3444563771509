<template>
	<input
		ref="templateInputRef"
		v-bind="$attrs"
		type="search"
		:placeholder="placeholder ? placeholder : '입력해주세요.'"
		:value="input"
		@input="bindInput"
	/>
</template>

<script>
	/**
 *  text 입력을 위한 컴포넌트
    <input type="text" :placeholder="placeholder" :value="props.value" @input="eventEmitter">
    <button @click="callback">
*/
	import { ref, onMounted } from 'vue';

	export default {
		name: 'TextInput',
		props: {
			value: {
				type: String,
				required: true,
			},
			eventName: {
				type: String,
				required: true,
			},
			placeholder: {
				type: String,
				required: false,
			},
			focus: Boolean,
		},
		setup(props, { emit }) {
			const input = ref('');
			const templateInputRef = ref(null);
			onMounted(() => {
				input.value = props.value;
				if (props.focus) {
					templateInputRef.value.focus();
				}
			});

			const bindInput = (event) => {
				input.value = event.target.value;
				emit(props.eventName, input.value);
			};

			return { templateInputRef, input, bindInput };
		},
	};
</script>

<style></style>
