<template>
	<div id="article_wrap" style="cursor: pointer" @click="onClick">
		<div class="detail_img">
			<img v-noimage :src="article?.path + article?.save_name ?? ''" alt="이벤트상세이미지" />
			<p :class="article.state !== '진행중' ? 'end_event' : ''">
				<button type="button">
					{{ article.state }}
				</button>
			</p>
		</div>
		<div class="detail_text">
			<p class="period">
				<span>{{ article?.enrollment_date ? article.enrollment_date.slice(0, 10) : '' }}</span>
				{{ type === '이벤트' ? '' : article?.end_date ? `- ${article.end_date.slice(0, 10)}` : '' }}
			</p>

			<p class="title">
				<strong>{{ article.title }}</strong>
			</p>
			<p class="description">
				{{ article.content }}
			</p>
		</div>
	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router';
	export default {
		props: {
			article: Object,
			type: String,
		},
		setup(props) {
			const router = useRouter();

			const routerParam = (name, articleId) => {
				return {
					name: name,
					params: { articleId: Number(articleId) },
				};
			};

			const pushMagazine = () => router.push(routerParam('MagazineDetail', props.article.magazine_pk));
			const pushTheme = () => router.push(routerParam('ThemeProductsDetail', props.article.exhibitions_pk));
			const pushEvent = () => router.push(routerParam('EventDetail', props.article.event_pk));

			const onClick = () => {
				props.type === '기획전'
					? pushTheme()
					: props.type === '매거진'
					? pushMagazine()
					: props.type === '이벤트'
					? pushEvent()
					: router.push('/404');
			};
			return {
				onClick,
			};
		},
	};
</script>

<style>
	#:hover {
		opacity: 0.7;
	}
</style>
