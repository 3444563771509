<template>
	<div class="header_container" :class="headerShownClass">
		<header class="c_inner">
			<div class="nav_top">
				<router-link to="/" class="ci">
					<img :src="logoImage" :alt="$store.state.template.mallName" />
				</router-link>
				<nav class="gnb">
					<ul class="depth1">
						<li
							v-for="level1 in $store.state.category.category.allDepth"
							:key="level1.category_pk"
							style="width: 11rem"
							@mouseenter="onMouseEnter(level1.category_pk)"
						>
							<a href="javascript:void(0)">{{ level1.content }}</a>

							<!---->
							<!-- <router-link
								:to="{
									name: 'Category',
									params: {
										category: level1.content,
									},
									query: {
										code: level1.code,
									},
								}"
								:style="$route.params.category === level1.content ? 'color: var(--color-primary)' : ''"
								>{{ level1.content }}</router-link
							> -->
						</li>
						<li class="category02 common_menu" @mouseenter="onMouseEnterMenu">
							<a href="javascript:void(0)">메뉴</a>
						</li>
					</ul>
				</nav>
				<transition name="fade">
					<SearchModal v-show="isSearchOpen" :isSearchOpen="isSearchOpen" @close-search="closeSearchModal" />
				</transition>
				<div class="header_menu">
					<a href="javascript:void(0)">
						<div class="icon_wrapper" @click="onClickLogout" v-if="$store.state.user.isLogin">
							<img src="@/assets/images/common/logout.svg" alt="로그아웃" />
							<span>로그아웃</span>
						</div></a
					>
					<a href="javascript:void(0)"
						><div
							class="icon_wrapper change_to_search_icon"
							v-once
							href="javascript:void(0)"
							style="cursor: pointer"
							@click.stop="openSearchModal"
						>
							<img src="@/assets/images/common/search.svg" alt="검색" />
							<span>검색</span>
						</div></a
					>

					<router-link id="wishlist-link" to="/wishlist" v-once>
						<div class="icon_wrapper">
							<img src="@/assets/images/common/like.svg" alt="찜하기" />
							<span>찜하기</span>
						</div>
					</router-link>
					<router-link to="/shoppingBag" v-once>
						<div class="icon_wrapper">
							<img src="@/assets/images/common/cart.svg" alt="장바구니" />
							<span>장바구니</span>
						</div>
					</router-link>
					<!-- 나중에 마이페이지로 이어지도록 바꿔야함 -->
					<router-link
						id="mypage-link"
						:to="access_token ? '/mypage/order_history' : { name: 'Login', query: { return_url: $route.fullPath } }"
					>
						<div class="icon_wrapper">
							<img src="@/assets/images/common/mypage.svg" alt="마이페이지" />
							<span>마이페이지</span>
						</div>
					</router-link>
				</div>
			</div>
		</header>

		<HeaderDropDown
			v-if="openDropDown"
			@close_dropdown="onMouseLeave"
			@go-page="goPage"
			:category="hoverCateogry"
			:menu="usableMenu"
			:brand="brandList"
			:mode="dropDownMode"
		/>
	</div>
</template>

<script>
	import { ref, computed, onBeforeMount, watch, onMounted } from 'vue';
	import { useStore } from 'vuex';
	import SearchInput from '@/components/molcules/Input/TextInput.vue';
	import SearchModal from '@/components/common/Search.vue';
	import HeaderDropDown from '@/components/common/HeaderDropDown.vue';
	import { useRoute, useRouter } from 'vue-router';
	import { modalOpenScrollFix, modalCloseScrollRestore } from '@/utils/modalScrollControl';

	export default {
		components: {
			SearchInput,
			SearchModal,
			HeaderDropDown,
		},
		props: {
			scrollDirection: Number,
			isScrollTop: Boolean,
		},
		setup(props) {
			const headerShownClass = computed(() => {
				if (props.scrollDirection === -1 && props.isScrollTop === false) return 'header_off';
				return '';
			});

			const store = useStore();

			const user = computed(() => store.state.user.user);
			const access_token = computed(() => store.state.access_token.access_token);
			const basicLogo = computed(() => store.getters['template/basicLogo']);
			const logoImage = computed(() => store.getters['template/logo']);

			function onClickLogout() {
				store.dispatch('user/LOGOUT');
			}

			return {
				headerShownClass,
				user,
				access_token,
				logoImage,
				basicLogo,
				onClickLogout,
				...useCategory(),
				...useSearchModal(),
			};
		},
	};
	const useCategory = () => {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const category = computed(() => store.getters['category/category']);
		onBeforeMount(() => {
			store.dispatch('category/GET_CATEGORY');
		});

		const openDropDown = ref(null);
		const dropDownMode = ref('');
		const hoverCateogry = computed(() => {
			if (!openDropDown.value) return null;
			return category.value.level1.find((v) => v.category_pk === openDropDown.value);
		});

		function onMouseEnter(category_pk) {
			openDropDown.value = category_pk;
			dropDownMode.value = 'category';
		}
		function onMouseLeave() {
			openDropDown.value = null;
			dropDownMode.value = '';
		}
		watch(
			route,
			(route) => {
				openDropDown.value = null;
			},
			{
				deep: true,
			},
		);

		const hoverMenu = ref([
			{
				content: '기획전',
				link: '/themeproducts',
				icon: require('../../assets/images/common/icon_menu_planitem_2.svg'),
			},
			{
				content: '매거진',
				link: '/magazine',
				icon: require('../../assets/images/common/icon_menu_magazine_2.svg'),
			},
			{
				content: '리뷰',
				link: '/review',
				icon: require('../../assets/images/common/icon_menu_review_2.svg'),
			},
			{
				content: '이벤트',
				link: '/event',
				icon: require('../../assets/images/common/icon_menu_event_2.svg'),
			},
			// { content: '고객센터', category_pk: 5, link: '/customer', icon: '@/assets/images/icon_menu_' },
			{
				content: '공지사항',
				link: '/notice',
				icon: require('../../assets/images/common/icon_menu_notice_2.svg'),
			},
			{ content: 'FAQ', link: '/faq', icon: require('../../assets/images/common/icon_menu_faq_2.svg') },
		]);

		const menu = computed(() => store.getters['template/menu']);
		const usableMenu = computed(() => {
			if (!menu.value || menu.value.length === 0) return [];

			let sortedMenu = menu.value.filter((v) => v.use_yn == 'true');
			sortedMenu = sortedMenu.filter((v) => hoverMenu.value.find((m) => m.content === v.content));
			sortedMenu.sort((a, b) => a.seq - b.seq);
			sortedMenu = sortedMenu.map((v) => ({ ...menu, ...hoverMenu.value.find((m) => m.content === v.content) }));

			return sortedMenu;
		});

		function onMouseEnterMenu() {
			openDropDown.value = 'menu';
			dropDownMode.value = 'menu';
		}
		function goPage(routerBody) {
			if (!routerBody) {
				// BRAND 클릭
				onClickBrand();
				return;
			}
			router.push(routerBody);
		}

		const brandList = ref([
			{
				brand_pk: 1,
				content: '셀린느',
			},
			{
				brand_pk: 2,
				content: '구찌',
			},
			{
				brand_pk: 3,
				content: '프라다',
			},
			{
				brand_pk: 4,
				content: '메종키츠네',
			},
			{
				brand_pk: 5,
				content: '코스',
			},
		]);
		function onClickBrand() {
			openDropDown.value = 'brand';
			dropDownMode.value = 'brand';
		}

		return {
			category,
			hoverCateogry,
			openDropDown,
			onMouseEnter,
			onMouseLeave,
			hoverMenu,
			onMouseEnterMenu,
			dropDownMode,
			goPage,
			usableMenu,
			brandList,
			onClickBrand,
		};
	};

	const useSearchModal = () => {
		const isSearchOpen = ref(false);

		const openSearchModal = () => {
			modalOpenScrollFix();
			isSearchOpen.value = true;
		};
		const closeSearchModal = () => {
			modalCloseScrollRestore();
			isSearchOpen.value = false;
		};
		return {
			isSearchOpen,
			openSearchModal,
			closeSearchModal,
		};
	};
</script>

<style scoped src="@/assets/css/components/header_basic.css"></style>
<style scoped src="@/assets/css/components/header_custom_01.css"></style>
<style scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.1s ease;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.icon_wrapper:hover {
		color: #222;
		opacity: 0.7;
	}

	.category02.common_menu {
		cursor: pointer;
	}
	.menu_wrapper {
		padding: 0.4rem 1.6rem;
		position: fixed;
		background-color: white;
		z-index: 400;
		height: max-content;
		text-align: left;
		border: 0.1rem solid var(--color-border-light);
		/* display: flex;
		flex-direction: column;
		gap: 0.8rem; */
	}
	.menu_wrapper li {
		line-height: 2;
	}
	.header_container {
		transition: top 0.2s linear;
	}
	.header_off {
		top: calc(calc(var(--size-header-height) + 0.3rem) * -1);
	}
	.header_container header .gnb .depth1 > li .menu_wrapper li a {
		font-size: var(--font-size-xx-small);
		color: var(--color-font-sub);
	}
	.header_container header .gnb .depth1 > li .menu_wrapper li a:hover {
		color: var(--color-primary);
	}
</style>
