<template>
	<router-link :to="to">
		<button type="button" class="btn_common">{{ title }}</button>
	</router-link>
</template>

<script>
	export default {
		props: {
			to: {
				type: String,
			},
			title: {
				type: String,
			},
		},
	};
</script>

<style></style>
