<template>
	<div
		v-if="isShown"
		v-infinite-scroll="{ onScrollEnd: () => onScrollEnd() }"
		style="width: 100%; height: 10rem; margin: auto"
	>
		<!-- <div id="intersectLoading" v-show="isLoading"></div> -->
		<InnerLoading :isLoading="isLoading" />
	</div>
</template>

<script>
	import { ref } from '@vue/reactivity';
	import InnerLoading from '@/components/common/InnerLoading.vue';

	export default {
		components: { InnerLoading },
		props: {
			onIntersected: {
				type: Function,
				required: true,
			},
			isShown: {
				type: Boolean,
				required: true,
			},
		},
		setup(props) {
			const isLoading = ref(false);

			const onScrollEnd = async () => {
				isLoading.value = true;

				await props.onIntersected();

				isLoading.value = false;
			};
			return {
				isLoading,
				onScrollEnd,
			};
		},
	};
</script>

<style scoped>
	@keyframes spining {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	#intersectLoading {
		width: 100%;
		height: 10rem;
		padding: 20px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#intersectLoading::after {
		content: '';
		display: block;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 0.7rem solid var(--color-primary-effect);
		border-top-color: var(--color-primary-contrast);
		animation: spining 0.7s ease-in-out infinite;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s ease-in-out;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>
