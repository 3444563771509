<template>
	<div class="screen_filter" v-if="this.$store.state.confirmContent"></div>
	<transition name="popup">
		<div class="confirm" v-if="this.$store.state.confirmContent">
			<div class="confirm_content">
				<p>{{ this.$store.state.confirmContent }}</p>
				<button type="button" class="btn btn_continue" @click="confirmContinue">확인</button>
				<button type="button" class="btn btn_close" id="confirm_close_btn" @click="confirmCancel">취소</button>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'confirm',
		// updated(){
		//     document.getElementById('confirm_close_btn').focus();
		// },
		methods: {
			confirmContinue() {
				this.$store.commit('confirmContinue');
			},
			confirmCancel() {
				this.$store.commit('confirmCancel');
			},
		},
	};
</script>

<style scoped>
	.screen_filter {
		z-index: 1000;
	}
	.confirm {
		position: fixed;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 40rem;
		max-width: 90vw;
		background-color: #fff;
		border-radius: 0.8rem;
		z-index: 1000;
	}
	.confirm .confirm_content {
		height: calc(100% - 10rem);
		background-color: #fff;
		border-radius: 1rem;
		padding: 2rem;
		text-align: center;
		font-size: 2rem;
		word-break: keep-all;
	}
	.confirm .confirm_content p {
		font-weight: 500;
	}
	.confirm .confirm_content .btn {
		display: inline-block;
		border: none;
		font-size: 1.6rem;
		color: #fff;
		padding: 0.8rem 2.6rem;
		border-radius: 0.8rem;
		margin-top: 3rem;
		cursor: pointer;
	}
	.confirm .confirm_content .btn_continue {
		margin-right: 10px;
		background-color: var(--color-primary);
	}
	.confirm .confirm_content .btn_continue:hover {
		background-color: var(--color-primary-effect);
	}
	.confirm .confirm_content .btn_close {
		color: #000;
		background-color: #efefef;
	}
	.confirm .confirm_content .btn_close:hover {
		background-color: #dfdfdf;
	}

	/* transition popup */
	.popup-enter-from,
	.popup-leave-to {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.9);
	}
	.popup-enter-active,
	.popup-leave-active {
		transition: all 0.1s ease-in-out;
	}
</style>
