<template>
	<div class="row_container" @click="goProductDetail(product)">
		<div class="img_container">
			<img :src="product.path + product.save_name" alt="" />
		</div>
		<div class="row_text_container" :style="type === 'timedeal' ? 'height:100%' : ''">
			<span class="product_name" style="padding: 0.5rem">{{ product.product_name }}</span>
			<span class="brand_name" style="padding: 0.5rem">{{ product.brand_name }}</span>

			<div class="price_container">
				<span class="price" style="padding: 0.5rem">{{ numberFormat(finalPrice) }} 원</span>
				<span v-if="discountYn == 'true'" class="discount" style="padding: 0.5rem">{{
					product.discount_price ? String(numberFormat(discountToPercent)) + '%' : ''
				}}</span>
				<span v-if="!isNaN(product.reward) && menu !== 'review'" class="point"
					>{{ numberFormat(product.reward) }}P</span
				>
			</div>
			<span class="original_price" style="padding: 0.5rem" v-if="discountYn == 'true'"
				>{{
					product.product_saleprice ? numberFormat(product.product_saleprice) : numberFormat(product.product_price)
				}}
				원</span
			>

			<div style="display: flex; justify-content: center">
				<div v-for="tag in product.product_tag" :key="tag">
					<p
						style="
							width: 7rem;
							padding: 0.5rem 0.1rem;
							font-size: 1.4rem;
							color: var(--color-primary);
							border: 0.1rem solid var(--color-primary);
						"
					>
						{{ tag.content }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Image from '@/components/atoms/Image.vue';
	import { numberFormat } from '@/utils/numberFormat';
	import { useRouter } from 'vue-router';
	import { computed } from '@vue/reactivity';
	import { useStore } from 'vuex';
	import { watchEffect } from '@vue/runtime-core';

	export default {
		components: {
			Image,
		},
		mixins: [numberFormat],
		props: {
			product: {
				type: Object,
				required: true,
			},
			type: {
				type: String,
				default: 'default',
			},
			menu: {
				type: String,
				default: 'notReview', // 리뷰창에선 포인트 정보 안 보이게 하기 위함
			},
		},
		setup(props) {
			const router = useRouter();
			function goProductDetail(product) {
				router.push(`/product/detail/${product.product_pk}`);
			}
			const store = useStore();

			const finalPrice = computed(() => {
				if (!store.state.access_token.access_token) return props.product.product_saleprice; // 로그인 시에만 보여줌
				if (props.product?.discount_yn === undefined || props.product?.discount_yn === 'false')
					return props.product.product_saleprice;
				if (props.product.discount_unit === '원') return props.product.product_saleprice - props.product.discount_price;
				return (props.product.product_saleprice * (100 - props.product.discount_price)) / 100;
			});
			const discountYn = computed(() => {
				const startDate = new Date(props.product.discount_start_date);
				const endDate = new Date(props.product.discount_end_date);
				endDate.setDate(endDate.getDate() + 1);
				const today = new Date();
				if (props.product.discount_yn === 'false' || props.product?.discount_yn === undefined) return 'false';
				if (props.product?.discount_period_yn === 'false') return 'true';
				if (startDate > today || today > endDate) {
					return 'false';
				}
				return 'true';
			});
			const productDiscountPrice = computed(() => {
				const startDate = new Date(props.product.discount_start_date);
				const endDate = new Date(props.product.discount_end_date);
				endDate.setDate(endDate.getDate() + 1);
				const today = new Date();
				if ((startDate > today || today > endDate) && props.product.discount_period_yn === 'true') {
					return props.product.product_saleprice;
				}
				if (props.product.discount_yn === 'false') return props.product.product_saleprice;

				let discounted = props.product.product_saleprice;

				if (props.product.discount_unit === '%') {
					discounted =
						Math.ceil(
							(props.product.product_saleprice * (100 - props.product.discount_price)) / 100 / 10,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
						) * 10;
				} else if (props.product.discount_unit === '원') {
					discounted -= props.product.discount_price;
				}

				return discounted;
			});
			const discountToPercent = computed(() => {
				if (props.product.discount_unit === '%') return props.product.discount_price;
				const dc = Math.ceil((props.product.discount_price / props.product.product_saleprice) * 100);
				return dc;
			});
			// watchEffect(()=>{
			// 	console.log('final',finalPrice.value);
			// 	console.log('discountYn',discountYn.value);
			// 	console.log('productDiscountPrice',productDiscountPrice.value)
			// 	console.log('discountToPercent',discountToPercent.value)
			// })
			return {
				goProductDetail,
				finalPrice,
				discountYn,
				productDiscountPrice,
				discountToPercent,
			};
		},
	};
</script>

<style scoped>
	.row_container {
		display: flex;
		align-items: center;
		height: 140px;
		width: 100%;
		cursor: pointer;
		overflow-x: hidden;
	}

	.row_container .img_container {
		height: calc(100% - 3rem);
		padding: 1.5rem;
		align-items: center;
		display: flex;
		/* background: var(--color-bg-highlight); */
	}
	.row_container .img_container img {
		height: 100%;
		width: auto;
	}
	.row_container .row_text_container {
		height: 140px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 0.5rem;
		/* background-color: whitesmoke; */
		overflow: hidden;
		white-space: nowrap;
	}
	.row_container .row_text_container span {
		padding: 0.1rem 0;
		text-overflow: ellipsis;
	}
</style>
