import useAxios from '@/compositions/useAxios';

const { fetchData } = useAxios();

export default {
    namespaced: true,
    state: {
        category: { level1: [], level2: [], level3: [], allDepth: [] },
    },
    mutations: {
        setCategory(state, payload) {
            state.category = payload;
        },
    },
    actions: {
        async GET_CATEGORY(context) {
            const category = await new Promise((resolve) => {
                fetchData('/getCategory', (data) => {
                    const category = {
                        level1: [],
                        level2: [],
                        level3: [],
                        allDepth: [],
                    };
                    data.category.forEach((item) => {
                        if (item.level === 1) {
                            category.level1.push({...item, children: [] });
                        } else if (item.level === 2) {
                            category.level2.push(item);
                        } else if (item.level === 3) {
                            category.level3.push(item);
                        }
                    });

                    // detph3
                    const newLevel2 = category.level2.map((level2) => {
                        level2.children = [];
                        category.level3.forEach((level3) => {
                            if (Number(level3.up_category) === level2.category_pk) {
                                level2.children.push(level3);
                            }
                        });
                        return level2;
                    });
                    // depth2
                    category.allDepth = category.level1.map((level1) => {
                        level1.children = [];
                        newLevel2.forEach((level2) => {
                            if (Number(level2.up_category) === level1.category_pk) {
                                level1.children.push(level2);
                            }
                        });
                        return level1;
                    });
                    resolve(category);
                }, {}, false);
            });
            context.commit('setCategory', category);
        },
    },
    getters: {
        categoryLevel1(state) {
            return state.category.level1;
        },
        categoryLevel2(state) {
            return state.category.level2;
        },
        categoryLevel3(state) {
            return state.category.level3;
        },
        categoryAll(state) {
            return state.category.allDepth;
        },
        category(state) {
            return state.category;
        },
    },
};