<template>
	<h2>
		<div style="display: flex; justify-content: center; align-items: center">
			<strong>{{ title }}</strong>
			<span
				v-if="division === '일반'"
				class="more_product"
				@click="$router.push({ name: 'MoreProduct', params: { display_pk } })"
			>
			</span>
		</div>
		<div>
			<p class="sub-title">{{ subContents }}</p>
			<p
				class="sub-title"
				style="display: flex; justify-content: flex-end; font-size: 1.4rem; cursor: pointer"
				@click="$router.push({ name: 'MoreProduct', params: { display_pk } })"
			>
				전체보기
			</p>
		</div>
	</h2>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
			},
			subContents: {
				type: String,
			},
			division: {
				type: String,
			},
			display_pk: Number,
		},
	};
</script>

<style>
	.more_product {
		position: relative;
		left: 6px;
		width: 10px; /* 사이즈 */
		height: 10px; /* 사이즈 */
		cursor: pointer;
		border-top: 2px solid #333;
		border-right: 2px solid #333;
		transform: rotate(45deg); /* 각도 */
	}

	.sub-title {
		color: var(--color-font-mid);
		font-size: var(--font-size-small);
		font-weight: var(--font-w-light);
		line-height: 1.3;
		letter-spacing: normal;
	}
</style>
