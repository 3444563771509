<template>
	<!-- 오늘만 한정수량 START -->
	<h2 class="hotdeal_title">
		<strong>{{ products.content }}</strong>
		<p class="sub-title">{{ products.sub_content }}</p>
	</h2>
	<div class="timedeal_container" @click="$router.push(`/product/detail/${products.product[0].product_pk}`)">
		<div class="overlay"></div>
		<div class="limit_wrapper">
			<div class="left">
				<h2>
					<div class="badge">Limited Item</div>
					<span
						><strong>{{ products.product[0].todaydeal_amt }}</strong> 한정 상품!</span
					>
				</h2>
				<!-- <div class="countdown_wrapper">
					<Countdown
						:deadline="$store.state.template.timedeal.endDate"
						:mainColor="'black'"
						:secondFlipColor="'black'"
						:mainFlipBackgroundColor="'#DCDCDC'"
						:secondFlipBackgroundColor="'#DCDCDC'"
						:showDays="false"
						:showSeconds="false"
						:labels="{}"
					/>
					<p class="hotdeal_text">
						오늘의 핫딜상품이 <br />
						<strong
							>{{ $store.state.template.timedeal.remainHour }}시간
							{{ $store.state.template.timedeal.remainMinute }}분</strong
						>
						남았습니다
					</p>
				</div> -->
			</div>
			<div class="center" @click="$router.push(`/product/detail/${products.product[0].product_pk}`)">
				<div class="img_container">
					<div class="img_wrapper">
						<div class="inner_wrapper">
							<img
								:src="products.product[0]?.path + products.product[0]?.save_name"
								:alt="products.product[0].product_name"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="right" @click="$router.push(`/product/detail/${products.product[0].product_pk}`)">
				<div class="desc_wrapper">
					<div class="desc">
						<p>
							<span>{{ products.product[0].product_name }}</span>
						</p>
						<p v-if="products.product[0].product_saleprice != products.product[0].after_discount_price">
							<span class="original_price">{{ numberFormat(products.product[0].product_saleprice) }}원</span>
							<span class="discount_amount">{{ getDiscountByPercent(products.product[0]) }}%</span>
							<span class="discount_price">{{ numberFormat(products.product[0].after_discount_price) }}원</span>
						</p>
						<p v-else>
							<span class="discount_price">{{ numberFormat(products.product[0].product_saleprice) }}원</span>
						</p>
						<p v-if="products.product[0].product_saleprice != products.product[0].after_discount_price">
							오늘 아니면 {{ numberFormat(getDicountedPrice(products.product[0])) }}원 더 비싸져요!
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 오늘만 한정수량 END -->
</template>

<script>
	import SwiperCore, { Pagination, Autoplay } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import 'swiper/swiper-bundle.min.css';

	import SwiperList from '@/components/molcules/Scroll/SwiperList.vue';
	import TimeDealItem from '@/components/molcules/Product/RowProductItem.vue';
	import ProductItemVue from '@/components/molcules/Product/ProductItem.vue';
	import TimedealProductSection from './TimedealProductSection.vue';
	import { numberFormat } from '@/utils/numberFormat';

	import { toRefs, computed } from '@vue/reactivity';
	import { ref } from 'vue';
	import { Countdown } from 'vue3-flip-countdown';
	import OnlyTodayProduct from './OnlyTodayProduct.vue';

	SwiperCore.use([Pagination, Autoplay]);

	export default {
		components: {
			ProductItemVue,
			TimeDealItem,
			SwiperList,
			Swiper,
			SwiperSlide,
			TimedealProductSection,
			Countdown,
			OnlyTodayProduct,
		},
		props: {
			products: Array,
		},
		mixins: [numberFormat],
		setup(props) {
			const { products: timeDealProduct } = toRefs(props);

			const getDiscountByPercent = (product) => {
				if (product.discount_unit === '원') {
					return Math.ceil((product.discount_price / product.product_saleprice) * 100);
				} else {
					return product.discount_price;
				}
			};
			const getDicountedPrice = (product) => {
				// % 할인의 할인 가격을 구하기 위해서
				if (product.discount_unit === '원') {
					return product.discount_price;
				} else {
					return (product.product_saleprice * product.discount_price) / 100;
				}
			};

			return { getDiscountByPercent, getDicountedPrice };
		},
	};
</script>

<style scoped></style>
