<template>
	<Swiper v-bind="swiperOptions" :resistanceRatio="0.5" style="margin-left: 2rem" :navigation="true">
		<SwiperSlide v-for="product in productList" :key="product.product_pk">
			<ProductItem v-if="productType === 'default'" :product="product" />
			<WishItem v-if="productType === 'wish'" :product="product" @remove-wishitem="removeWish" />
		</SwiperSlide>
	</Swiper>
</template>

<script>
	import { reactive, ref } from 'vue';

	import { Swiper, SwiperSlide } from 'swiper/vue';
	import SwiperCore, { Pagination, Navigation } from 'swiper';
	import ProductItem from '@/components/molcules/Product/ProductItem.vue';
	import WishItem from '@/components/molcules/Product/WishItem.vue';

	import 'swiper/swiper-bundle.min.css';

	SwiperCore.use([Pagination, Navigation]);

	export default {
		props: {
			max_product: {
				type: Number,
				default: 6,
			},
			productList: {
				type: Array,
			},
			pagination: {
				type: Boolean,
				default: false,
			},
			productType: {
				type: String,
				default: 'default',
			},
		},
		components: {
			ProductItem,
			Swiper,
			SwiperSlide,
			WishItem,
		},
		setup(props, { emit }) {
			const swiperOptions = ref({
				effect: 'fade',
				slidesPerView: 2,
				slidesPerGroup: 2,
				spaceBetween: 15,
				pagination: props.pagination,
				// 반응형 breakpoints
				breakpoints: {
					//slidePerView 값을 정수값이 아니라 .3으로 맞춘 이유는 UX적 측면에서 다음 상품을 조금 노출시킴으로써 스와이프가 가능하다는 걸 알려주기 위함.
					375: {
						slidesPerView: 2.3,
						slidesPerGroup: 2,
					},
					425: {
						slidesPerView: 3.3,
						slidesPerGroup: 3,
					},
					768: {
						slidesPerView: 5.3,
						slidesPerGroup: 5,
					},
					1024: {
						slidesPerView: props.max_product + 0.3,
						slidesPerGroup: props.max_product,
					},
				},
			});

			function removeWish(wishPk) {
				emit('remove-wish', wishPk);
			}
			return { swiperOptions, removeWish };
		},
	};
</script>

<style scoped></style>
