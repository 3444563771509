import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

import user from './modules/user';
import access_token from './modules/access_token';
import cart from './modules/cart';
import modal from './modules/modal';
import category from './modules/category';
import product from './modules/product';
import template from './modules/template';
import orders from './modules/orders';

export default createStore({
	state: {
		alertContent: '', // 알림창 내용
		confirmContent: '',
		confirmCallback: null,

		loading: false, // 로딩

		searchTab: false, //연관검색어

		hidePrice: false,

		scrollDirection: 0, // -1 : down, 1 : up, 0 : default
		isScrollTop: true,
	},
	mutations: {
		// 알림창
		alert(state, payload) {
			state.alertContent = payload;
			const timeoutId = setTimeout(() => {
				state.alertContent = '';
				clearTimeout(timeoutId);
			}, 2000);
		},
		closeAlert(state) {
			state.alertContent = '';
		},

		// 확인(confirm)창
		confirm(state, payload) {
			state.confirmContent = payload.content;
			state.confirmCallback = payload.callback;
		},
		confirmContinue(state) {
			state.confirmContent = '';
			state.confirmCallback();
		},
		confirmCancel(state) {
			state.confirmContent = '';
			state.confirmCallback = null;
		},

		// 로딩
		startLoading(state) {
			state.loading = true;
		},
		finishLoading(state) {
			state.loading = false;
		},

		// 연관 검색어
		setSearchTab(state, value) {
			state.searchTab = value;
		},

		setHidePrice(state, value) {
			state.hidePrice = value;
		},
		setScrollInfo(state, { scrollDirection, isScrollTop }) {
			if (scrollDirection !== undefined) state.scrollDirection = scrollDirection;
			if (isScrollTop !== undefined) state.isScrollTop = isScrollTop;
		},
	},
	actions: {},
	getters: {
		searchTab(state) {
			return state.searchTab;
		},
		scrollInfo(state) {
			return {
				isScrollTop: state.isScrollTop,
				scrollDirection: state.scrollDirection,
			};
		},
	},
	modules: {
		user,
		access_token,
		cart,
		modal,
		category,
		product,
		template,
		orders,
	},
	plugins: [
		createPersistedState({
			paths: ['cart', 'category', 'template'],
		}),
		createPersistedState({
			key: 'login',
			paths: ['access_token'],
			storage: {
				getItem(key) {
					if (JSON.parse(localStorage.getItem('saveLogin'))) {
						return localStorage.getItem(key);
					} else {
						return Cookies.get(key);
						// return sessionStorage.getItem(key);
					}
				},
				setItem(key, value) {
					if (JSON.parse(localStorage.getItem('saveLogin'))) {
						localStorage.setItem(key, value);
					} else {
						Cookies.set(key, value);
						// sessionStorage.setItem(key, value);
					}
				},
				removeItem(key) {
					if (JSON.parse(localStorage.getItem('saveLogin'))) {
						localStorage.removeItem(key);
					} else {
						Cookies.remove(key);
						// sessionStorage.removeItem(key);
					}
				},
			},
		}),
	],
});
