<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.25 33.25">
		<g id="레이어_2" data-name="레이어 2">
			<g id="icon">
				<path
					class="cls-1"
					:class="isOn"
					d="M29.78,12.75H24a2.93,2.93,0,0,1-3.16-2.44s3.2-4.61.29-8C17.47-1.91,15,3.09,15,3.09L8.05,12.75H8V32.63H22.9c4,0,4.61-2.66,4.61-2.66s3.24-7.69,4.6-11.58C34.09,12.75,29.78,12.75,29.78,12.75Z"
				/>
				<path class="cls-1" :class="isOn" d="M.63,12.77V32.63H8.5V12.77Z" />
			</g>
		</g>
	</svg>
</template>

<script>
import { computed } from 'vue';
export default {
	props: {
		on: Boolean,
	},
	setup(props) {
		const isOn = computed(() => {
			return props.on ? 'on' : '';
		});
		return {
			isOn,
		};
	},
};
</script>

<style scoped>
svg {
	margin-right: 0.6rem;
	width: 1.5rem;
}
svg:active {
	opacity: 0.8;
}
.cls-1 {
	fill: none;
	stroke: #7b7b7b;
	stroke-linejoin: round;
	stroke-width: 1.25px;
}
.cls-1.on {
	stroke: var(--color-primary-effect);
}
</style>