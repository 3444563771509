<template>
	<div class="dropdown_title dropdown_brand" style="flex-basis: auto">
		<div class="search">
			<label>브랜드 검색</label>
			<input
				:value="searchInput"
				@input="handleSearch"
				@keypress="handleSearch"
				placeholer="검색하기"
				type="search"
				placeholder="입력해주세요."
			/>
			<button @click="handleSearch" type="button" class="icon_search"></button>
		</div>
		<ul class="list_brand_name">
			<li
				v-for="(filter, idx) in ['ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ']"
				:key="idx"
				:class="currentFilter === filter ? 'on' : ''"
				@click="currentFilter === filter ? (currentFilter = '') : (currentFilter = filter)"
			>
				{{ filter }}
			</li>
		</ul>
		<div class="brand_img_wrapper" style="position: relative">
			<div
				v-show="isLoading"
				style="
					position: absolute;
					background: #99999970;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				"
			>
				<InnerLoading :isLoading="isLoading" />
			</div>
			<ul class="list_brand_img">
				<li v-for="brand in brands" :key="brand.brand_pk" @click="() => onClickBrand(brand.content)">
					<!-- <div>
						<img v-noimage :src="brand.path + brand.save_name" alt="" />
					</div> -->
					<div class="img_container">
						<div class="overlay"></div>
						<div class="img_wrapper">
							<div class="inner_wrapper">
								<img v-noimage :src="brand.path + brand.save_name" alt="" />
							</div>
						</div>
					</div>
					<p>
						{{ brand.content }}
					</p>
				</li>
				<p style="margin: auto" v-if="!isLoading && brands.length === 0">해당 브랜드 검색 결과가 없습니다</p>
			</ul>
		</div>
	</div>
</template>
<script>
	import { onMounted, ref, watch } from 'vue';

	import Input from '@/components/atoms/Input.vue';
	import InnerLoading from '@/components/common/InnerLoading.vue';

	import { searchBrand } from '@/api';
	import { useRouter } from 'vue-router';

	export default {
		components: {
			Input,
			InnerLoading,
		},
		setup() {
			const brands = ref([]);
			const filterList = ref();
			const currentFilter = ref('');

			const searchInput = ref('');

			const debounce = ref(null);

			const isLoading = ref(true);

			onMounted(() => {
				getBrand('', '');
			});

			async function getBrand(search, text) {
				isLoading.value = true;
				const data = await searchBrand(search, text);
				brands.value = data.filter((v) => v.use_yn === 'true' && v.content !== null);
				isLoading.value = false;
			}
			async function handleSearch(e) {
				currentFilter.value = '';
				searchInput.value = e.target.value;

				if (!searchInput.value) {
					return;
				}
				clearTimeout(debounce.value);
				debounce.value = setTimeout(async () => {
					await getBrand(currentFilter.value, searchInput.value);
				}, 400);
			}
			watch(currentFilter, (value, oldValue) => {
				getBrand(value, searchInput.value);
			});

			const router = useRouter();
			function onClickBrand(brand) {
				router.push(`/search?q=${brand}`);
			}
			return { isLoading, brands, filterList, currentFilter, searchInput, handleSearch, onClickBrand };
		},
	};
</script>

<style scoped>
	/* 드롭 다운 - 브랜드 */
	.dropdown_brand {
		width: 74rem;
		flex-wrap: wrap;
	}
	.dropdown_brand .search {
		align-items: center;
	}
	.dropdown_brand .search label {
		flex-basis: 10rem;
		font-size: var(--font-size-xx-small);
		font-weight: var(--font-w-regular);
	}
	.dropdown_brand .list_brand_name {
		flex-basis: 100%;
		display: flex;
		justify-content: center;
		gap: 0 1.6rem;
	}
	.dropdown_brand .list_brand_name li {
		color: #888;
		font-size: var(--font-size-xx-small);
		font-weight: var(--font-w-regular);
	}
	.dropdown_brand .list_brand_name li.on {
		color: #222;
		font-weight: var(--font-w-bold);
	}
	.dropdown_brand .brand_img_wrapper {
		width: 100%;
		max-height: 30rem;
		overflow-y: scroll;
	}
	.dropdown_brand .list_brand_img {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		min-height: 15rem;
	}
	.dropdown_brand .list_brand_img li {
		flex-basis: calc((100% - 6rem) / 4);
	}
	.dropdown_brand .list_brand_img li .img_container {
		position: relative;
	}
	.dropdown_brand .list_brand_img li .img_container::before {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	.dropdown_brand .list_brand_img li .img_container .overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.03);
	}
	.dropdown_brand .list_brand_img li .img_container .img_wrapper {
		width: 80%;
		height: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dropdown_brand .list_brand_img li .img_container .img_wrapper .inner_wrapper {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.dropdown_brand .list_brand_img li .img_container .img_wrapper .inner_wrapper img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		/* transform: scale(1);
		transition: opacity 0.2s ease-out 0.1s, transform 0.2s cubic-bezier(0.4, 0, 1, 1) 0s; */
	}
	.dropdown_brand .list_brand_img li p {
		/* display: none; */
		margin: 1rem 0;
		font-size: 1.5rem;
		font-weight: 400;
		text-align: center;
	}
	@media screen and (max-width: 930px) {
		.dropdown_brand {
			width: calc(100% - 4rem);
			padding: 2rem;
			background-color: white;
			display: flex;
			gap: 1rem;
		}

		.dropdown_brand .list_brand_name {
			flex-wrap: wrap;
		}

		.dropdown_brand .list_brand_img li {
			flex-basis: calc((100% - 6rem) / 4);
		}
	}
	@media screen and (max-width: 599px) {
		.dropdown_brand .list_brand_img li {
			flex-basis: calc((100% - 4rem) / 3);
		}
	}
	@media screen and (max-width: 360px) {
		.dropdown_brand .list_brand_img li {
			flex-basis: calc((100% - 2rem) / 2);
		}
	}
</style>
