<template>
	<h2>상품랭킹</h2>
	<p class="deco_underline"></p>
	<!-- <div class="categories_btn">
		<button type="button" class="on" @click="clickRankTab($event)">전체</button>
		<button type="button" @click="clickRankTab($event)">여성</button>
		<button type="button" @click="clickRankTab($event)">남성</button>
		<button type="button" @click="clickRankTab($event)">키즈</button>
	</div> -->
	<div class="item_list_container">
		<div class="left">
			<ul class="item_list">
				<li class="ranking01">
					<a href="javascript:void(0)" @click="goProductDetail(rankingItems.product[0].product_pk)">
						<p class="ranking_num">1</p>
						<div class="img_container">
							<div class="overlay"></div>
							<div class="img_wrapper">
								<div class="inner_wrapper">
									<img
										v-noimage
										:src="rankingItems.product[0].path + rankingItems.product[0].save_name"
										alt="newarrivals01"
									/>
								</div>
							</div>
						</div>
						<div class="product_text">
							<p class="brand_name">
								<strong>{{ rankingItems.product[0].brand_name }}</strong>
							</p>
							<p class="product_name">{{ rankingItems.product[0].product_name }}</p>
							<p class="price">{{ numberFormat(rankingItems.product[0].product_saleprice) }}</p>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="right">
			<ul class="item_list">
				<li v-for="(item, idx) in rankingItems.product.slice(1)" :key="item">
					<p class="ranking_num">{{ idx + 2 }}</p>
					<ProductItem :product="item" :type="'ranking'" />
				</li>
			</ul>
		</div>
	</div>
	<div v-if="rankingItems.product.length === 0">
		<p style="text-align: center">상품이 없습니다.</p>
	</div>
</template>

<script>
	import { ref, watch } from 'vue';
	import { useRouter } from 'vue-router';

	import ProductItem from '@/components/molcules/Product/ProductItem.vue';

	import { numberFormat } from '@/utils/numberFormat';
	export default {
		components: {
			ProductItem,
		},
		mixins: [numberFormat],
		props: {
			rankingItems: {
				type: Object,
			},
		},
		setup() {
			const rankingTab = ref('전체');

			function clickRankTab(event) {
				const tabs = document.querySelectorAll('.ranking .categories_btn button');
				tabs.forEach((item) => item.classList.remove('on'));
				const current = event.currentTarget;
				current.classList.add('on');
				rankingTab.value = current.outerText;
			}

			const router = useRouter();
			function goProductDetail(pk) {
				router.push(`/product/detail/${pk}`);
			}

			return {
				rankingTab,
				clickRankTab,
				goProductDetail,
			};
		},
	};
</script>

<style scoped>
	.img_container {
		position: relative;
	}
	.img_container .overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.04);
	}
	.img_container::before {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	.img_container .img_wrapper {
		width: 80%;
		height: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.img_container .img_wrapper .inner_wrapper {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.img_container .img_wrapper .inner_wrapper img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	/* 호버 효과 START */
	.img_container {
		cursor: pointer;
	}
	.img_container img {
		transform: scale(1);
		transition: opacity 0.2s ease-out 0.1s, transform 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
	}
	.img_container:hover img {
		transform: scale(1.05);
		transition: opacity 0.2s ease-out 0.1s, transform 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
	}
</style>
