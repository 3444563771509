<template>
	<div class="modal_container" style="">
		<div class="btn_close_wrapper" style="padding: 1rem 2rem">
			<button @click="$store.commit('modal/closeImageModal')">
				<span class="btn_close_span"></span>
			</button>
		</div>
		<div class="image_container">
			<button class="prev_btn" @click="prevImage">
				<span class="arrow-prev"></span>
			</button>
			<div class="image_wrapper">
				<img class="review_image" :src="thumbImage" />
			</div>

			<button class="next_btn" @click="nextImage">
				<span class="arrow-next"></span>
			</button>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { useStore } from 'vuex';
	export default {
		setup() {
			const store = useStore();
			const images = computed(() => store.getters['modal/images']);
			const review = computed(() => store.getters['modal/reviewContent']);

			const thumbImage = ref(images.value[0]);

			const currentImage = ref(0);

			const prevImage = () => {
				if (currentImage.value - 1 < 0) currentImage.value = images.value.length - 1;
				else currentImage.value -= 1;
				thumbImage.value = images.value[currentImage.value];
			};
			const nextImage = () => {
				if (currentImage.value + 1 == images.value.length) currentImage.value = 0;
				else currentImage.value += 1;
				thumbImage.value = images.value[currentImage.value];
			};

			return { images, review, thumbImage, prevImage, nextImage };
		},
	};
</script>

<style scoped>
	.image_container {
		position: relative;
		display: flex;
		align-items: center;
	}
	.prev_btn {
		position: absolute;
		left: -50px;
	}
	.next_btn {
		position: absolute;
		right: -50px;
	}
	.image_wrapper {
		display: flex;
		height: 500px;
		width: 100%;
		align-items: center;
		justify-content: center;
		background: white;
	}
	.review_image {
		width: 500px;
		height: auto;
	}

	.arrow-prev,
	.arrow-next {
		position: relative;
		float: left;
		/* border: 1px solid #000; */
		width: 30px;
		height: 30px;
	}

	.arrow-prev::after {
		position: absolute;
		left: 0px;
		top: 0px;
		content: '';
		width: 20px; /* 사이즈 */
		height: 20px; /* 사이즈 */
		border-top: 2px solid var(--color-border-light);
		border-right: 2px solid var(--color-border-light);
		transform: rotate(225deg); /* 각도 */
	}

	.arrow-next::after {
		position: absolute;
		left: 0px;
		top: 0px;
		content: '';
		width: 20px; /* 사이즈 */
		height: 20px; /* 사이즈 */
		border-top: 2px solid var(--color-border-light);
		border-right: 2px solid var(--color-border-light);
		transform: rotate(45deg); /* 각도 */
	}

	@media screen and (max-width: 768px) {
		.modal_container .btn_close_wrapper button span::before,
		.modal_container .btn_close_wrapper button span::after {
			background-color: var(--color-border-light);
		}
		.btn_close_wrapper {
			background: rgb(0, 0, 0, 0);
		}
		.image_container {
			height: 100vh;
			background: rgb(0, 0, 0, 0);
		}
		.image_wrapper {
			height: 100%;
			background: rgb(0, 0, 0, 0);
		}
		.review_image {
			width: 80%;
			height: auto;
		}

		.prev_btn {
			left: 10px;
		}
		.next_btn {
			right: 5px;
		}
	}
</style>
