<template>
	<div class="category_dropdown" @mouseleave="$emit('close_dropdown')">
		<div class="dropwdown_submenu_wrapper">
			<div class="dropwdown_submenu c_inner">
				<!-- <div class="dropdown_title" v-if="mode === 'category'">
					<a
						@click="
							$emit('go-page', {
								name: 'Category',
								params: {
									category: category.content,
								},
								query: {
									code: category.code,
								},
							})
						"
					>
						{{ category.content }}
					</a>
				</div> -->
				<div class="category_section_wrapper" v-if="mode === 'category'">
					<div
						class="category_section"
						v-for="subcategory in category.children"
						:key="subcategory.category_pk"
						@click="
							$emit('go-page', {
								name: 'Category',
								params: {
									category: category.content,
									subcategory: subcategory.content,
								},
								query: {
									code: subcategory.code,
								},
							})
						"
					>
						<img v-noimage :src="subcategory?.path + subcategory?.save_name ?? ''" alt="" width="100" height="100" />
						<h4 class="category_title">
							{{ subcategory.content }}
						</h4>
					</div>
				</div>

				<div class="menu" v-if="mode === 'menu'">
					<div
						class="category_section"
						@click="$emit('go-page', item.link)" v-for="item in menu" :key="item.category_pk"
					>
						<img :src="item.icon" :alt="item.content + '아이콘'" width="100" height="100" />
						<h4 class="category_title">
							{{ item.content }}
						</h4>
					</div>
				</div>

				<!-- <div class="menu" v-if="mode === 'menu'" style="width: 100%">
					<div class="menu_container">
						<a class="menu_item" @click="$emit('go-page', item.link)" v-for="item in menu" :key="item.category_pk">
							<div>
								<img :src="item.icon" :alt="item.content + '아이콘'" />
							</div>
							<p>{{ item.content }}</p>
						</a>
					</div>
				</div> -->

				<!-- 브랜드 클릭 팝업 -->
				<div style="width: 100%; display: flex; justify-content: center">
					<BrandSerachMenu v-if="mode === 'brand'" />
				</div>
			</div>
		</div>
		<!-- 
		<div class="dropwdown_submenu_wrapper">
			<div class="dropwdown_submenu c_inner">
				<div class="dropdown_title">
					<a>
						브랜드
					</a>
				</div>
				<div class="brand_section_wrapper">
					<ul class="list_brand_img">
						<li>
							<img src="@/assets/images/brand/Coltorti.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Farfetch.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/G&B.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Gente.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Harrods.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/HarveyNichols.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Leam.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/LNCC.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Matches.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/MrPorter.png" alt="brand" />
						</li>

						<li>
							<img src="@/assets/images/brand/Mytheresa.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Net-A-Porter.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/OFF5TH.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Outnet.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Saks.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Selfridges.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/SSENSE.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Tessabit.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/ThedoubleF.png" alt="brand" />
						</li>
						<li>
							<img src="@/assets/images/brand/Yoox.png" alt="brand" />
						</li>
					</ul>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import BrandSerachMenu from './HeaderDropDownBrand.vue';

	export default {
		components: {
			BrandSerachMenu,
		},
		props: {
			category: {
				type: Object,
			},
			menu: { type: Array },
			mode: {
				type: String,
				required: true,
			},
			brand: Array,
		},
	};
</script>

<style scoped>
	.category_dropdown {
		padding-bottom: 2rem;
		background: #fff;
		/* padding: 1rem 0; */
		height: auto;
		width: 100vw;
		border-top: 0.1rem solid var(--color-border-highlight);
		display: flex;
		flex-basis: 100%;
		flex-direction: column;
		align-items: center;
		background-color: rgb(255, 255, 255);
		z-index: 100;
	}
	@media screen and (max-width: 930px) {
		.category_dropdown_bg,
		.category_dropdown {
			visibility: collapse;
		}
	}

	.dropwdown_submenu_wrapper {
		width: 100%;
		border-top: 0.1rem solid var(--color-border-highlight);
		display: flex;
		justify-content: center;
	}
	.dropwdown_submenu {
		padding: 3rem 0;
		min-width: 70rem;
	}
	.dropdown_title {
		font-size: var(--font-size-x-large);
		font-weight: var(--font-w-mid);
		/* padding: 0rem 1rem; */
		/* padding-bottom: 1rem; */
		/* margin-bottom: 2rem; */
		display: flex;
		justify-content: flex-start;
		text-align: center;
		cursor: pointer;
		gap: 1.5rem;
		flex-basis: 24%;
		white-space: nowrap;
	}
	.dropdown_title a:hover {
		opacity: 0.7;
	}
	.category_section_wrapper {
		display: flex;
		justify-content: center;
		align-content: center;
		gap: 2rem;
	}

	.category_section {
		min-width: 10rem;
		cursor: pointer;
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
	.category_section:hover {
		opacity: 0.8;
	}
	.category_title {
		font-weight: var(--font-w-regular);
		font-size: 1.4rem;
		border: none;
		margin: 0;
		padding: unset;
		cursor: pointer;
	}

	.category_section_list {
		display: flex;
		flex-direction: column;
		font-size: 1.3rem;
		gap: 0.5rem;
	}

	.menu {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 1.5rem;
	}
	.menu_container {
		margin: 0 auto;
		flex-basis: 52%;
		display: flex;
		justify-content: flex-start;
		align-content: center;
		gap: 4rem;
	}
	.menu_container .menu_item {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 2rem;
		cursor: pointer;
		/* flex-basis: calc(100% / 6); */
	}
	.menu_container .menu_item div {
		border-radius: 100%;
		background-color: #fafafa;
		/* padding: 2.5rem; */
		width: 20rem;
		height: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.menu_container .menu_item div img {
		width: 40%;
	}
	.menu_container .menu_item p {
		font-size: 1.4rem;
	}

	.brand_section_wrapper {
		flex-basis: 52%;
		display: flex;
		justify-content: center;
		align-content: center;
		gap: 2rem;
	}
	.brand_section_wrapper ul {
		height: 13rem;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		overflow-y: scroll;
	}
	.brand_section_wrapper ul li {
		padding: 1rem 0;
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--color-bg-highlight);
		flex-basis: calc((100% - 4rem) / 5);
		overflow: hidden;
		cursor: pointer;
	}
	.brand_section_wrapper ul li img {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 70%;
	}
</style>
