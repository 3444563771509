import { useRouter } from 'vue-router';
import useAxios from '../compositions/useAxios';
import store from '../store';

export const deleteReviewImage = async (rino, pno) => {
	const { postData } = useAxios();

	return await new Promise((resolve) =>
		postData('/deleteReviewImage', { rino, pno }, (res) => {
			resolve(res.code);
		}),
	);
};

export const fetchHome = async () => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) =>
		fetchData('/getMain', (data) => {
			const timedeal = data.display.find((v) => v.division === '핫딜');
			if (timedeal) {
				//const endDate = timedeal.end_date;
				const currentDate = new Date();

				store.commit(
					'template/setTimeDealEndDate',
					new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
				);
			}
			resolve(data);
		}),
	);
};

export const fetchPopularKeywords = async () => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) => fetchData('/getPopularity', (data) => resolve(data)));
};

export const fetchRecommendKeywords = async () => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) => fetchData('/getRecommend', (data) => resolve(data)));
};

export const searchKeyword = async (search) => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) =>
		fetchData(
			'/getSearch',
			(data) => {
				resolve(data);
			},
			{ search },
		),
	);
};

export const fetchMyInquiry = async () => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) =>
		fetchData('/getMyInquiry', (data) => {
			resolve(data.inquiry);
		}),
	);
};
export const deleteInquiry = async (ino) => {
	const { postData } = useAxios();
	return await new Promise((resolve) =>
		postData('/deleteInquiry', { ino }, () => {
			resolve();
		}),
	);
};

export const fetchProductDetail = async (pno) => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) =>
		fetchData(
			'/getProductDetail',
			(data) => {
				resolve(data);
			},
			{ pno },
		),
	);
};

export const fetchMenu = async (menuContent) => {
	const menus = store.state.template.templateData.menu;
	const menu = menus.find((v) => v.content === menuContent);
	if (!menu) return;
	const menu_pk = menu.homepage_menu_pk;

	const { fetchData } = useAxios();
	return await new Promise((resolve) =>
		fetchData(
			'/getMenu',
			(data) => {
				resolve(data);
			},
			{ mno: menu_pk },
		),
	);
};

export const fetchMenuDetail = async (menu_pk, detail_pk) => {
	const { fetchData } = useAxios();
	return await new Promise((resolve, reject) => {
		if (!menu_pk || !detail_pk) {
			reject();
			return;
		}

		fetchData(
			'/getMenuDetail',
			(data) => {
				resolve(data);
			},
			{
				mno: menu_pk,
				dno: detail_pk,
			},
			false,
		);
	});
};
export const saveCartAmount = async (ono, order_amount) => {
	const { postData } = useAxios();
	return await new Promise((resolve) => {
		postData(
			'/saveCartAmount',
			{
				ono,
				order_amount,
			},
			(res) => {
				resolve(res.delivery_price);
			},
			false,
		);
	});
};

// serach : ㄱ,ㄴ,ㄷ, ...
// text : '구찌'
export const searchBrand = async (search, text) => {
	const { fetchData } = useAxios();

	return await new Promise((resolve) =>
		fetchData(
			'/getBrand',
			(data) => resolve(data.data),
			{
				search: search,
				text: text,
			},
			false,
		),
	);
};
