<template>
	<section :class="className">
		<HomeSectionTitle :title="sectionTitle" />
		<HomeSectionDivider />
		<div v-if="productList.length === 0" style="text-align: center; font-size: 2rem">
			<p>상품을 준비중입니다.</p>
		</div>
		<SwiperList :productList="productList" :pagination="true" />
	</section>
</template>

<script>
	import SwiperList from '@/components/molcules/Scroll/SwiperList';
	import HomeSectionTitle from '@/components/molcules/Text/SecondHeader.vue';
	import HomeSectionDivider from '@/components/molcules/Divider/SectionDivider.vue';
	import SectionBtnWrapper from '@/components/molcules/Section/SectionBtnWrapper.vue';
	import RouterButton from '@/components/molcules/Button/RouterButton.vue';

	export default {
		props: {
			className: {
				type: String,
			},
			sectionTitle: {
				type: String,
			},
			productList: {
				type: Array,
			},
			detailLink: {
				type: String,
			},
			detailTitle: {
				type: String,
			},
		},
		components: {
			HomeSectionTitle,
			HomeSectionDivider,
			SectionBtnWrapper,
			RouterButton,
			SwiperList,
		},
	};
</script>

<style src="@/assets/css/home.css"></style>
<style></style>
