<template>
	<div class="ranking">
		<div class="item_list_container" v-if="products.length > 0">
			<div class="left">
				<ul class="item_list">
					<li class="ranking01">
						<a href="javascript:void(0)" @click="$router.push(`/product/detail/${products[0].product_pk}`)">
							<p class="ranking_num">1</p>
							<div class="img_container">
								<div class="overlay"></div>
								<div class="img_wrapper">
									<div class="inner_wrapper">
										<img v-noimage :src="products[0].path + products[0].save_name" alt="newarrivals01" />
									</div>
								</div>
							</div>
							<div class="product_text">
								<p class="brand_name">
									<strong>{{ products[0].brand_name }}</strong>
								</p>
								<p class="product_name">{{ products[0].product_name }}</p>
								<p class="price">{{ numberFormat(products[0].product_saleprice) }}<span class="won">원</span></p>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<div class="right" v-if="products.length > 0">
				<ul class="item_list">
					<li v-for="(item, idx) in products.slice(1)" :key="item">
						<p class="ranking_num">{{ idx + 2 }}</p>
						<ProductItem :product="item" :type="'ranking'" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import ProductItem from '@/components/molcules/Product/ProductItem.vue';

	import { numberFormat } from '@/utils/numberFormat';
	export default {
		components: { ProductItem },
		props: {
			useSlideYn: Boolean,
			products: Array,
		},
		mixins: [numberFormat],
	};
</script>

<style></style>
